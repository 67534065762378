import { Box, Card } from '@mui/material';
import * as React from 'react';
import { UserLayerType } from '../../screens/UserCoverageMap/UserCoverageMap';

// styled Box
const LayerCircle = (props: any) => (
   <Box
      sx={{
         color: 'white',
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         ':not(:last-child)': {
            mr: 2,
         },
      }}
   >
      <Box
         sx={{
            width: 12.5,
            height: 12.5,
            borderRadius: '50%',
            backgroundColor: props.color,
            border: '1px solid white',
            mr: 1,
         }}
      />
      {props.title}
   </Box>
);

function MapLayerDescriptions(props: { currentType: UserLayerType }) {
   return (
      <Card
         sx={() => ({
            position: 'absolute',
            bottom: 25,
            left: 25,
            px: 2,
            py: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            zIndex: 100,
         })}
         className='control-panel'
      >
         {props.currentType == 'gender' ? (
            <>
               <LayerCircle title='Male' color='#46247a' />
               <LayerCircle title='Female' color='#F0AFFB' />
               <LayerCircle title='Non-Binary' color='#f1c40f' />
            </>
         ) : (
            <>
               <LayerCircle title='Last 72 hours' color='#2ecc71' />
               <LayerCircle title='Last 7 days' color='#e67e22' />
               <LayerCircle title='Last 28 days' color='#e74c3c' />
               <LayerCircle title='Other' color='grey' />
            </>
         )}
      </Card>
   );
}

export default React.memo(MapLayerDescriptions);
