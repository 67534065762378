import AllInboxIcon from '@mui/icons-material/AllInbox';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BadgeIcon from '@mui/icons-material/Badge';
import CakeIcon from '@mui/icons-material/Cake';
import ChatIcon from '@mui/icons-material/Chat';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import EditIcon from '@mui/icons-material/Edit';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HeightIcon from '@mui/icons-material/Height';
import HistoryIcon from '@mui/icons-material/History';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SwipeIcon from '@mui/icons-material/Swipe';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import TransgenderIcon from '@mui/icons-material/Transgender';
import TranslateIcon from '@mui/icons-material/Translate';
import WcIcon from '@mui/icons-material/Wc';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Box, Button, FormGroup } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useEffect, useRef, useState } from 'react';
import FormDialog from '../../../../shared/components/FormDialog';
import {
   ProfileModel,
   useSetDeepLinkRemotelyMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const actions = [
   {
      category: 'Support',
      domain: 'support.doubble.app',
      links: [
         {
            name: 'Open chat',
            description: 'Will open the support chat',
            path: 'chat',
            icon: SupportAgentIcon,
         },
         {
            name: 'Logout',
            path: 'logout',
            icon: LogoutIcon,
         },
         {
            name: 'Restart app',
            description: 'Simply restarts the app',
            path: 'restart',
            icon: PowerSettingsNewIcon,
         },
         {
            name: 'Reset cache & logout',
            description: 'This will clear all the cache and log you out',
            path: 'reset',
            icon: RestartAltIcon,
         },
         {
            name: 'Ask for app review',
            description: 'Will open the app review popup',
            path: 'review',
            icon: ReviewsIcon,
         },
      ],
   },
   {
      category: 'Swipe preferences',
      domain: 'my.doubble.app',
      links: [
         {
            name: 'Change range age',
            path: 'change-age-range',
            icon: CakeIcon,
         },
         {
            name: 'Change Sexual Orientations',
            path: 'change-sexual-orientations',
            icon: TransgenderIcon,
         },
         {
            name: 'Change Distance',
            path: 'change-distance',
            icon: SocialDistanceIcon,
         },
      ],
   },
   {
      category: 'Edit profile',
      domain: 'my.doubble.app',
      links: [
         {
            name: 'Edit Profile',
            path: 'edit-profile',
            icon: EditIcon,
         },
         {
            name: 'Change Name',
            path: 'change-name',
            icon: BadgeIcon,
         },
         {
            name: 'Change Birth Date',
            path: 'change-birth-date',
            icon: CakeIcon,
         },
         {
            name: 'Change Height',
            path: 'change-height',
            icon: HeightIcon,
         },
         {
            name: 'Change Gender',
            path: 'change-gender',
            icon: TransgenderIcon,
         },
         {
            name: 'Add Top Compliment',
            path: 'add-top-compliment',
            icon: WorkspacePremiumIcon,
         },
      ],
   },
   {
      category: 'Account',
      domain: 'my.doubble.app',
      links: [
         {
            name: 'Change Email',
            path: 'change-email',
            icon: AlternateEmailIcon,
         },
         {
            name: 'Change Username',
            path: 'change-username',
            icon: BadgeIcon,
         },
         {
            name: 'Change phone number',
            path: 'change-phone-number',
            icon: PhoneForwardedIcon,
         },
         {
            name: 'Change Profile Status',
            path: 'change-profile-status',
            icon: ToggleOnIcon,
         },
         {
            name: 'Change Matching Status',
            path: 'change-matching-status',
            icon: ToggleOnIcon,
         },
      ],
   },
   {
      category: 'Preferences',
      domain: 'my.doubble.app',
      links: [
         {
            name: 'Change Language',
            path: 'change-language',
            icon: TranslateIcon,
         },

         {
            name: 'Change Notification Preferences',
            path: 'change-notification-preferences',
            icon: NotificationsActiveIcon,
         },
         {
            name: 'Change Inbox Preference',
            path: 'change-inbox-preference',
            icon: AllInboxIcon,
         },
      ],
   },
   {
      category: 'Navigation',
      domain: 'my.doubble.app',
      links: [
         {
            name: 'Go to Swipe',
            path: 'swipe',
            icon: SwipeIcon,
         },
         {
            name: 'Go to Matches',
            path: 'matches',
            icon: WcIcon,
         },
         {
            name: 'Go to Chat',
            path: 'chat',
            icon: ChatIcon,
         },
         {
            name: 'Go to My Friends',
            path: 'my-friends',
            icon: PeopleAltIcon,
         },
         {
            name: 'Go to my profile',
            path: 'profile',
            icon: PersonIcon,
         },
         {
            name: 'Go to Doubble Matches',
            path: 'doubble-matches',
            icon: Diversity1Icon,
         },
         {
            name: 'Go to Unlock Matches',
            path: 'unlock-matches',
            icon: LockOpenIcon,
         },
         {
            name: 'Go to Add Friends',
            path: 'add-friends',
            icon: GroupAddIcon,
         },
         {
            name: 'Go to Compliments',
            path: 'compliments',
            icon: FormatListNumberedIcon,
         },
         {
            name: 'Go to Chat Request History',
            path: 'request-history',
            icon: HistoryIcon,
         },
         {
            name: 'Go to Become Ambassador',
            path: 'become-ambassador',
            icon: AssignmentIndIcon,
         },
      ],
   },
   {
      category: 'Premium',
      domain: 'my.doubble.app',
      links: [
         {
            name: 'Show paywall',
            path: 'get-premium',
            icon: null,
         },
         {
            name: 'Paywall (Get unlimited likes)',
            path: 'get-unlimited-likes',
            icon: null,
         },
         {
            name: 'Paywall (See who likes you)',
            path: 'see-who-likes-you',
            icon: null,
         },
         {
            name: 'Paywall (Get priority likes)',
            path: 'get-priority-likes',
            icon: null,
         },
         {
            name: 'Paywall (Get unlimited undo)',
            path: 'get-unlimited-undo',
            icon: null,
         },
         {
            name: 'Paywall (Get match chat)',
            path: 'get-match-chat',
            icon: null,
         },
         {
            name: 'Paywall (Get swipe filters)',
            path: 'get-swipe-filters',
            icon: null,
         },
      ],
   },
];

const RemoteActionButton: FC<{ user: ProfileModel; onChange?: () => void }> = ({ user }) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [isOpen, setIsOpen] = useState(false);

   const [sendRemoteLinkMutation, { error, loading }] = useSetDeepLinkRemotelyMutation({
      variables: {
         link: '',
         userId: user.id!,
      },
   });

   useEffect(() => {
      if (!loading && error) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Could not set remote link. Does user have notifications enabled?`, {
            variant: 'error',
         });
      }
   }, [loading, error]);

   const handleSubmit = async (link: any, url: string) => {
      closeSnackbar(notification.current);
      notification.current = enqueueSnackbar(`Sending...`, {
         variant: 'info',
         autoHideDuration: null,
      });
      const result = await sendRemoteLinkMutation({
         variables: {
            link: url,
            userId: user.id!,
         },
      });

      if (result.data) {
         closeSnackbar(notification.current);
         enqueueSnackbar(
            `Action ${link.name} sent to ${result.data.setDeepLinkRemotely} device(s)`,
            {
               variant: 'success',
            },
         );
      }
   };

   return (
      <>
         <Button
            startIcon={<SettingsRemoteIcon />}
            variant='outlined'
            onClick={() => setIsOpen(true)}
         >
            Remote action
         </Button>
         <FormDialog
            title='Perform remote action'
            open={isOpen}
            onClose={() => setIsOpen(false)}
            loading={loading}
            hasActions={false}
         >
            <FormGroup>
               <Box mt={2}>
                  {actions.map((action) => (
                     <Box key={action.category} mb={2}>
                        <Box mb={1}>{action.category}</Box>
                        {action.links.map((link) => {
                           const url = `https://${action.domain}/${link.path}`;
                           return (
                              <Button
                                 key={link.name}
                                 variant='outlined'
                                 startIcon={link.icon ? <link.icon /> : null}
                                 onClick={() => {
                                    handleSubmit(link, url);
                                 }}
                                 sx={{ mt: 1, mr: 1 }}
                              >
                                 {link.name}
                              </Button>
                           );
                        })}
                     </Box>
                  ))}
               </Box>

               {error && <div>Something went wrong: {error.message}</div>}
            </FormGroup>
         </FormDialog>
      </>
   );
};

export default RemoteActionButton;
