/* eslint-disable @typescript-eslint/no-empty-function */
import { Save, SvgIconComponent } from '@mui/icons-material';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';

const PREFIX = 'SaveButton';

const classes = {
   loadingIcon: `${PREFIX}-loadingIcon`,
};

const StyledButton = styled(Button)(({ theme }) => ({
   [`& .${classes.loadingIcon}`]: {
      marginRight: theme.spacing(1),
   },
}));

export interface SaveButtonProps extends Partial<ButtonProps> {
   className?: string;
   loading?: boolean;
   onClick?: any;
   children: any;
   icon?: SvgIconComponent;
}

const SaveButton: FC<SaveButtonProps> = ({
   className,
   loading = undefined,
   onClick,
   children,
   icon,
   ...props
}) => {
   const Icon = icon ?? Save;

   const [isLoading, setIsLoading] = useState(loading);

   const saveHandler = (...onClickProps: any[]) => {
      if (typeof loading === 'undefined') {
         setIsLoading(true);
         onClick(...onClickProps).finally(() => setIsLoading(false));
      } else {
         onClick(onClickProps);
      }
   };

   // update loading state if loading prop changes
   useEffect(() => {
      setIsLoading(loading);
   }, [loading]);

   return (
      <StyledButton
         variant='contained'
         color='primary'
         className={className}
         startIcon={isLoading ? null : Icon && <Icon />}
         onClick={saveHandler}
         disabled={isLoading}
         {...props}
      >
         {isLoading && <CircularProgress className={classes.loadingIcon} size={20} />}
         {children}
      </StyledButton>
   );
};
SaveButton.defaultProps = {
   className: '',
   onClick: () => {},
};

export default SaveButton;
