/* eslint-disable no-constant-condition */
import WebMercatorViewport from '@math.gl/web-mercator';
import { cellsToMultiPolygon, gridDisk, latLngToCell } from 'h3-js';
import { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Layer, Map, Popup, Source } from 'react-map-gl';

import type { GeoJSONSource, MapRef } from 'react-map-gl';
/* eslint-disable @typescript-eslint/no-var-requires */

// added the following 6 lines.
import mapboxgl, { LngLat } from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import { Box, Typography } from '@mui/material';
import type { LayerProps, MapLayerMouseEvent } from 'react-map-gl';
import {
   ProfileLocationModel,
   SupportedAreaModel,
   useGetUserCoverageQuery,
} from '../../../../shared/utilities/__generated__/graphql';
import CitiyControlPanel from '../../components/CitiyControlPanel';
import MapControlsPanel from '../../components/MapControlsPanel';
import MapLayerDescriptions from '../../components/MapLayerDescriptions';

const clusterLayer: LayerProps = {
   id: 'clusters',
   type: 'circle',
   source: 'locations',
   filter: ['has', 'point_count'],
   paint: {
      'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
   },
};

const clusterCountLayer: LayerProps = {
   id: 'cluster-count',
   type: 'symbol',
   source: 'locations',
   filter: ['has', 'point_count'],
   layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
   },
};

// const unclusteredPointLayer: LayerProps = {
//    id: 'unclustered-point',
//    type: 'circle',
//    source: 'locations',
//    filter: ['!', ['has', 'point_count']],
//    paint: {
//       'circle-color': '#11b4da',
//       'circle-radius': 4,
//       'circle-stroke-width': 1,
//       'circle-stroke-color': '#fff',
//    },
// };

const unclusteredGenderizedPointLayer: LayerProps = {
   id: 'unclustered-gender-point',
   type: 'circle',
   source: 'locations',
   filter: ['!', ['has', 'point_count']],
   paint: {
      // Use a data-driven approach to set the circle color
      'circle-color': [
         'match',
         ['get', 'gender'], // the feature property you want to base the color on
         'Male',
         '#46247a', // if the property value is 'value1', set color to red
         'Female',
         '#F0AFFB', // if the property value is 'value2', set color to green
         '#f1c40f', // fallback color if no match
      ],
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff',
      // 'circle-stroke-color': [
      //    'match',
      //    ['get', 'value'],
      //    // for values less than 2, set the color to green
      //    2,
      //    '#2ecc71',
      //    // for values between 2 and 36, set the color to yellow
      //    36,
      //    '#e67e22',
      //    // for values between 36 and 168, set the color to red
      //    168,
      //    '#e74c3c',
      //    // for all other values, set the color to grey
      //    'grey',
      // ],
   },
};

const unclusteredActivityPointLayer: LayerProps = {
   id: 'unclustered-activity-point',
   type: 'circle',
   source: 'locations',
   filter: ['!', ['has', 'point_count']],
   paint: {
      // Use a data-driven approach to set the circle color
      'circle-color': [
         'case',
         // last 3 days
         ['<=', ['get', 'hoursSinceActive'], 72],
         '#2ecc71',
         // last 7 days
         ['<=', ['get', 'hoursSinceActive'], 168],
         '#e67e22',
         // last 28 days
         ['<=', ['get', 'hoursSinceActive'], 672],
         '#e74c3c',
         // for all other values, set the color to grey
         'grey',
      ],
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff',
   },
};

export const areasLayer: LayerProps = {
   id: 'areas',
   type: 'fill',
   // 'source-layer': 'original',

   paint: {
      'fill-color': '#7654AA',
      'fill-opacity': 0.5,
      'fill-outline-color': '#FFF',
   },
};
export const cellsLayer: LayerProps = {
   id: 'cells',
   type: 'fill',
   // 'source-layer': 'original',
   paint: {
      'fill-color': '#FFF',
      'fill-opacity': 0.3,
      'fill-outline-color': '#FFF',
   },
}; // Define the coordinates of the bounding box that covers the whole world

// Generate the H3 indexes covering the bounding box at base resolution 6
// TODO: generate cells for major cities, or something like that.
const dkCell = latLngToCell(55.66840108225136, 12.556333963259815, 4);

const polygons = cellsToMultiPolygon(gridDisk(dkCell, 6), true);

export type UserLayerType = 'gender' | 'activity';

const UserCoverageMapScreen: FC<void> = (): ReactElement => {
   const [page, setPage] = useState(1);

   const [loading, setLoading] = useState(true);

   const { data, error } = useGetUserCoverageQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
         page,
      },
   });

   const [allProfiles, setProfiles] = useState<ProfileLocationModel[]>([]);

   useEffect(() => {
      if (!data?.profileLocations) {
         return;
      }

      if (data?.profileLocations?.length > 100) {
         setPage(page + 1);
         setProfiles([...allProfiles, ...data.profileLocations]);
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, [data]);

   const isPresenting = useRef<boolean>(false);

   // const locations = !data
   //    ? []
   //    : data.profileLocations.map(({ coordinates }) => ({
   //         lat: coordinates[1],
   //         lng: coordinates[0],
   //      }));

   const mapRef = useRef<MapRef>(null);
   const [userLayerType, setUserLayerType] = useState<UserLayerType>('gender');

   const profiles = {
      type: 'FeatureCollection',
      features: (allProfiles ?? [])
         .map(
            ({ c, g, h }) =>
               ({
                  type: 'Feature',
                  geometry: { type: 'Point', coordinates: c },
                  properties: {
                     gender: g,
                     hoursSinceActive: h,
                  },
                  // lat: coordinates[1],
                  // lng: coordinates[0],
               } as any),
         )
         .flat(),
   } as GeoJSON.FeatureCollection<GeoJSON.Point>;

   const countries = {
      type: 'FeatureCollection',
      features: (data?.supportedRegions ?? []).map(({ polygon }) => ({
         type: 'Feature',
         geometry: {
            type: 'Polygon',
            coordinates: polygon.coordinates,
         },
      })),
   } as any;

   const cells = {
      type: 'FeatureCollection',
      features: (polygons ?? []).map((coordinates) => ({
         type: 'Feature',
         geometry: {
            type: 'Polygon',
            coordinates: coordinates,
         },
      })),
   } as any;

   const areas = {
      type: 'FeatureCollection',
      features: (data?.supportedRegions ?? [])
         .map(({ areas }) =>
            areas.map(({ name, polygon, target, count }) => ({
               type: 'Feature',
               geometry: {
                  type: 'Polygon',
                  coordinates: polygon.coordinates,
               },
               properties: {
                  type: 'Area',
                  name,
                  target,
                  totalCount: count.female + count.male + count.nonBinary,
                  maleCount: count.male,
                  femaleCount: count.female,
                  nonBinaryCount: count.nonBinary,
                  waitlistCount: count.waitlist,
               },
            })),
         )
         .flat(),
   } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

   const onClick = (event: mapboxgl.MapLayerMouseEvent) => {
      const feature = event.features![0];
      const clusterId = feature?.properties?.cluster_id;

      if (!clusterId) return;

      const mapboxSource = mapRef.current?.getSource('locations') as GeoJSONSource;

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
         if (err) {
            return;
         }

         // mapRef.current?.easeTo({
         //    center: feature.geometry.coordinates,
         //    zoom,
         //    duration: 500,
         // });
      });
   };

   const [hoverInfo, setHoverInfo] = useState<any>(null);

   const onHover = useCallback((event: MapLayerMouseEvent) => {
      if (mapRef.current && mapRef.current?.getZoom() > 7) return setHoverInfo(null);

      setHoverInfo({
         longitude: event.lngLat.lng,
         latitude: event.lngLat.lat,
         properties: {
            ...event.features?.[0]?.properties,
            polygon: event.features?.[0]?.geometry,
         },
      });
   }, []);

   const flyToArea = useCallback(
      async (
         area?: SupportedAreaModel,
         options: mapboxgl.FlyToOptions & { easeTo?: boolean } = {},
      ) => {
         let centerLngLat = options.center;
         let zoom = options.zoom;

         if (area) {
            const {
               polygon: {
                  coordinates: [coordinates],
               },
            } = area ?? {};

            const bounds = new mapboxgl.LngLatBounds();
            coordinates
               .map((coord) => new LngLat(coord[0], coord[1]))
               .forEach((coord) => bounds.extend(coord));

            const viewport = new WebMercatorViewport({
               width: mapRef.current?.getContainer().clientWidth || 0,
               height: mapRef.current?.getContainer().clientHeight || 0,
            }).fitBounds(bounds.toArray() as [[number, number], [number, number]], {
               padding: 0,
               offset: [0, -50],
            });

            const centerMercator = viewport.center;
            centerLngLat = viewport.unproject(centerMercator) as [number, number];
            zoom = viewport.zoom;
         }

         const flyAnimation = {
            center: centerLngLat,
            duration: 2000,
            zoom: zoom,
            bearing: 30,
            pitch: 90,
            ...options,
         };

         if (options.easeTo) {
            mapRef.current?.easeTo(flyAnimation);
         } else {
            mapRef.current?.flyTo(flyAnimation);
         }

         await new Promise((resolve) => setTimeout(resolve, flyAnimation.duration));
      },
      [],
   );

   const startFlyAnimation = async () => {
      const areas = data?.supportedRegions?.map((region) => region.areas).flat() ?? [];

      if (!areas.length) return;

      const durationMultiplier = 1;
      const baseDuration = 10000;
      const duration = baseDuration * durationMultiplier;

      const destinations: {
         area?: SupportedAreaModel;
         bearing: number;
         pitch: number;
         duration: number;
         zoom: number;
         center?: [number, number];
         easeTo?: boolean;
      }[] = [
         // world
         {
            center: [14.311235646886871, 59.11753305389584],
            zoom: 2.9663329329804307,
            bearing: 0,
            pitch: 0,
            duration: duration,
         },

         // ålborg
         {
            center: [9.912907887501234, 57.059824833565045],
            zoom: 11.933510982434552,
            bearing: -177.4581172707392,
            pitch: 59.99994774971379,
            duration: duration,
         },

         // århus
         {
            center: [10.208198577115525, 56.18469104520838],
            zoom: 12.447720164897015,
            bearing: 175.20000000000005,
            pitch: 60,
            duration: duration,
         },

         // odense
         {
            center: [10.390574113521637, 55.40198268328922],
            zoom: 13.319668267768638,
            bearing: 167.76081846619377,
            pitch: 58.09368741032991,
            duration: duration,
         },

         // copenhagen (top)
         {
            center: [12.357560938286412, 55.564026275321595],
            zoom: 9.69095973774167,
            bearing: 94.59521334106648,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // copenhagen (close - towards malmø)
         {
            center: [12.435151884842526, 55.87793856977524],
            zoom: 12.329730113268758,
            bearing: 158.12055192258708,
            pitch: 60,
            duration: duration,
         },

         // copenhagen (fly over)
         {
            center: [12.562525160492896, 55.67693272453582],
            zoom: 13.51916041838935,
            bearing: 158.12055192258708,
            pitch: 60,
            duration: duration * 2,
            easeTo: true,
         },

         // copenhagen (fly over)
         {
            center: [12.571573964041477, 55.68342944391955],
            zoom: 14.283516665224003,
            bearing: -88.3748604897296,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // // copenhagen close
         // {"center":[12.567547101127246,55.69128441326768],"zoom":13.423280779756395,"bearing":127.65028826890784,"pitch":59.999999999999964,"duration":duration},

         // malmø
         {
            center: [13.01473024305244, 55.598068912849385],
            zoom: 10.92296287145037,
            bearing: 30,
            pitch: 60,
            duration: duration,
         },

         // // top scandinavia
         // {"center":[11.928301100632837,56.02586080443774],"zoom":6.975464610195182,"bearing":72.56307279090242,"pitch":59.999999999999986,"duration":duration * 0.5},

         // gothebourg
         {
            center: [11.90209240860483, 57.72305802227021],
            zoom: 11.513248141041352,
            bearing: 130.11430224327614,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // bornholm
         {
            center: [15.025343964259235, 55.07040502740662],
            zoom: 10.721568111995527,
            bearing: -11.383408176940065,
            pitch: 60,
            duration: duration,
         },

         // stockholm
         {
            center: [17.992925327371893, 59.072114888295744],
            zoom: 9.315402319036522,
            bearing: -11.383408176940065,
            pitch: 60,
            duration: duration,
         },

         // stockholm center
         {
            center: [18.06299001332144, 59.317914570281005],
            zoom: 12.590690483744492,
            bearing: -11.383408176940065,
            pitch: 60,
            duration: duration,
         },

         // top view
         {
            center: [11.60716778689391, 61.780816299975754],
            zoom: 5.877084431839331,
            bearing: -139.58878898326833,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // trondheim
         {
            center: [10.402794316323707, 63.41974038960123],
            zoom: 11.75998415332381,
            bearing: -163.5887889832685,
            pitch: 60,
            duration: duration,
         },

         // top scandinavia
         {
            center: [7.406753883402729, 59.62630654659503],
            zoom: 4.977652154014112,
            bearing: 170.01121101673152,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // bergen
         {
            center: [5.291845761411565, 60.34206429740277],
            zoom: 9.08940750962557,
            bearing: 170.01121101673152,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // bergen close
         {
            center: [5.311349314663346, 60.398597575638036],
            zoom: 13.47383863344426,
            bearing: 170.01121101673152,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // netherlands
         {
            center: [4.434226909071725, 52.14131987720208],
            zoom: 9.1863767232646,
            bearing: 115.59414435006545,
            pitch: 59.999999999999964,
            duration: duration,
         },

         // amsterdam
         {
            center: [4.8249516970838044, 52.41305529031905],
            zoom: 11.12018828808659,
            bearing: -161.11662305003176,
            pitch: 60,
            duration: duration,
         },

         // amsterdam close (hauge)
         {
            center: [4.307139034414689, 51.95119201891876],
            zoom: 11.12018828808659,
            bearing: 80.9418871225156,
            pitch: 59.99999999999994,
            duration: duration,
         },

         // amsterdam close (utrecht)
         {
            center: [5.058923102088812, 52.0640530885851],
            zoom: 11.98213003181848,
            bearing: 80.9418871225156,
            pitch: 59.99999999999994,
            duration: duration,
         },
      ];

      for (const { area, ...destination } of destinations) {
         if (isPresenting.current) {
            await flyToArea(area, destination);
         }

         // await new Promise((resolve) => setTimeout(resolve, 3000));
      }

      if (isPresenting.current) {
         startFlyAnimation();
      }
   };

   (window as any).startFlyAnimation = () => {
      isPresenting.current = true;
      startFlyAnimation();
   };

   const selectedArea = (hoverInfo && hoverInfo?.properties?.name) || '';

   // on command+c copy the selected area
   useEffect(() => {
      const handleCopy = (e: KeyboardEvent) => {
         if (e.metaKey && e.key === 'c') {
            e.preventDefault();
            navigator.clipboard.writeText(
               JSON.stringify({
                  center: mapRef.current?.getCenter()?.toArray(),
                  zoom: mapRef.current?.getZoom(),
                  bearing: mapRef.current?.getBearing(),
                  pitch: mapRef.current?.getPitch(),
                  duration: 3000,
               }),
            );
         }
      };

      document.addEventListener('keydown', handleCopy);

      return () => {
         document.removeEventListener('keydown', handleCopy);
      };
   }, [selectedArea]);

   const setPresentingMode = (enabled: boolean) => {
      isPresenting.current = enabled;

      if (enabled) {
         startFlyAnimation();
      }
   };

   return (
      <div style={{ height: 'calc(100vh - 64px)', width: '100%', position: 'relative' }}>
         {loading || error ? (
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  textAlign: 'center',
               }}
            >
               <div style={{ width: '250px' }}>
                  {loading && !error ? (
                     <img src='/clover_loader.webp' alt='Please wait' width={100} />
                  ) : null}
                  <Box mt={2}>
                     {error ? (
                        <>Something went wrong :(</>
                     ) : (
                        <>
                           Fetching data...
                           <br />
                           <br />
                           Stay put, this usually takes around a minute
                           <br />
                           <br />
                           {allProfiles.length ? `Fetched ${allProfiles.length} profiles` : <br />}
                        </>
                     )}
                  </Box>
               </div>
            </Box>
         ) : (
            <>
               <Map
                  initialViewState={{
                     latitude: 60.477118631037484,
                     longitude: 11.57721592655301,
                     zoom: 5.070617209187902,
                  }}
                  mapStyle='mapbox://styles/mapbox/dark-v9'
                  mapboxAccessToken={
                     'pk.eyJ1IjoibWF0dGlhc2RvdWJibGUiLCJhIjoiY2xidXR4OThiMW83NDNvbXZsYjF6NjZlaSJ9.8DF4sqdoqceFGra_xouUIA'
                  }
                  interactiveLayerIds={['areas']}
                  onClick={onClick}
                  ref={mapRef}
                  onMouseMove={onHover}
                  logoPosition='bottom-right'
                  onMouseDown={() => setPresentingMode(false)}
               >
                  {selectedArea && (
                     <Popup
                        key={selectedArea}
                        longitude={hoverInfo.longitude}
                        latitude={hoverInfo.latitude}
                        offset={[0, -10]}
                        closeButton={false}
                     >
                        <Typography variant='h5' color='textSecondary'>
                           {selectedArea}
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                           <table>
                              <tr>
                                 <td>Users:</td>
                                 <td>{hoverInfo?.properties?.totalCount}</td>
                              </tr>
                              <tr>
                                 <td>Male:</td>
                                 <td>{hoverInfo?.properties?.maleCount}</td>
                              </tr>
                              <tr>
                                 <td>Female:</td>
                                 <td>{hoverInfo?.properties?.femaleCount}</td>
                              </tr>
                              <tr>
                                 <td>NonBinary:</td>
                                 <td>{hoverInfo?.properties?.nonBinaryCount}</td>
                              </tr>
                              <tr>
                                 <td>Waitlist:</td>
                                 <td>{hoverInfo?.properties?.waitlistCount}</td>
                              </tr>
                           </table>
                           <br />
                           Target: {hoverInfo?.properties?.target || 'N/A'}
                        </Typography>
                     </Popup>
                  )}
                  {/* countries */}
                  <Source id='countries' type='geojson' data={countries}>
                     <Layer
                        type='fill'
                        paint={{
                           'fill-color': '#46247a',
                           'fill-opacity': isPresenting?.current == true ? 0 : 0.2,
                        }}
                        source='countries'
                     ></Layer>
                  </Source>
                  {/* areas */}
                  <Source id='areas' type='geojson' data={areas}>
                     <Layer {...areasLayer}></Layer>
                  </Source>

                  {/* cells */}
                  {/* <Source id='cells' type='geojson' data={cells}>
                  <Layer {...cellsLayer}></Layer>
               </Source> */}
                  {/* profile locations */}
                  <Source
                     id='locations'
                     type='geojson'
                     data={profiles}
                     cluster={true}
                     clusterMaxZoom={8}
                     clusterRadius={50}
                  >
                     <Layer {...clusterLayer} />
                     <Layer {...clusterCountLayer} />

                     <Layer
                        {...unclusteredActivityPointLayer}
                        layout={{ visibility: userLayerType == 'activity' ? 'visible' : 'none' }}
                     />
                     <Layer
                        {...unclusteredGenderizedPointLayer}
                        layout={{ visibility: userLayerType == 'gender' ? 'visible' : 'none' }}
                     />
                  </Source>
               </Map>
               {isPresenting?.current != true && (
                  <>
                     <CitiyControlPanel
                        onSelectArea={(area) => {
                           flyToArea(area);
                        }}
                        areas={data?.supportedRegions?.map((region) => region.areas).flat() ?? []}
                        onPresentationMode={(enabled) => {
                           setPresentingMode(enabled);
                        }}
                     />
                     {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                     <MapControlsPanel
                        currentType={userLayerType}
                        onChangeType={(type) => {
                           setUserLayerType(type);
                        }}
                     />
                  </>
               )}
               <MapLayerDescriptions currentType={userLayerType} />
               <Box
                  sx={() => ({
                     position: 'absolute',
                     bottom: 21,
                     right: 157,

                     display: {
                        xs: 'none',
                     },
                  })}
               >
                  <img height={25} src='/logo-white.svg' alt='logo' />
               </Box>
            </>
         )}
      </div>
   );
};

export default UserCoverageMapScreen;
