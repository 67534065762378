import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

import { Delete } from '@mui/icons-material';
import { SnackbarKey, useSnackbar } from 'notistack';
import FormDialog from '../../../../shared/components/FormDialog';
import {
   ProfileModel,
   useAdminDeleteAccountMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const DeleteUserButton: FC<{ user: ProfileModel }> = ({ user }) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);
   const [delayed, setDelayed] = useState(true);
   const [isOpen, setIsOpen] = useState(false);

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [deleteAccountMutation, { error, loading }] = useAdminDeleteAccountMutation({
      variables: {
         deleteInput: {
            profileId: user.id!,
            delayed,
         },
      },
   });

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDelayed(event.target.checked);
   };

   const handleDelete = async () => {
      closeSnackbar(notification.current);
      notification.current = enqueueSnackbar(`Requesting deletion of account, please wait...`, {
         variant: 'info',
         autoHideDuration: null,
      });

      const deleteAccountMutationResult = await deleteAccountMutation();
      closeSnackbar(notification.current);

      if (deleteAccountMutationResult.data) {
         enqueueSnackbar(delayed ? `Account scheduled for deletion` : `Account deleted!`, {
            variant: 'success',
         });
      }

      setIsOpen(false);
   };

   useEffect(() => {
      if (error && !loading) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Could not delete account`, {
            variant: 'error',
         });
      }
   }, [error, loading]);

   return (
      <>
         <Button startIcon={<Delete />} variant='outlined' onClick={() => setIsOpen(true)}>
            Permanently delete
         </Button>
         <FormDialog
            title='Delete user'
            open={isOpen}
            onSubmit={handleDelete}
            onClose={() => setIsOpen(false)}
            loading={loading}
            saveButtonProps={{
               color: 'error',
               icon: Delete,
               children: 'Delete',
               loading: loading,
            }}
         >
            <FormGroup>
               <div>{`Are you sure you want to delete ${user.name}'s account?`}</div>
               <FormControlLabel
                  control={<Checkbox checked={delayed} onChange={handleChange} />}
                  label='Delayed deletion'
               />

               {error && <div>Something went wrong: {error.message}</div>}
            </FormGroup>
         </FormDialog>
      </>
   );
};

export default DeleteUserButton;
