import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Avatar } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   ProfileModel,
   useSearchUsersQuery,
} from '../../../../shared/utilities/__generated__/graphql';
import { navigateOrNewTab } from '../../../../shared/utilities/helpers';

const SearchUsersInput: FC = () => {
   const [value, setValue] = React.useState<ProfileModel | null>(null);
   const [inputValue, setInputValue] = React.useState('');
   const [options, setOptions] = React.useState<readonly ProfileModel[]>([]);

   const [query, setQuery] = React.useState('');

   const { loading, data } = useSearchUsersQuery({
      variables: {
         query,
      },
      fetchPolicy: 'cache-and-network',
      skip: !query,
   });

   // set options when data changes
   React.useEffect(() => {
      if (data) {
         setOptions(data.searchUsers.items as any);
      }
   }, [data]);

   // update query after 400ms of no typing
   React.useEffect(() => {
      const timeoutId = setTimeout(() => {
         setQuery(inputValue);
      }, 400);

      return () => {
         clearTimeout(timeoutId);
      };
   }, [inputValue]);

   const navigate = useNavigate();

   return (
      <Autocomplete
         id='search-users-input'
         sx={{ width: 300 }}
         getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.name)}
         filterOptions={(x) => x}
         options={[...options, value].filter(Boolean) as ProfileModel[]}
         autoComplete
         forcePopupIcon={false}
         includeInputInList
         autoHighlight
         filterSelectedOptions
         value={value}
         noOptionsText={
            !inputValue || inputValue != query ? 'Search by phone, email or username' : 'No results'
         }
         onChange={(event: any, profile: ProfileModel | null) => {
            // setOptions(newValue ? [newValue, ...options] : options);
            // setValue(newValue);
            if (profile) {
               navigateOrNewTab(`/users/${profile.id}`, navigate, event);
            }
         }}
         loading={loading}
         onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
         }}
         renderInput={(params) => (
            <TextField
               {...params}
               variant='outlined'
               label='Search users'
               fullWidth
               size='small'
               sx={{
                  '& label': {
                     color: 'white', // Style for label
                  },
                  '& .MuiInputBase-root': {
                     color: 'white', // Style for input text
                  },
                  '& .MuiOutlinedInput-root': {
                     backgroundColor: 'transparent', // Transparent background
                     '& fieldset': {
                        borderColor: 'white', // White border
                     },
                     '&:hover fieldset': {
                        borderColor: 'white', // White border on hover
                     },
                     '&.Mui-focused fieldset': {
                        borderColor: 'white', // White border when focused
                     },
                  },
                  '& .MuiInputLabel-root': {
                     // color: 'white', // Already set above
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                     color: 'white', // White label text when focused
                  },
                  '& .MuiInput-underline:before': {
                     borderBottomColor: 'white', // Underline color when textfield is not focused
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                     borderBottomColor: 'white', // Underline color on hover
                  },
                  '& .MuiInput-underline:after': {
                     borderBottomColor: 'white', // Underline color when textfield is focused
                  },
               }}
            />
         )}
         renderOption={(props, option) => {
            return (
               <li {...props}>
                  <Grid container alignItems='center'>
                     <Grid item sx={{ display: 'flex', width: 50 }}>
                        <Avatar
                           src={option.avatar?.thumbnailUrl ?? ''}
                           sx={{ width: 40, height: 40, mr: 0.5 }}
                        />
                     </Grid>
                     <Grid item>
                        <Typography variant='body1' color='text.secondary'>
                           {option.name ?? option.username ?? option.phoneNumber}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                           {option.username ?? option.phoneNumber}
                        </Typography>
                     </Grid>
                  </Grid>
               </li>
            );
         }}
      />
   );
};

export default SearchUsersInput;
