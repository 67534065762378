import { FC, useEffect, useRef, useState } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import {
   ProfileModel,
   useAdminDeleteProfileImageMutation,
} from '../../../../shared/utilities/__generated__/graphql';

import 'photoswipe/dist/photoswipe.css';

import PhotoSwipe from 'photoswipe';
import { Gallery, GalleryProps, Item } from 'react-photoswipe-gallery';

const ProfileImages: FC<{ user: ProfileModel }> = ({ user }) => {
   const instance = useRef<PhotoSwipe | null>(null);

   const onBeforeOpen = (pswpInstance: PhotoSwipe) => {
      instance.current = pswpInstance;

      // pswpInstance.on('change', () => {
      //    console.log('slide was changed');
      // });
   };

   const notification = useRef<SnackbarKey | undefined>(undefined);
   const [imageUrl, setImageUrl] = useState<string>('');

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();
   const [deleteImageMutation, { error, loading }] = useAdminDeleteProfileImageMutation({
      variables: {
         userId: user.id!,
         imageUrl,
      },
   });

   const handleDelete = async () => {
      // instance.current?.close();

      await confirm({
         title: 'Delete image?',
         description: 'This action cannot be undone.',
         confirmationText: 'Delete',
         cancellationText: 'Cancel',
         // z-index
         dialogProps: {
            style: {
               zIndex: 100001,
            },
         },
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Deleting image, please wait...`, {
            variant: 'info',
            autoHideDuration: null,
         });

         const deleteAccountMutationResult = await deleteImageMutation();
         closeSnackbar(notification.current);

         if (deleteAccountMutationResult.data) {
            enqueueSnackbar(`Image deleted!`, {
               variant: 'success',
            });
         }
      });
   };

   useEffect(() => {
      if (error && !loading) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Could not delete image`, {
            variant: 'error',
         });
      }
   }, [error, loading]);
   const uiElements: GalleryProps['uiElements'] = [
      {
         name: 'delete-button',
         ariaLabel: 'delete',
         order: 9,
         isButton: true,
         html: {
            isCustomSVG: true,
            inner: '<path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"/>',
            outlineID: 'pswp__icn-delete',
         },
         appendTo: 'bar',
         onClick: (e, el, pswpInstance) => {
            const image = user.images[pswpInstance.currIndex];

            setImageUrl(image.imageUrl!);

            handleDelete();
         },
      },
   ];

   return (
      <div>
         <Gallery id='images' uiElements={uiElements} onBeforeOpen={onBeforeOpen}>
            {user.images.map((image, index) => (
               <Item
                  key={index}
                  id={index}
                  width={image.width as number}
                  height={image.height as number} //
                  original={image.imageUrl!}
                  thumbnail={image.thumbnailUrl!}
               >
                  {({ ref, open }) => (
                     <img
                        ref={ref as any}
                        onClick={open}
                        height={250}
                        src={image.imageUrl!}
                        style={{ marginRight: '5px' }}
                     />
                  )}
               </Item>
            ))}
         </Gallery>
      </div>
   );
};

export default ProfileImages;
