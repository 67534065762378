import { difference, isEqual, pick, uniq } from 'lodash';
import debounce from 'lodash/debounce';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addQueryParams, removeQueryParams } from '../store/query';
import useRouter from './use-router';

/**
 * A hook that can take some state data and store it in the query string. The initialState you pass, as well as any query param you add, will be collected in the global app state.
 * The reason for the global app state is so that the query does not interfere with eachother, when useQuerifyState is used in multiple components in the same page.
 *
 * The simplest API looks like this:
 *
 * const [stateFromQuery, appendQueryString] = useQueryifyState([params explained below]);
 *
 * @param {func}    queryFormatterFn 			        A function that will receive the state as a parameter.
 * 									  										        Should return an object containing a representation of the query string (passed directly to qs.stringify)
 * 										  									        If the object contains "_query" this object will take precedence, otherwise it will use the
 *                                                whole object for the query string. This allows for using the hook to store state that may not be
 *                                                identical to how it should be represented in the query string.
 *
 * @param {object}  initialState					        The initial state to populate to the global state
 *
 * @param {boolean} options.fetchFromQuery        If enabled, the initial state will be picked from the `router.query` based on keys present in initialState,
 *                                                so that you don't manually have to define the logic to fetch the initialState from the query in your component
 *
 * @param {boolean} options.debounce              Amount of time in MS before changes to state should be reflected in query
 *
 * @param {object} options.defaultValues          The values that are considered default for the state. The defaultValues will not be appended to the query string
 *
 * @return  {array[0]}  										      The global state from the query
 * @return  {array[1]}  										      A function to append a query string to the global state
 * @return  {array[2]}  										      An object containing a few helper functions, e.g. for resetting the applied filters. If options.defaultValues are applied
 *                                                it will reset to those values.
 */

type QuerifyStateOptions = Partial<{
   fetchFromQuery: boolean;
   debounce: number;
   defaultValues: any;
}>;

const useQuerifyState = (
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   queryFormatterFn: (state: any) => any = () => ({}),
   initialState: any,
   options: QuerifyStateOptions = {},
) => {
   const router = useRouter();
   const dispatch = useDispatch();

   const initState = {
      ...(!options.fetchFromQuery ? initialState : pick(router.query, Object.keys(initialState))),
   };

   const [state, setState] = useState(initState);
   const [previousState, setPreviousState] = useState(initState);

   const [queryParamsSet, setQueryParamsSet] = useState(
      Object.keys(queryFormatterFn(initState)?._query || queryFormatterFn(initState)),
   );

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const updateQuery = useCallback(
      debounce((query) => {
         // store a reference of all query params set (so we later can clear them, even the dynamic ones)
         const keysFromQuery = Object.keys(query);
         setQueryParamsSet(uniq([...queryParamsSet, ...keysFromQuery]));

         dispatch(addQueryParams(query));

         // remove keys managed by this instance, but that was not passed to the new query (to clean up dynamic query params automatically)
         const queryParamsToRemove = difference(queryParamsSet, keysFromQuery);
         if (queryParamsToRemove.length) {
            dispatch(removeQueryParams(queryParamsToRemove));
         }
      }, options.debounce ?? 500),
      [queryParamsSet],
   );

   const buildQueryFromState = (stateForQuery: any) => {
      const queryFormatter = queryFormatterFn(stateForQuery);
      const receivedQuery = queryFormatter?._query || queryFormatter || {};

      // const managedQueryParams = Object.keys(receivedQuery);

      // get the current query
      const currentQuery = qs.parse(window.location.search, {
         arrayFormat: 'comma',
      });

      // create a new query object, based on the current state, as well as the current query
      // It will only pick query params that are managed by this instance of useQueryifyState
      // eslint-disable-next-line no-restricted-syntax
      const newQuery = {
         ...qs.parse(
            qs.stringify(receivedQuery, {
               arrayFormat: 'comma',
            }),
            {
               arrayFormat: 'comma',
            },
         ),
      };

      // ensure that query params that was previously set,
      // but is not part of the new query string, is also removed

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(receivedQuery)) {
         if (typeof value === 'undefined' || typeof value === 'undefined') {
            // delete newQuery[key];
            newQuery[key] = undefined as any;
         }
      }

      return { newQuery, currentQuery };
   };

   useEffect(() => {
      // only attempt to update query, if state has actually chabged
      if (JSON.stringify(previousState) === JSON.stringify(state)) {
         return;
      }
      setPreviousState(state);

      // build the new query
      const { currentQuery, newQuery } = buildQueryFromState(state);

      // ensure that values from newQuery that are equal to defaultValues, are not added
      if (options?.defaultValues) {
         // get the parsed new query string
         const newQueryParsed = qs.parse(qs.stringify(newQuery));

         // get the default query based on state
         //  const defaultQuery = buildQueryFromState(options?.defaultValues, false).newQuery;
         const defaultQuery = buildQueryFromState(options?.defaultValues).newQuery;

         // get the parsed default query string
         const defaultQueryParsed = qs.parse(qs.stringify(defaultQuery));

         // compare each parameter value
         Object.entries(pick(newQueryParsed, Object.keys(defaultQueryParsed))).forEach(
            ([param, value]) => {
               // this new value, is equal to the defaultValues, so don't add it to the query
               if (isEqual(value, defaultQueryParsed[param])) {
                  newQuery[param] = undefined as any;
               }
            },
         );
      }

      // so the new query, is not equal to the proposed new query, so lets update the query params in the URL
      if (!isEqual(currentQuery, newQuery)) {
         updateQuery(newQuery);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [state]);

   return [
      { ...state },
      (s: any) => {
         setState(s);
      },
      {
         reset: () => {
            setState(options.defaultValues || {});
            dispatch(removeQueryParams(queryParamsSet));
         },
      },
   ];
};

export default useQuerifyState;
