import {
   Button,
   Dialog,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Slide,
   Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC, ReactElement, useState } from 'react';
import { UserLogModel } from '../../../../shared/utilities/__generated__/graphql';
import UserDebugLogs from './UserDebugLog';

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & {
      children: React.ReactElement<any, any>;
   },
   ref: React.Ref<unknown>,
) {
   return <Slide direction='up' ref={ref} {...props} />;
});

const UserLogsTable: FC<{ logs: Partial<UserLogModel>[] }> = ({ logs }): ReactElement => {
   const [viewingUserLog, setViewUserLog] = useState<UserLogModel | null>(null);
   const handleClose = () => setViewUserLog(null);

   const columns: GridColDef[] = [
      { field: 'action', headerName: 'Action', width: 250 },
      // { field: 'data', headerName: 'Data' },
      {
         field: 'data',
         headerName: 'Data',
         flex: 1,
         renderCell: ({ value, row }) => {
            try {
               const data = JSON.parse(value);

               // if row.action is debug_log then
               if (row.action === 'debug_log') {
                  const url = data.path;

                  return <div>{url}</div>;
               }

               // if empty object
               if (Object.keys(data).length === 0) {
                  throw new Error('Empty data object');
               }

               return (
                  <Button variant='outlined' onClick={() => setViewUserLog(row)}>
                     View data
                  </Button>
               );
            } catch (error) {
               return 'N/A';
            }
         },
      },
      {
         field: 'createdAt',
         headerName: 'Created at',
         type: 'dateTime',
         width: 200,
         valueGetter: ({ value }) => value && new Date(value),
      },
   ];

   return logs.length > 0 ? (
      <>
         <DataGrid
            rows={logs}
            getRowId={(row) => row.createdAt + row.action}
            pagination
            columns={columns}
            disableColumnSelector
            disableSelectionOnClick
            disableColumnMenu
            hideFooter
            hideFooterPagination
            autoHeight
            onRowClick={(params) => {
               console.log('params', params);
               if (params.row.action === 'debug_log') {
                  setViewUserLog(params.row);
               }
            }}
            // initialState={initialState}
         />
         <Dialog
            open={viewingUserLog != null}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description'
            maxWidth='lg'
         >
            {viewingUserLog != null && <DialogTitle>Action: {viewingUserLog.action}</DialogTitle>}
            {viewingUserLog != null && viewingUserLog.action !== 'debug_log' && (
               <DialogContent>
                  {viewingUserLog != null && (
                     <DialogContentText id='alert-dialog-slide-description'>
                        <Typography variant='h6'>{viewingUserLog.createdAt}</Typography>
                        <pre>{JSON.stringify(JSON.parse(viewingUserLog!.data!), null, 2)}</pre>
                     </DialogContentText>
                  )}
               </DialogContent>
            )}
            {viewingUserLog != null && viewingUserLog.action === 'debug_log' && (
               <DialogContent>
                  {viewingUserLog != null && (
                     <UserDebugLogs url={JSON.parse(viewingUserLog!.data!).path} />
                  )}
               </DialogContent>
            )}
         </Dialog>
      </>
   ) : (
      <div>No logs</div>
   );
};

export default UserLogsTable;
