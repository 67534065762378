import { Assignment, PlayCircle } from '@mui/icons-material';
import { Box, Card, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { SupportedAreaModel } from '../../../../shared/utilities/__generated__/graphql';

function CitiyControlPanel(props: {
   onSelectArea: (city: SupportedAreaModel) => void;
   onPresentationMode: (mode: boolean) => void;
   areas: SupportedAreaModel[];
}) {
   const sortedAreas = props.areas.sort((a, b) => a.name.localeCompare(b.name));
   const [value, setValue] = React.useState<number | undefined>();

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(event.target.value === 'presentation-mode') {
         props.onPresentationMode(true);
      } else {
         const selected = parseInt((event.target as HTMLInputElement).value);

         setValue(selected);

         const selectedCity = sortedAreas[selected];
         props.onSelectArea(selectedCity);


         props.onPresentationMode(false);
      }
   };




   // random cities
   // React.useEffect(() => {
   //    const presentationInterval = setInterval(() => {
   //       // rotate
   //       if(presentationMode) {
   //          if(props.areas.length === 0) return;
            
   //          const area = props.areas[Math.floor(Math.random() * props.areas.length)];

   //          props.onSelectArea(area);
   //       }
   //    }, 10000);
   //    return () => {
   //       clearInterval(presentationInterval);
   //    };
   // }, [presentationMode]);

   return (
      <Card
         sx={(theme) => ({
            position: 'absolute',
            top: 25,
            right: 25,
            maxWidth: '400px',
            py: 1,
            px: 2,
            maxHeight: '300px',
            overflow: 'scroll',
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,

            display: {
               xs: 'none',
               lg: 'block',
            },
         })}
         className='control-panel'
      >
         <FormControl>
            <RadioGroup
               aria-labelledby='demo-controlled-radio-buttons-group'
               name='controlled-radio-buttons-group'
               value={value}
               onChange={handleChange}
            >
               {sortedAreas.map((area, index) => (
                  <FormControlLabel
                     key={`btn-${index}`}
                     value={index}
                     control={
                        <Radio
                           sx={(theme) => ({
                              color: theme.palette.primary.contrastText,
                              '&.Mui-checked': {
                                 color: theme.palette.secondary.main,
                              },
                           })}
                        />
                     }
                     label={
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              // alignItems: 'center',
                           }}
                        >
                           {area.name}{' '}
                           {area.launched == true ? null : <Assignment sx={{ ml: 1 }} />}
                        </Box>
                     }
                  />
               ))}
                  <FormControlLabel
                     value="presentation-mode"
                     control={
                        <Radio
                           sx={(theme) => ({
                              color: theme.palette.primary.contrastText,
                              '&.Mui-checked': {
                                 color: theme.palette.secondary.main,
                              },
                           })}
                        />
                     }
                     label={
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              // alignItems: 'center',
                           }}
                        >
                           Presentation mode<PlayCircle sx={{ ml: 1 }} />
                        </Box>
                     }
                  />
            </RadioGroup>
         </FormControl>
      </Card>
   );
}

export default React.memo(CitiyControlPanel);
