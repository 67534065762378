import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type AbstractMatchModel = {
  match: ProfileModel;
  matchId: Scalars['String'];
  type: MatchType;
  userId: Scalars['String'];
};

export type AdminModel = {
  __typename?: 'AdminModel';
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type AegisObservationModel = {
  __typename?: 'AegisObservationModel';
  category: Scalars['String'];
  observations: Array<Array<Scalars['String']>>;
  score: Scalars['Float'];
};

export type AegisReportModel = {
  __typename?: 'AegisReportModel';
  aegisVersion: Scalars['String'];
  blocked: Scalars['Boolean'];
  context: Scalars['String'];
  deviceId: Maybe<Scalars['String']>;
  deviceName: Maybe<Scalars['String']>;
  executionTime: Maybe<Scalars['Float']>;
  headers: Scalars['String'];
  id: Scalars['String'];
  ipAddress: Scalars['String'];
  ipCountry: Maybe<Scalars['String']>;
  observations: Array<AegisObservationModel>;
  phoneNumber: Scalars['String'];
  phoneNumberCountry: Maybe<Scalars['String']>;
  requestedAt: Scalars['DateTime'];
  score: Scalars['Float'];
  stats: Array<Array<Scalars['String']>>;
  usedAt: Maybe<Scalars['DateTime']>;
  userAgent: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type AgeDistributionModel = {
  __typename?: 'AgeDistributionModel';
  age: Scalars['String'];
  count: Scalars['Float'];
};

export type AlbumInfo = {
  __typename?: 'AlbumInfo';
  name: Scalars['String'];
  weight: Scalars['Int'];
};

export type AnswerModel = {
  __typename?: 'AnswerModel';
  confessed: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  flag: Maybe<Scalars['Boolean']>;
  gameId: Scalars['String'];
  /** Nullable, since sometimes it can just be derived from the accompanying ChatGame instance */
  gameType: Maybe<GameType>;
  hidden: Maybe<Scalars['Boolean']>;
  image: Maybe<ImageModel>;
  late: Scalars['Boolean'];
  player: ProfileModel;
  prompt: Scalars['String'];
  promptEdited: Maybe<Scalars['Boolean']>;
  rating: Maybe<Scalars['Float']>;
  shuffled: Scalars['Float'];
  taggedUser: Maybe<ProfileModel>;
  taggedUserId: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  user: Maybe<ProfileModel>;
  user2: Maybe<ProfileModel>;
  user2Id: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type AppEventModel = {
  __typename?: 'AppEventModel';
  data: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export type AppInfoModel = {
  __typename?: 'AppInfoModel';
  date: Scalars['DateTime'];
  enforcedAppVersion: Maybe<Scalars['String']>;
  environment: Maybe<Scalars['String']>;
  latestAppVersion: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
};

export type AreaPopulationModel = {
  __typename?: 'AreaPopulationModel';
  female: Scalars['Float'];
  male: Scalars['Float'];
  nonBinary: Scalars['Float'];
  waitlist: Scalars['Float'];
};

export type AuditlogModel = {
  __typename?: 'AuditlogModel';
  callerId: Scalars['String'];
  callerName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  exception: Scalars['Boolean'];
  id: Scalars['ID'];
  input: Scalars['JSON'];
  operation: Scalars['String'];
  operationType: Scalars['String'];
  output: Scalars['JSON'];
  resourceType: Scalars['String'];
};

export type AuthTokensModel = {
  __typename?: 'AuthTokensModel';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AutoCompleteAddressInput = {
  city: InputMaybe<Scalars['String']>;
  countryCodeISO3: InputMaybe<Scalars['String']>;
  formatted: InputMaybe<Scalars['String']>;
  latitude: InputMaybe<Scalars['Float']>;
  longitude: InputMaybe<Scalars['Float']>;
  neighborhood: InputMaybe<Scalars['String']>;
  postalCode: InputMaybe<Scalars['String']>;
  streetName: InputMaybe<Scalars['String']>;
  streetNumber: InputMaybe<Scalars['String']>;
};

export type AutoCompleteAddressModel = {
  __typename?: 'AutoCompleteAddressModel';
  city: Scalars['String'];
  countryCodeISO3: Scalars['String'];
  formatted: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  neighborhood: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  streetName: Maybe<Scalars['String']>;
  streetNumber: Maybe<Scalars['String']>;
};

export type BoostModel = {
  __typename?: 'BoostModel';
  createdAt: Scalars['DateTime'];
  endsAt: Maybe<Scalars['DateTime']>;
  expiresAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  issuerUserId: Scalars['String'];
  jwtToken: Maybe<Scalars['String']>;
  pairedBoost: Maybe<BoostModel>;
  profile: ProfileModel;
  trigger: ConsumableItemTrigger;
  triggerId: Scalars['String'];
  type: ConsumableItemType;
  used: Scalars['Boolean'];
  usedAt: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type CanChangeProfileFieldArg = {
  field: RestrictedProfileField;
};

export type CandidateMetaData = {
  __typename?: 'CandidateMetaData';
  /** @deprecated We now use DrDre canary instances, see dreVariant */
  appliedAlgorithm: Maybe<Scalars['Float']>;
  appliedDreVariant: Maybe<Scalars['String']>;
  appliedFilter: Maybe<Filter>;
};

export type CandidateModel = {
  __typename?: 'CandidateModel';
  category: Maybe<Scalars['Float']>;
  mapType: Maybe<Scalars['Float']>;
  metaData: Maybe<CandidateMetaData>;
  potentialDoubbleMatchWith: Maybe<ProfileModel>;
  potentialDoubbleMatchWithMatchUserId: Maybe<Scalars['String']>;
  potentialDoubbleMatchWithUserId: Maybe<Scalars['String']>;
  potentialDoubbleMatchWithUserRegion: Maybe<Scalars['String']>;
  preferenceScore: Maybe<Scalars['Float']>;
  profile: Maybe<ProfileModel>;
  recommendedBy: Maybe<ProfileModel>;
  sharedBy: Maybe<ProfileModel>;
  sharedById: Maybe<Scalars['String']>;
  sharedByRegion: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userRegion: Scalars['String'];
};

export type CandidateStatsInput = {
  candidateUserId: InputMaybe<Scalars['String']>;
  data: InputMaybe<Scalars['JSON']>;
  firstImageId: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['String']>;
  imagesCount: InputMaybe<Scalars['Float']>;
  imagesSeen: InputMaybe<Scalars['Float']>;
  imagesSwiped: InputMaybe<Scalars['Float']>;
  lastSeenImageId: InputMaybe<Scalars['String']>;
  like: InputMaybe<Scalars['Boolean']>;
  longestSeenImageId: InputMaybe<Scalars['String']>;
  longestSeenImageSeconds: InputMaybe<Scalars['Float']>;
  mapCategory: InputMaybe<Scalars['Float']>;
  mapType: InputMaybe<Scalars['Float']>;
  mostSeenImageId: InputMaybe<Scalars['String']>;
  preferenceScore: InputMaybe<Scalars['Float']>;
  secondsSeen: InputMaybe<Scalars['Float']>;
  type: InputMaybe<CandidateStatsType>;
  undos: InputMaybe<Scalars['Float']>;
};

export type CellModel = {
  __typename?: 'CellModel';
  base: Scalars['Float'];
  cell: Scalars['String'];
  region: Region;
};

export type ChatGameModel = {
  __typename?: 'ChatGameModel';
  answers: Array<AnswerModel>;
  createdAt: Scalars['DateTime'];
  createdBy: Maybe<ProfileModel>;
  id: Scalars['ID'];
  originalPrompt: Maybe<PromptModel>;
  prompt: Scalars['String'];
  promptEdited: Scalars['Boolean'];
  status: Scalars['String'];
  type: GameType;
};

export type ChatModel = {
  __typename?: 'ChatModel';
  createdAt: Scalars['DateTime'];
  doubbleMatch: Maybe<DoubbleMatchModel>;
  id: Scalars['ID'];
  /** Uses messages() when querying. It's important that messages is the first field in the query as the take parameter in messages() is otherwise not respected */
  isSeen: Scalars['Boolean'];
  lastSeenAt: Maybe<Scalars['DateTime']>;
  latestGameAnswerAt: Maybe<Scalars['DateTime']>;
  /** @deprecated Use messages field instead */
  latestMessage: Maybe<MessageModel>;
  locked: Scalars['Boolean'];
  /** @deprecated Use users instead */
  matchUsers: Array<ChatUserModel>;
  messages: Maybe<Array<MessageModel>>;
  region: Region;
  type: Maybe<Scalars['String']>;
  typeId: Scalars['String'];
  users: Array<ChatUserModel>;
};


export type ChatModelMessagesArgs = {
  take: InputMaybe<Scalars['Float']>;
};

export type ChatSeenEventModel = {
  __typename?: 'ChatSeenEventModel';
  seenAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type ChatStreamEventModel = {
  __typename?: 'ChatStreamEventModel';
  chatId: Scalars['String'];
  chatSeen: Maybe<ChatSeenEventModel>;
  isUpdate: Scalars['Boolean'];
  message: Maybe<MessageModel>;
  reaction: Maybe<MessageReactionModel>;
  swipedProfileAttachment: Maybe<SwipedProfileAttachmentModel>;
  userStoppedTyping: Maybe<Scalars['String']>;
  userTyping: Maybe<Scalars['String']>;
};

export enum ChatType {
  CHAT = 'CHAT',
  CONTACT_CHAT = 'CONTACT_CHAT',
  DOUBBLE_MATCH_CHAT = 'DOUBBLE_MATCH_CHAT',
  MATCH_CHAT = 'MATCH_CHAT',
  PRE_USER_CHAT = 'PRE_USER_CHAT'
}

export type ChatUserModel = {
  __typename?: 'ChatUserModel';
  /** @deprecated Use profile.images instead */
  avatar: Scalars['String'];
  causedByBoost: Maybe<Scalars['Boolean']>;
  /** @deprecated Use profile.name instead */
  displayName: Scalars['String'];
  lastSeen: Maybe<Scalars['DateTime']>;
  profile: ProfileModel;
  /** @deprecated Use profile.id instead */
  profileId: Scalars['ID'];
  relationship: Scalars['String'];
  status: Maybe<ChatUserStatus>;
};

export type ComplimentModel = {
  __typename?: 'ComplimentModel';
  emoji: Scalars['String'];
  id: Scalars['String'];
  text: Scalars['String'];
  type: Maybe<ComplimentType>;
};

export type ConsumableItemModel = {
  __typename?: 'ConsumableItemModel';
  createdAt: Scalars['DateTime'];
  expiresAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  issuerUserId: Scalars['String'];
  trigger: ConsumableItemTrigger;
  triggerId: Scalars['String'];
  type: ConsumableItemType;
  usedAt: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ContactInviteProfileModel = {
  __typename?: 'ContactInviteProfileModel';
  gender: Maybe<Scalars['String']>;
  imageThumbnailUrl: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  name: Scalars['String'];
  username: Maybe<Scalars['String']>;
};

export type CreatePlaceInput = {
  address: AutoCompleteAddressInput;
  base64Image: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
};

export type DatingProfileInfoInput = {
  algorithm: InputMaybe<Scalars['Float']>;
  bio: InputMaybe<Scalars['String']>;
  birthDate: InputMaybe<Scalars['DateTime']>;
  city: InputMaybe<AutoCompleteAddressInput>;
  compliments: InputMaybe<Array<Scalars['String']>>;
  email: InputMaybe<Scalars['String']>;
  gender: InputMaybe<Gender>;
  height: InputMaybe<Scalars['Int']>;
  inboxPreference: InputMaybe<InboxPreference>;
  incognito: InputMaybe<Scalars['Boolean']>;
  interests: InputMaybe<Array<Scalars['String']>>;
  language: InputMaybe<Locales>;
  matchingActive: InputMaybe<Scalars['Boolean']>;
  maxSuggestionAge: InputMaybe<Scalars['Int']>;
  maxSuggestionDistance: InputMaybe<Scalars['Float']>;
  minSuggestionAge: InputMaybe<Scalars['Int']>;
  name: InputMaybe<Scalars['String']>;
  occupation: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  profileImageUrls: InputMaybe<Array<Scalars['String']>>;
  public: InputMaybe<Scalars['Boolean']>;
  school: InputMaybe<Scalars['String']>;
  sexualOrientations: InputMaybe<Array<Gender>>;
  strictAgeRange: InputMaybe<Scalars['Boolean']>;
  strictDistance: InputMaybe<Scalars['Boolean']>;
  username: InputMaybe<Scalars['String']>;
};

export type DeleteProfileInput = {
  delayed: Scalars['Boolean'];
  profileId: Scalars['String'];
};

export type DoubbleMatchDistributionModel = {
  __typename?: 'DoubbleMatchDistributionModel';
  count: Scalars['Float'];
  doubbleMatches: Scalars['Float'];
};

export type DoubbleMatchModel = AbstractMatchModel & {
  __typename?: 'DoubbleMatchModel';
  /** @deprecated Use chat.createdAt instead */
  createdAt: Maybe<Scalars['DateTime']>;
  doubbleChatId: Scalars['String'];
  expireAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isSeen: Maybe<Scalars['Boolean']>;
  lastSeenAt: Maybe<Scalars['DateTime']>;
  latestMessageAt: Maybe<Scalars['DateTime']>;
  match: ProfileModel;
  matchId: Scalars['String'];
  matchTeamMateId: Scalars['String'];
  teamMateId: Scalars['String'];
  type: MatchType;
  userId: Scalars['String'];
  users: Array<ChatUserModel>;
};

export type EditPlaceInput = {
  address: InputMaybe<AutoCompleteAddressInput>;
  base64Image: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: InputMaybe<Scalars['String']>;
  phoneNumber: InputMaybe<Scalars['String']>;
};

export type ExpiringDoubbleMatchesModel = {
  __typename?: 'ExpiringDoubbleMatchesModel';
  count: Scalars['Float'];
  image: Maybe<ImageModel>;
};

export enum FeedbackType {
  DEACTIVATED_PROFILE = 'DEACTIVATED_PROFILE',
  DELETING_PROFILE = 'DELETING_PROFILE',
  FEEDBACK = 'FEEDBACK'
}

export type FriendSuggestionModel = {
  __typename?: 'FriendSuggestionModel';
  category: FriendSuggestionsCategory;
  contact: Maybe<PhoneNumberInviteModel>;
  profile: Maybe<ProfileModel>;
};

export enum GameType {
  CAMERA_NOW = 'CAMERA_NOW',
  FREE_TEXT = 'FREE_TEXT',
  HOT_TAKE = 'HOT_TAKE',
  MOST_LIKELY_TO = 'MOST_LIKELY_TO',
  NEVER_HAVE_I_EVER = 'NEVER_HAVE_I_EVER',
  PHOTO_CHALLENGE = 'PHOTO_CHALLENGE',
  RED_FLAG_GREEN_FLAG = 'RED_FLAG_GREEN_FLAG',
  ROULETTE = 'ROULETTE',
  VOICE_CHALLENGE = 'VOICE_CHALLENGE',
  WHO_IS_WHO = 'WHO_IS_WHO'
}

export type GenderDistributionModel = {
  __typename?: 'GenderDistributionModel';
  count: Scalars['Float'];
  gender: Scalars['String'];
};

export type GetSuspensionsInput = {
  phoneNumber: Scalars['String'];
  userId: Scalars['String'];
};

export type ImageModel = {
  __typename?: 'ImageModel';
  blurhash: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  height: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Float']>;
  thumbnailUrl: Maybe<Scalars['String']>;
  /** @deprecated use blurhash instead */
  tinyUrl: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Float']>;
};

export type InstagramMediaModel = {
  __typename?: 'InstagramMediaModel';
  caption: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Only available for reels */
  isSharedToFeed: Maybe<Scalars['Boolean']>;
  mediaType: Scalars['String'];
  mediaUrl: Scalars['String'];
  permalink: Scalars['String'];
  /** Only available for videos */
  thumbnailUrl: Maybe<Scalars['String']>;
  timestamp: Scalars['String'];
  username: Scalars['String'];
};

export type InterestModel = {
  __typename?: 'InterestModel';
  category: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type InviteCodeModel = {
  __typename?: 'InviteCodeModel';
  autoInvite: Scalars['Boolean'];
  code: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isUnlock: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type InviteModel = {
  __typename?: 'InviteModel';
  autoInvite: Scalars['Boolean'];
  friends: Array<ContactInviteProfileModel>;
  inviteCode: Maybe<Scalars['String']>;
  match: Maybe<ContactInviteProfileModel>;
  profile: ContactInviteProfileModel;
};

export type InvitesDataModel = {
  __typename?: 'InvitesDataModel';
  count: Scalars['Float'];
  lastReceivedAt: Maybe<Scalars['DateTime']>;
};

export type LikedMeModel = {
  __typename?: 'LikedMeModel';
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDisliked: Scalars['Boolean'];
  profile: ProfileModel;
};

export type LikesAndDislikesModel = {
  __typename?: 'LikesAndDislikesModel';
  dislikes: Scalars['Float'];
  likeToDislikeRatio: Scalars['Float'];
  likes: Scalars['Float'];
};

export type LocationModel = {
  __typename?: 'LocationModel';
  cells: CellModel;
  countryCode: Maybe<Scalars['String']>;
  didChangeRegion: Scalars['Boolean'];
  /** Distance moved in kilometers */
  distanceMoved: Maybe<Scalars['Float']>;
  jwtToken: Maybe<Scalars['String']>;
  location: PointModel;
  onWaitlist: Maybe<Scalars['Boolean']>;
  withinSupportedRegion: Maybe<Scalars['Boolean']>;
};

export type LogoutInput = {
  refreshToken: Scalars['String'];
};

export type MatchModel = AbstractMatchModel & {
  __typename?: 'MatchModel';
  createdAt: Maybe<Scalars['DateTime']>;
  match: ProfileModel;
  matchId: Scalars['String'];
  type: MatchType;
  userId: Scalars['String'];
};

export type MatchesFilterInput = {
  matchId: InputMaybe<Scalars['String']>;
  spacetime: InputMaybe<SpacetimeFilter>;
  teamMateId: InputMaybe<Scalars['String']>;
  type: InputMaybe<MatchType>;
};

export type MatchesGroupByInput = {
  match: Scalars['Boolean'];
};

export type MessageAttachmentModel = {
  __typename?: 'MessageAttachmentModel';
  data: Scalars['String'];
  /** Specifically to resolve GAME_ANSWER_REPLY attachments, since these need translation and "HIDDEN" status */
  gameAnswer: Maybe<AnswerModel>;
  id: Scalars['ID'];
  kind: MessageAttachmentKind;
  userId: Maybe<Scalars['String']>;
};

export type MessageModel = {
  __typename?: 'MessageModel';
  attachments: Array<MessageAttachmentModel>;
  chat: ChatModel;
  chatId: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  gameAnswer: Maybe<AnswerModel>;
  id: Scalars['ID'];
  sender: ChatUserModel;
  senderId: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type MessageReactionModel = {
  __typename?: 'MessageReactionModel';
  emoji: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptChatRequest: Scalars['Boolean'];
  acceptGiftedBoostInvitation: BoostModel;
  addNotificationToken: Scalars['Boolean'];
  adminDeleteAccount: Scalars['Boolean'];
  adminRemoveProfileImage: Scalars['Boolean'];
  /** Ask a reporter for elaboration on a report */
  askForElaboration: Scalars['Boolean'];
  authenticateInstagram: Scalars['Boolean'];
  cancelAccountDeletion: Scalars['Boolean'];
  cancelInvite: Scalars['Boolean'];
  changeNotificationsOptions: Array<NotificationOptionModel>;
  changeNumber: Scalars['String'];
  /** Deletes all reports given to a specific profile */
  clearReportsOnUser: Scalars['Boolean'];
  consumeMatchChatUnlock: Scalars['Boolean'];
  /** @deprecated Use createProfile instead */
  createDatingProfile: ProfileModel;
  createInviteCode: InviteCodeModel;
  createPlace: PlaceModel;
  createProfile: ProfileModel;
  /** Creates a warning for a specific profile */
  createWarning: Scalars['Boolean'];
  /** @deprecated Use deleteAccountV2 instead */
  deleteAccount: Scalars['Boolean'];
  deleteAccountV2: Scalars['Boolean'];
  deletePlace: PlaceModel;
  deletePlaceSuggestions: Scalars['Boolean'];
  disableNotificationToken: Scalars['Boolean'];
  /** @deprecated Use swipe instead. */
  dislike: Scalars['Boolean'];
  dismissContactChat: Scalars['Boolean'];
  /** @deprecated Use editProfile instead */
  editDatingProfile: ProfileModel;
  editPlace: PlaceModel;
  editProfile: ProfileModel;
  editUser: UserModel;
  /** Enables paywall for user */
  enablePaywall: Scalars['Boolean'];
  fakeCompletedPhotoTest: PhotoTestModel;
  fakeCompliment: Scalars['Boolean'];
  fakeConversation: Scalars['Boolean'];
  fakeDoubbleMatch: Scalars['Boolean'];
  fakeInvite: Scalars['Boolean'];
  fakeMatch: Scalars['Boolean'];
  fakeMatchBetweenSwipers: Scalars['Boolean'];
  fakeMatchWithId: Scalars['Boolean'];
  fakeNewUserNotification: Scalars['Boolean'];
  fakePhotoTest: PhotoTestModel;
  fakeQueue: Scalars['Boolean'];
  fakeRandomCandidateStats: Scalars['String'];
  getSuspensions: Scalars['Boolean'];
  grantConsumable: Scalars['Boolean'];
  /** @deprecated Might be relevant for client-only invite actions (e.g. Snap) in the future. TODO: Remove after 01-05-2023 if not used. */
  grantUnlimitedLikes: Scalars['Boolean'];
  handleLoginLinkToken: AuthTokensModel;
  ignoreChatRequest: Scalars['Boolean'];
  ignoreContact: Scalars['Boolean'];
  ignoreTeammateSuggestion: Scalars['Boolean'];
  inviteContact: PhoneNumberInviteModel;
  inviteFriendToBoost: BoostModel;
  inviteProfile: Scalars['Boolean'];
  /** @deprecated use inviteProfile instead */
  inviteProfileV2: Scalars['Boolean'];
  isTyping: Scalars['Boolean'];
  /** @deprecated Use swipe instead */
  like: SwipeModel;
  likeCompliment: Scalars['Boolean'];
  login: AuthTokensModel;
  /** @deprecated Use login instead */
  loginV2: AuthTokensModel;
  logout: Scalars['Boolean'];
  markWarningsAsSeen: Scalars['Boolean'];
  messageSeen: Scalars['DateTime'];
  pingToAddFriends: Scalars['Boolean'];
  playChatGame: Scalars['Boolean'];
  populateProfileCache: Scalars['Boolean'];
  reactToMessage: Scalars['Boolean'];
  recommendPlace: MessageModel;
  refreshAuthTokens: AuthTokensModel;
  /** @deprecated Use refreshAuthTokens instead */
  refreshAuthTokensV2: AuthTokensModel;
  removeImageFromProfile: Scalars['Boolean'];
  removeTeammate: Scalars['Boolean'];
  /** Removes name/gender limit */
  removeUpdateLimit: Scalars['Boolean'];
  replyToCompliment: Scalars['Boolean'];
  replyToGame: MessageModel;
  /** @deprecated Use replyToGameAnswerV2 */
  replyToGameAnswer: MessageModel;
  replyToGameAnswerV2: MessageModel;
  reportContent: Scalars['Boolean'];
  requestEmailLogin: Scalars['Boolean'];
  requestLoginV2: OtpRequestModel;
  requestNumberChange: OtpRequestModel;
  requestUserCreation: OtpRequestModel;
  respondToInvite: Maybe<ProfileModel>;
  revealPhotoTest: PhotoTestModel;
  revokeSuspension: Scalars['Boolean'];
  saveCandidateStats: Scalars['String'];
  saveDeviceInfo: Scalars['Boolean'];
  saveProfileContacts: Scalars['Boolean'];
  savePrompt: PromptModel;
  saveRedisConfig: Scalars['Boolean'];
  seenCompliment: Scalars['Boolean'];
  seenNotification: Scalars['Boolean'];
  sendCompliment: ProfileComplimentModel;
  sendFeedback: Scalars['Boolean'];
  sendGif: MessageModel;
  sendMessage: MessageModel;
  /** @deprecated Use sendMessage instead */
  sendMessageV2: MessageModel;
  setDeepLinkRemotely: Scalars['Float'];
  setLocation: LocationModel;
  setMatchingStatus: Scalars['Boolean'];
  setSuspension: SuspensionModel;
  shareProfile: Array<MessageModel>;
  skipCompliment: Scalars['Boolean'];
  startBoost: BoostModel;
  startChatGame: ChatGameModel;
  startPhotoTest: PhotoTestModel;
  stoppedTyping: Scalars['Boolean'];
  submitAnswer: AnswerModel;
  submitSingleAnswerGames: Array<AnswerModel>;
  suggestPlace: Scalars['Boolean'];
  swipe: SwipeModel;
  swipedSharedProfile: Scalars['Boolean'];
  testNotification: TestNotificationModel;
  testNotificationToken: Scalars['Boolean'];
  undoLikeOrDislike: Scalars['Boolean'];
  unlockChat: Scalars['Boolean'];
  unmatch: Scalars['Boolean'];
  unmatchDoubbleMatch: Scalars['Boolean'];
  updateActivity: Scalars['Boolean'];
  updateCandidateStats: Scalars['Boolean'];
  /** Updates wait list status */
  updateWaitListStatus: Scalars['Boolean'];
  verifyAndCreateUser: AuthTokensModel;
  waitingList: Scalars['Boolean'];
  whitelistPhoneNumber: Scalars['Boolean'];
  withinSupportedRegion: Scalars['Boolean'];
};


export type MutationAcceptChatRequestArgs = {
  chatId: Scalars['String'];
};


export type MutationAcceptGiftedBoostInvitationArgs = {
  id: Scalars['String'];
};


export type MutationAddNotificationTokenArgs = {
  token: Scalars['String'];
};


export type MutationAdminDeleteAccountArgs = {
  deleteInput: DeleteProfileInput;
};


export type MutationAdminRemoveProfileImageArgs = {
  imageUrl: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAskForElaborationArgs = {
  reportId: InputMaybe<Scalars['String']>;
};


export type MutationAuthenticateInstagramArgs = {
  code: Scalars['String'];
};


export type MutationCancelInviteArgs = {
  invitedId: Scalars['String'];
};


export type MutationChangeNotificationsOptionsArgs = {
  notificationOptions: Array<NotificationOptionInput>;
  token: Scalars['String'];
};


export type MutationChangeNumberArgs = {
  id: Scalars['String'];
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};


export type MutationClearReportsOnUserArgs = {
  userId: Scalars['String'];
};


export type MutationConsumeMatchChatUnlockArgs = {
  chatId: Scalars['String'];
};


export type MutationCreateDatingProfileArgs = {
  email: Scalars['String'];
  info: DatingProfileInfoInput;
  username: Scalars['String'];
};


export type MutationCreateInviteCodeArgs = {
  autoInvite: InputMaybe<Scalars['Boolean']>;
  description: InputMaybe<Scalars['String']>;
  phoneNumber: InputMaybe<Scalars['String']>;
  sharedProfileId: InputMaybe<Scalars['String']>;
  unlockMatchId: InputMaybe<Scalars['String']>;
};


export type MutationCreatePlaceArgs = {
  createPlaceInput: CreatePlaceInput;
};


export type MutationCreateProfileArgs = {
  email: Scalars['String'];
  info: ProfileInfoInput;
  username: Scalars['String'];
};


export type MutationCreateWarningArgs = {
  reason: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  feedback: InputMaybe<UserFeedbackInput>;
};


export type MutationDeleteAccountV2Args = {
  feedback: InputMaybe<UserFeedbackInput>;
};


export type MutationDeletePlaceArgs = {
  placeId: Scalars['String'];
};


export type MutationDeletePlaceSuggestionsArgs = {
  suggestionIds?: Array<Scalars['String']>;
};


export type MutationDisableNotificationTokenArgs = {
  token: Scalars['String'];
};


export type MutationDislikeArgs = {
  category: InputMaybe<Scalars['Float']>;
  profileId: Scalars['String'];
};


export type MutationDismissContactChatArgs = {
  chatId: Scalars['String'];
};


export type MutationEditDatingProfileArgs = {
  info: DatingProfileInfoInput;
};


export type MutationEditPlaceArgs = {
  input: EditPlaceInput;
};


export type MutationEditProfileArgs = {
  info: ProfileInfoInput;
};


export type MutationEditUserArgs = {
  email: InputMaybe<Scalars['String']>;
  language: InputMaybe<Locales>;
  phone: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
};


export type MutationEnablePaywallArgs = {
  userId: Scalars['String'];
};


export type MutationFakeCompletedPhotoTestArgs = {
  revealed: InputMaybe<Scalars['Boolean']>;
};


export type MutationFakeComplimentArgs = {
  complimentId: InputMaybe<Scalars['String']>;
  fromFriend: InputMaybe<Scalars['Boolean']>;
  userId: InputMaybe<Scalars['String']>;
};


export type MutationFakeConversationArgs = {
  amountOfMessages: Scalars['Float'];
  chatId: InputMaybe<Scalars['String']>;
  chatType: Scalars['String'];
  typeId: InputMaybe<Scalars['String']>;
  userId: InputMaybe<Scalars['String']>;
};


export type MutationFakeInviteArgs = {
  username: InputMaybe<Scalars['String']>;
};


export type MutationFakeMatchBetweenSwipersArgs = {
  id1: Scalars['String'];
  id2: Scalars['String'];
};


export type MutationFakeMatchWithIdArgs = {
  matchId: Scalars['String'];
};


export type MutationGetSuspensionsArgs = {
  query: GetSuspensionsInput;
};


export type MutationGrantConsumableArgs = {
  expiresAt: InputMaybe<Scalars['String']>;
  type: ConsumableItemType;
};


export type MutationHandleLoginLinkTokenArgs = {
  token: Scalars['String'];
};


export type MutationIgnoreChatRequestArgs = {
  chatId: Scalars['String'];
};


export type MutationIgnoreContactArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationIgnoreTeammateSuggestionArgs = {
  profileToIgnore: Scalars['String'];
};


export type MutationInviteContactArgs = {
  inviteCode: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};


export type MutationInviteFriendToBoostArgs = {
  friendId: Scalars['String'];
};


export type MutationInviteProfileArgs = {
  id: Scalars['String'];
};


export type MutationInviteProfileV2Args = {
  username: Scalars['String'];
};


export type MutationIsTypingArgs = {
  chatId: Scalars['String'];
};


export type MutationLikeArgs = {
  category: InputMaybe<Scalars['Float']>;
  profileId: Scalars['String'];
};


export type MutationLikeComplimentArgs = {
  complimentId: Scalars['String'];
  complimenterUserId: Scalars['String'];
  like: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  id: Scalars['String'];
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};


export type MutationLoginV2Args = {
  id: Scalars['String'];
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};


export type MutationLogoutArgs = {
  logoutInput: LogoutInput;
};


export type MutationMessageSeenArgs = {
  chatId: InputMaybe<Scalars['String']>;
};


export type MutationPingToAddFriendsArgs = {
  userId: Scalars['String'];
};


export type MutationPlayChatGameArgs = {
  chatId: Scalars['String'];
  customPrompt: InputMaybe<Scalars['String']>;
};


export type MutationReactToMessageArgs = {
  chatId: Scalars['String'];
  emoji: InputMaybe<Scalars['String']>;
  messageId: Scalars['String'];
};


export type MutationRecommendPlaceArgs = {
  chatId: Scalars['String'];
  placeId: Scalars['String'];
};


export type MutationRefreshAuthTokensArgs = {
  refreshAuthTokensInput: RefreshAuthTokensInput;
};


export type MutationRefreshAuthTokensV2Args = {
  refreshAuthTokensInput: RefreshAuthTokensInput;
};


export type MutationRemoveImageFromProfileArgs = {
  imageUrl: Scalars['String'];
};


export type MutationRemoveTeammateArgs = {
  teammateId: Scalars['String'];
};


export type MutationRemoveUpdateLimitArgs = {
  userId: Scalars['String'];
};


export type MutationReplyToComplimentArgs = {
  complimentId: Scalars['String'];
  complimenterUserId: Scalars['String'];
  message: Scalars['String'];
};


export type MutationReplyToGameArgs = {
  input: ReplyToGameInput;
};


export type MutationReplyToGameAnswerArgs = {
  input: ReplyToGameAnswerInput;
};


export type MutationReplyToGameAnswerV2Args = {
  input: ReplyToGameAnswerInputV2;
};


export type MutationReportContentArgs = {
  report: ReportInput;
};


export type MutationRequestEmailLoginArgs = {
  email: Scalars['String'];
};


export type MutationRequestLoginV2Args = {
  isRetry?: InputMaybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
};


export type MutationRequestNumberChangeArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationRequestUserCreationArgs = {
  isRetry?: InputMaybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
};


export type MutationRespondToInviteArgs = {
  inviterId: Scalars['String'];
  response: InviteResponse;
};


export type MutationRevealPhotoTestArgs = {
  photoTestId: Scalars['String'];
};


export type MutationRevokeSuspensionArgs = {
  revokeInput: RevokeSuspensionInput;
};


export type MutationSaveCandidateStatsArgs = {
  input: CandidateStatsInput;
};


export type MutationSaveDeviceInfoArgs = {
  input: SaveDeviceInfoArgs;
};


export type MutationSaveProfileContactsArgs = {
  contactInformationInputs?: Array<ProfileContactInformationInput>;
};


export type MutationSavePromptArgs = {
  input: SavePromptInput;
};


export type MutationSaveRedisConfigArgs = {
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MutationSeenComplimentArgs = {
  complimentId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSeenNotificationArgs = {
  id: Scalars['String'];
  opened?: Scalars['Boolean'];
};


export type MutationSendComplimentArgs = {
  complimentId: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
  pollUsers: Array<PollUserData>;
  userId: InputMaybe<Scalars['String']>;
};


export type MutationSendFeedbackArgs = {
  feedback: InputMaybe<UserFeedbackInput>;
};


export type MutationSendGifArgs = {
  chatId: Scalars['String'];
  giphyId: Scalars['String'];
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationSendMessageV2Args = {
  input: SendMessageInput;
};


export type MutationSetDeepLinkRemotelyArgs = {
  link: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSetLocationArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type MutationSetMatchingStatusArgs = {
  status: Scalars['Boolean'];
};


export type MutationSetSuspensionArgs = {
  suspensionInput: SuspensionInput;
};


export type MutationShareProfileArgs = {
  input: ShareProfileInput;
};


export type MutationSkipComplimentArgs = {
  complimentId: Scalars['String'];
};


export type MutationStartBoostArgs = {
  friendId: InputMaybe<Scalars['String']>;
};


export type MutationStartChatGameArgs = {
  input: StartChatGameInput;
};


export type MutationStartPhotoTestArgs = {
  input: StartPhotoTestInput;
};


export type MutationStoppedTypingArgs = {
  chatId: Scalars['String'];
};


export type MutationSubmitAnswerArgs = {
  input: SubmitAnswerInput;
};


export type MutationSubmitSingleAnswerGamesArgs = {
  input: SubmitSingleAnswersInput;
};


export type MutationSuggestPlaceArgs = {
  suggestion: PlaceSuggestionInput;
};


export type MutationSwipeArgs = {
  category: Scalars['Float'];
  like: Scalars['Boolean'];
  userId: Scalars['String'];
};


export type MutationSwipedSharedProfileArgs = {
  chatId: Scalars['String'];
  liked: Scalars['Boolean'];
  messageId: Scalars['String'];
};


export type MutationTestNotificationArgs = {
  data: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};


export type MutationTestNotificationTokenArgs = {
  token: Scalars['String'];
};


export type MutationUndoLikeOrDislikeArgs = {
  swipedId: Scalars['String'];
};


export type MutationUnlockChatArgs = {
  chatId: Scalars['String'];
};


export type MutationUnmatchArgs = {
  userId: Scalars['String'];
};


export type MutationUnmatchDoubbleMatchArgs = {
  doubbleMatchId: Scalars['String'];
};


export type MutationUpdateCandidateStatsArgs = {
  input: CandidateStatsInput;
};


export type MutationUpdateWaitListStatusArgs = {
  onWaitList: Scalars['Boolean'];
  userId: Scalars['String'];
};


export type MutationVerifyAndCreateUserArgs = {
  autoInvite?: InputMaybe<Scalars['Boolean']>;
  inviteCode: InputMaybe<Scalars['String']>;
  locale?: Scalars['String'];
  otpCode: Scalars['String'];
  otpId: Scalars['String'];
  phoneNumber: InputMaybe<Scalars['String']>;
  referralUserId: InputMaybe<Scalars['String']>;
};


export type MutationWaitingListArgs = {
  city: InputMaybe<Scalars['String']>;
  countryCode: InputMaybe<Scalars['String']>;
  latitude: InputMaybe<Scalars['Float']>;
  longitude: InputMaybe<Scalars['Float']>;
  region: InputMaybe<Scalars['String']>;
};


export type MutationWhitelistPhoneNumberArgs = {
  input: WhitelistPhoneNumberInput;
};


export type MutationWithinSupportedRegionArgs = {
  city: InputMaybe<Scalars['String']>;
  countryCode: InputMaybe<Scalars['String']>;
  latitude: InputMaybe<Scalars['Float']>;
  longitude: InputMaybe<Scalars['Float']>;
  region: InputMaybe<Scalars['String']>;
};

export type NotificationOptionInput = {
  allowed: Scalars['Boolean'];
  type: Scalars['String'];
};

export type NotificationOptionModel = {
  __typename?: 'NotificationOptionModel';
  allowed: Scalars['Boolean'];
  type: Scalars['String'];
};

export type OtpRequestModel = {
  __typename?: 'OtpRequestModel';
  code: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type PaginatedAbstractMatchModelCursorResponse = {
  __typename?: 'PaginatedAbstractMatchModelCursorResponse';
  cursor: Maybe<Scalars['String']>;
  items: Array<AbstractMatchModel>;
  totalCount: Maybe<Scalars['Int']>;
};

export type PaginatedAnswerModelCursorResponse = {
  __typename?: 'PaginatedAnswerModelCursorResponse';
  cursor: Maybe<Scalars['String']>;
  items: Array<AnswerModel>;
  totalCount: Maybe<Scalars['Int']>;
};

export type PaginatedAuditlogModelResponse = {
  __typename?: 'PaginatedAuditlogModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<AuditlogModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedChatGameModelCursorResponse = {
  __typename?: 'PaginatedChatGameModelCursorResponse';
  cursor: Maybe<Scalars['String']>;
  items: Array<ChatGameModel>;
  totalCount: Maybe<Scalars['Int']>;
};

export type PaginatedChatModelResponse = {
  __typename?: 'PaginatedChatModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<ChatModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedFriendSuggestionModelCursorResponse = {
  __typename?: 'PaginatedFriendSuggestionModelCursorResponse';
  cursor: Maybe<Scalars['String']>;
  items: Array<FriendSuggestionModel>;
  totalCount: Maybe<Scalars['Int']>;
};

export type PaginatedImageModelResponse = {
  __typename?: 'PaginatedImageModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<ImageModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedInstagramMediaModelCursorResponse = {
  __typename?: 'PaginatedInstagramMediaModelCursorResponse';
  cursor: Maybe<Scalars['String']>;
  items: Array<InstagramMediaModel>;
  totalCount: Maybe<Scalars['Int']>;
};

export type PaginatedMessageModelResponse = {
  __typename?: 'PaginatedMessageModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<MessageModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedPhotoTestModelResponse = {
  __typename?: 'PaginatedPhotoTestModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<PhotoTestModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedPlaceModelResponse = {
  __typename?: 'PaginatedPlaceModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<PlaceModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedPlaceSuggestionModelResponse = {
  __typename?: 'PaginatedPlaceSuggestionModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<PlaceSuggestionModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedProfileModelResponse = {
  __typename?: 'PaginatedProfileModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<ProfileModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedPromptModelCursorResponse = {
  __typename?: 'PaginatedPromptModelCursorResponse';
  cursor: Maybe<Scalars['String']>;
  items: Array<PromptModel>;
  totalCount: Maybe<Scalars['Int']>;
};

export type PaginatedPromptModelResponse = {
  __typename?: 'PaginatedPromptModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<PromptModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedReportModelResponse = {
  __typename?: 'PaginatedReportModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<ReportModel>;
  total: Maybe<Scalars['Int']>;
};

export type PaginatedReportedProfileModelResponse = {
  __typename?: 'PaginatedReportedProfileModelResponse';
  hasMore: Maybe<Scalars['Boolean']>;
  items: Array<ReportedProfileModel>;
  total: Maybe<Scalars['Int']>;
};

export type PhoneNumberInviteModel = {
  __typename?: 'PhoneNumberInviteModel';
  commonFriends: Scalars['Float'];
  createdAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['String']>;
  inviteCode: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  phoneNumberScore: Maybe<Scalars['Float']>;
  referenceId: Maybe<Scalars['String']>;
};

export type PhotoTestImageModel = {
  __typename?: 'PhotoTestImageModel';
  createdAt: Scalars['DateTime'];
  image: Maybe<ImageModel>;
  score: Maybe<Scalars['Float']>;
};

export type PhotoTestModel = {
  __typename?: 'PhotoTestModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  images: Maybe<Array<PhotoTestImageModel>>;
  progress: Maybe<Scalars['Float']>;
  status: PhotoTestStatus;
};

export enum PhotoTestStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  REVEALED = 'REVEALED'
}

export type PlaceDistanceModel = {
  __typename?: 'PlaceDistanceModel';
  distance: Scalars['Float'];
  place: PlaceModel;
};

export type PlaceModel = {
  __typename?: 'PlaceModel';
  address: AutoCompleteAddressModel;
  category: Scalars['String'];
  id: Scalars['ID'];
  image: Maybe<ImageModel>;
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  statistics: Maybe<PlaceStatisticModel>;
};

export type PlaceStatisticModel = {
  __typename?: 'PlaceStatisticModel';
  recommendedCount: Maybe<Scalars['Float']>;
};

export type PlaceSuggestionInput = {
  text: Scalars['String'];
};

export type PlaceSuggestionModel = {
  __typename?: 'PlaceSuggestionModel';
  cleanName: Scalars['String'];
  count: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Ids of other suggestions matching this place suggestion */
  ids: Array<Scalars['String']>;
  latestSuggestedAt: Scalars['DateTime'];
  name: Scalars['String'];
  profiles: Array<ProfileModel>;
  userIds: Array<Scalars['String']>;
};

export type PointModel = {
  __typename?: 'PointModel';
  coordinates: Array<Scalars['Float']>;
};

export type PollGameModel = {
  __typename?: 'PollGameModel';
  compliments: Array<ComplimentModel>;
  complimentsPerDay: Scalars['Float'];
  users: Array<PollUserModel>;
};

export type PollUserData = {
  id: Scalars['String'];
  relation: PollUserRelation;
};

export type PollUserModel = {
  __typename?: 'PollUserModel';
  avatar: Maybe<ImageModel>;
  description: Scalars['String'];
  hasAnyCompliments: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  relation: Scalars['String'];
  relevantCompliments: Array<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  weight: Maybe<Scalars['Float']>;
};

export type PolygonModel = {
  __typename?: 'PolygonModel';
  coordinates: Array<Array<Array<Scalars['Float']>>>;
};

export type PotentialDoubbleMatchesWithModel = {
  __typename?: 'PotentialDoubbleMatchesWithModel';
  friend: ProfileModel;
  matches: Array<ProfileModel>;
};

export type ProfileComplimentModel = {
  __typename?: 'ProfileComplimentModel';
  compliment: Maybe<ComplimentModel>;
  complimentId: Maybe<Scalars['String']>;
  complimented: Maybe<ProfileModel>;
  complimentedUserId: Maybe<Scalars['String']>;
  complimenter: Maybe<ProfileModel>;
  complimenterId: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  liked: Maybe<Scalars['Boolean']>;
  seenAt: Maybe<Scalars['DateTime']>;
};

export type ProfileContactInformationInput = {
  birthDate: InputMaybe<Scalars['String']>;
  company: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  hasEmail: InputMaybe<Scalars['Boolean']>;
  hasPhoto: InputMaybe<Scalars['Boolean']>;
  lastName: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  referenceId: InputMaybe<Scalars['String']>;
};

export type ProfileInfoInput = {
  algorithm: InputMaybe<Scalars['Float']>;
  bio: InputMaybe<Scalars['String']>;
  birthDate: InputMaybe<Scalars['DateTime']>;
  city: InputMaybe<AutoCompleteAddressInput>;
  compliments: InputMaybe<Array<Scalars['String']>>;
  email: InputMaybe<Scalars['String']>;
  gender: InputMaybe<Gender>;
  height: InputMaybe<Scalars['Int']>;
  inboxPreference: InputMaybe<InboxPreference>;
  incognito: InputMaybe<Scalars['Boolean']>;
  interests: InputMaybe<Array<Scalars['String']>>;
  language: InputMaybe<Locales>;
  matchingActive: InputMaybe<Scalars['Boolean']>;
  maxSuggestionAge: InputMaybe<Scalars['Int']>;
  maxSuggestionDistance: InputMaybe<Scalars['Float']>;
  minSuggestionAge: InputMaybe<Scalars['Int']>;
  name: InputMaybe<Scalars['String']>;
  occupation: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  profileImageUrls: InputMaybe<Array<Scalars['String']>>;
  public: InputMaybe<Scalars['Boolean']>;
  school: InputMaybe<Scalars['String']>;
  sexualOrientations: InputMaybe<Array<Gender>>;
  strictAgeRange: InputMaybe<Scalars['Boolean']>;
  strictDistance: InputMaybe<Scalars['Boolean']>;
  username: InputMaybe<Scalars['String']>;
};

export type ProfileLocationModel = {
  __typename?: 'ProfileLocationModel';
  c: Array<Scalars['Float']>;
  g: Maybe<Scalars['String']>;
  h: Scalars['Float'];
};

export type ProfileModel = {
  __typename?: 'ProfileModel';
  appFeatures: Array<AppFeature>;
  appVersion: Scalars['String'];
  area: Maybe<Scalars['String']>;
  avatar: Maybe<ImageModel>;
  bio: Maybe<Scalars['String']>;
  birthDate: Maybe<Scalars['DateTime']>;
  causedByBoost: Maybe<Scalars['Boolean']>;
  cell: Scalars['String'];
  city: Maybe<Scalars['String']>;
  commonTeamMatesCount: Scalars['Float'];
  compliments: Array<ProfileComplimentModel>;
  country: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  devicePlatform: Scalars['String'];
  deviceType: Scalars['String'];
  distance: Maybe<Scalars['Float']>;
  /** @deprecated Fetch from doubble-match resolver instead */
  doubbleMatches: Array<DoubbleMatchModel>;
  email: Maybe<Scalars['String']>;
  friendChatId: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  hasLocation: Scalars['Boolean'];
  /** @deprecated Use appFeatures instead */
  hasPaywall: Scalars['Boolean'];
  height: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  images: Array<ImageModel>;
  inContacts: Scalars['Boolean'];
  inboxPreference: Maybe<InboxPreference>;
  incognito: Maybe<Scalars['Boolean']>;
  interests: Array<InterestModel>;
  ipAddress: Scalars['String'];
  isCommonTeamMate: Scalars['Boolean'];
  language: Maybe<Scalars['String']>;
  lastActive: Maybe<Scalars['DateTime']>;
  logs: Array<UserLogModel>;
  matchChatId: Maybe<Scalars['String']>;
  matchingActive: Maybe<Scalars['Boolean']>;
  maxSuggestionAge: Maybe<Scalars['Int']>;
  maxSuggestionDistance: Maybe<Scalars['Float']>;
  minSuggestionAge: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  /** Fetches amount of matches _together_ with this profile. TOD: When querying self, instead of returning 0, you could fetch amount of matches you have with "yourself" */
  numberOfMatches: Scalars['Float'];
  numberOfTeammates: Maybe<Scalars['Float']>;
  occupation: Maybe<Scalars['String']>;
  onWaitlist: Scalars['Boolean'];
  operatingSystem: Scalars['String'];
  phoneExtension: Scalars['Float'];
  phoneNumber: Scalars['String'];
  potentialDoubbleMatches: Array<ProfileModel>;
  potentialDoubbleMatchesCount: Scalars['Float'];
  potentialDoubbleMatchesWith: Array<PotentialDoubbleMatchesWithModel>;
  public: Scalars['Boolean'];
  reports: Maybe<Array<ReportModel>>;
  school: Maybe<Scalars['String']>;
  sexualOrientations: Maybe<Array<Scalars['String']>>;
  strictAgeRange: Maybe<Scalars['Boolean']>;
  strictDistance: Maybe<Scalars['Boolean']>;
  supportVerificationHash: Maybe<Scalars['String']>;
  suspension: Maybe<SuspensionModel>;
  suspensions: Array<SuspensionModel>;
  teamMates: Array<ProfileModel>;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** @deprecated Use id instead */
  userId: Maybe<Scalars['ID']>;
  userRegion: Maybe<Region>;
  username: Maybe<Scalars['String']>;
  warnings: Maybe<Array<ProfileWarningModel>>;
  withinSupportedRegion: Scalars['Boolean'];
};


export type ProfileModelDoubbleMatchesArgs = {
  useTTQ?: InputMaybe<Scalars['Boolean']>;
};


export type ProfileModelPotentialDoubbleMatchesArgs = {
  useTTQ?: InputMaybe<Scalars['Boolean']>;
};

export type ProfileWarningModel = {
  __typename?: 'ProfileWarningModel';
  callerId: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  reason: Maybe<Scalars['String']>;
  userSeen: Scalars['Boolean'];
};

export type PromptConstraintModel = {
  __typename?: 'PromptConstraintModel';
  endTime: Maybe<Scalars['DateTime']>;
  limit: Maybe<Scalars['Float']>;
  searchTag: Maybe<Scalars['String']>;
  specificLibrary: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['DateTime']>;
};

export type PromptModel = {
  __typename?: 'PromptModel';
  chatTypes: Maybe<Array<ChatType>>;
  constraints: Maybe<PromptConstraintModel>;
  content: Scalars['String'];
  gameType: GameType;
  id: Scalars['String'];
  locale: Maybe<Locales>;
  prefillType: TagPrefillType;
};

export type Query = {
  __typename?: 'Query';
  activeBoost: Maybe<BoostModel>;
  adminGeocode: Array<AutoCompleteAddressModel>;
  adminGetUser: ProfileModel;
  aegisReports: Array<AegisReportModel>;
  ageDistribution: Array<AgeDistributionModel>;
  albumPreferrenceWeights: Array<AlbumInfo>;
  allRedisConfigs: Array<Scalars['String']>;
  /** @deprecated For old admin */
  allReports: PaginatedReportModelResponse;
  answers: PaginatedAnswerModelCursorResponse;
  appInfo: AppInfoModel;
  auditlogs: PaginatedAuditlogModelResponse;
  averageTeammatesPrUser: Scalars['Float'];
  canChangeProfileField: Scalars['Boolean'];
  canSeeComplimentsUntil: Maybe<Scalars['DateTime']>;
  candidatesByIds: Array<CandidateModel>;
  chat: ChatModel;
  chatGameById: ChatGameModel;
  chatGames: PaginatedChatGameModelCursorResponse;
  chatMessageSuggestions: Array<Scalars['String']>;
  chatRequests: PaginatedChatModelResponse;
  chatsV3: PaginatedChatModelResponse;
  consumableItem: ConsumableItemModel;
  consumableItems: Array<ConsumableItemModel>;
  currentBoost: Maybe<BoostModel>;
  currentProfile: ProfileModel;
  doubbleMatchDistribution: Array<DoubbleMatchDistributionModel>;
  doubbleMatches: Array<DoubbleMatchModel>;
  doubbleMatchesCount: Scalars['Float'];
  emailInUse: Scalars['Boolean'];
  expiringDoubbleMatches: ExpiringDoubbleMatchesModel;
  friendSuggestions: PaginatedFriendSuggestionModelCursorResponse;
  genderDistribution: Array<GenderDistributionModel>;
  geocode: Array<AutoCompleteAddressModel>;
  getCloudflareAUD: Array<Scalars['String']>;
  getReportedChat: ReportedChatModel;
  getUnlockableDoubbleMatch: Maybe<DoubbleMatchModel>;
  guessGender: Maybe<Gender>;
  guessName: Maybe<Scalars['String']>;
  imagesForPhotoTest: Array<ImageModel>;
  instagramPhotos: PaginatedInstagramMediaModelCursorResponse;
  interests: Array<InterestModel>;
  invite: InviteModel;
  inviteCode: InviteCodeModel;
  inviteData: InvitesDataModel;
  inviteableContacts: Array<PhoneNumberInviteModel>;
  invitedContacts: Array<PhoneNumberInviteModel>;
  invitedProfileIds: Array<Scalars['String']>;
  invitedProfiles: Array<ProfileModel>;
  invitingProfiles: Array<ProfileModel>;
  lastSwipeDate: Maybe<Scalars['DateTime']>;
  likedMe: Array<LikedMeModel>;
  likedMeCount: Scalars['Int'];
  likesAndDislikesCount: LikesAndDislikesModel;
  matches: Array<MatchModel>;
  matchesNotPartOfDoubbleMatchCount: Scalars['Float'];
  matchesV2: PaginatedAbstractMatchModelCursorResponse;
  messages: PaginatedMessageModelResponse;
  myCompliments: Array<ProfileComplimentModel>;
  myTopCompliments: Array<ComplimentModel>;
  /** Warnings that the user have not yet seen */
  nonSeenWarnings: Array<ProfileWarningModel>;
  notificationOptions: Array<NotificationOptionModel>;
  onWaitlist: Scalars['Boolean'];
  oneSidedLikes: Scalars['Float'];
  paginatedImagesForPhotoTest: PaginatedImageModelResponse;
  paginatedPrompts: PaginatedPromptModelResponse;
  photoTest: PhotoTestModel;
  photoTests: PaginatedPhotoTestModelResponse;
  placeSuggestions: PaginatedPlaceSuggestionModelResponse;
  places: Array<PlaceDistanceModel>;
  placesWithoutDistance: PaginatedPlaceModelResponse;
  pollGame: PollGameModel;
  potentialDoubbleMatchesWith: Array<PotentialDoubbleMatchesWithModel>;
  profile: ProfileModel;
  profileCompliment: ProfileComplimentModel;
  profileContacts: UserContactsModel;
  profileLocations: Array<ProfileLocationModel>;
  profiles: PaginatedProfileModelResponse;
  profilesThroughContacts: Array<ProfileModel>;
  promptInAllLocales: Array<PromptModel>;
  promptSuggestions: Array<PromptModel>;
  promptsByType: PaginatedPromptModelCursorResponse;
  recentlyLiked: Array<ProfileModel>;
  recommendedCandidates: Array<CandidateModel>;
  reportedProfiles: PaginatedReportedProfileModelResponse;
  searchUser: Array<ProfileModel>;
  searchUsers: PaginatedProfileModelResponse;
  shuffledPrompt: PaginatedPromptModelCursorResponse;
  stockProfiles: Array<ProfileModel>;
  subscriptionStatus: Scalars['Boolean'];
  supportedRegions: Array<SupportedRegionModel>;
  swipeDeck: SwipeDeckModel;
  teammates: Array<ProfileModel>;
  teammatesDistribution: Array<TeammatesDistributionModel>;
  tokenEntitlements: Array<PremiumEntitlement>;
  topLockedMatches: Array<ProfileModel>;
  totalDoubbleMatchesCount: Scalars['Float'];
  totalMatchesCount: Scalars['Float'];
  totalProfileCount: Scalars['Float'];
  trueUsers: Array<TrueUserModel>;
  unreadChatCount: Scalars['Float'];
  /** @deprecated Use unreadChatCount instead */
  unreadChatCountV2: Scalars['Float'];
  unseenCompliments: Scalars['Float'];
  usernameInUse: Scalars['Boolean'];
  /** @deprecated Use searchUser instead */
  usernameSearch: Array<UsernameSearchModel>;
  usernameSuggestions: Array<Scalars['String']>;
  versionedRouteGuards: Array<VersionedRouteGuardModel>;
  waitingListArea: Maybe<Scalars['String']>;
  whoAdminI: AdminModel;
  whosTyping: Array<Scalars['String']>;
};


export type QueryAdminGeocodeArgs = {
  onlyCities?: Scalars['Int'];
  query: Scalars['String'];
  take?: Scalars['Int'];
};


export type QueryAdminGetUserArgs = {
  profileId: InputMaybe<Scalars['String']>;
  userId: InputMaybe<Scalars['String']>;
};


export type QueryAegisReportsArgs = {
  blocked: InputMaybe<Scalars['Boolean']>;
  ip: InputMaybe<Scalars['String']>;
  maxScore?: InputMaybe<Scalars['Int']>;
  minScore?: InputMaybe<Scalars['Int']>;
  page?: Scalars['Int'];
  phoneNumber: InputMaybe<Scalars['String']>;
  take?: Scalars['Int'];
  used: InputMaybe<Scalars['Boolean']>;
};


export type QueryAllReportsArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryAnswersArgs = {
  chatId: Scalars['String'];
  cursor?: InputMaybe<Scalars['DateTime']>;
  take?: Scalars['Int'];
};


export type QueryAuditlogsArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCanChangeProfileFieldArgs = {
  input: CanChangeProfileFieldArg;
};


export type QueryCandidatesByIdsArgs = {
  candidateIds: Array<Scalars['String']>;
  sharedById: InputMaybe<Scalars['String']>;
};


export type QueryChatArgs = {
  chatId: Scalars['String'];
};


export type QueryChatGameByIdArgs = {
  chatId: Scalars['String'];
  gameId: Scalars['String'];
};


export type QueryChatGamesArgs = {
  chatId: Scalars['String'];
  cursor?: InputMaybe<Scalars['DateTime']>;
  take?: Scalars['Int'];
  unanswered: InputMaybe<Scalars['Boolean']>;
};


export type QueryChatMessageSuggestionsArgs = {
  chatId: Scalars['String'];
};


export type QueryChatRequestsArgs = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  ignored: Scalars['Boolean'];
  take?: Scalars['Int'];
};


export type QueryChatsV3Args = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  expiringSoon?: Scalars['Boolean'];
  filter: InputMaybe<Filter>;
  matchId: InputMaybe<Scalars['String']>;
  query?: Scalars['String'];
  take?: Scalars['Int'];
  teamMateId: InputMaybe<Scalars['String']>;
  withoutMessages?: Scalars['Boolean'];
};


export type QueryConsumableItemArgs = {
  id: Scalars['String'];
};


export type QueryConsumableItemsArgs = {
  type: InputMaybe<ConsumableItemType>;
};


export type QueryDoubbleMatchesArgs = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  filter: InputMaybe<Filter>;
  groupByMatch?: Scalars['Boolean'];
  matchId: InputMaybe<Scalars['String']>;
  take?: Scalars['Int'];
  teamMateId: InputMaybe<Scalars['String']>;
};


export type QueryEmailInUseArgs = {
  email: Scalars['String'];
};


export type QueryFriendSuggestionsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter: InputMaybe<FriendSuggestionsCategory>;
  search?: InputMaybe<Scalars['String']>;
  take?: Scalars['Int'];
};


export type QueryGeocodeArgs = {
  onlyCities?: Scalars['Int'];
  query: Scalars['String'];
  take?: Scalars['Int'];
};


export type QueryGetCloudflareAudArgs = {
  token: Scalars['String'];
};


export type QueryGetReportedChatArgs = {
  chatId: Scalars['String'];
};


export type QueryGetUnlockableDoubbleMatchArgs = {
  ignoreSuggestions?: Array<Scalars['String']>;
};


export type QueryGuessGenderArgs = {
  countryCode: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type QueryGuessNameArgs = {
  countryCode: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
};


export type QueryInstagramPhotosArgs = {
  cursor: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryInviteArgs = {
  inviteCode: Scalars['String'];
};


export type QueryInviteCodeArgs = {
  code: Scalars['String'];
};


export type QueryInviteableContactsArgs = {
  page?: Scalars['Float'];
  startCursor?: InputMaybe<Scalars['String']>;
};


export type QueryLikedMeArgs = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  filter: InputMaybe<Filter>;
  since: InputMaybe<Scalars['DateTime']>;
  take?: Scalars['Int'];
  type: InputMaybe<LikedMeType>;
};


export type QueryLikedMeCountArgs = {
  filter: InputMaybe<Filter>;
  since: InputMaybe<Scalars['DateTime']>;
  type: InputMaybe<LikedMeType>;
};


export type QueryMatchesArgs = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  filter: InputMaybe<Filter>;
  take?: Scalars['Int'];
};


export type QueryMatchesV2Args = {
  cursor?: InputMaybe<Scalars['String']>;
  filter: InputMaybe<MatchesFilterInput>;
  groupBy: InputMaybe<MatchesGroupByInput>;
  take?: Scalars['Int'];
};


export type QueryMessagesArgs = {
  chatId: Scalars['String'];
  cursor?: InputMaybe<Scalars['DateTime']>;
  take?: Scalars['Int'];
};


export type QueryMyComplimentsArgs = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  take?: Scalars['Int'];
};


export type QueryNotificationOptionsArgs = {
  token: Scalars['String'];
};


export type QueryOnWaitlistArgs = {
  city: InputMaybe<Scalars['String']>;
  countryCode: InputMaybe<Scalars['String']>;
  latitude: InputMaybe<Scalars['Float']>;
  longitude: InputMaybe<Scalars['Float']>;
  region: InputMaybe<Scalars['String']>;
};


export type QueryPaginatedImagesForPhotoTestArgs = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  take?: Scalars['Int'];
};


export type QueryPaginatedPromptsArgs = {
  chatType: InputMaybe<Scalars['String']>;
  gameType: InputMaybe<Scalars['String']>;
  search: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryPhotoTestArgs = {
  photoTestId: Scalars['String'];
};


export type QueryPhotoTestsArgs = {
  cursor?: InputMaybe<Scalars['DateTime']>;
  take?: Scalars['Int'];
};


export type QueryPlaceSuggestionsArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryPlacesArgs = {
  cursor?: Scalars['Int'];
  maxDistance?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryPlacesWithoutDistanceArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryPollGameArgs = {
  ttq?: Scalars['Boolean'];
};


export type QueryPotentialDoubbleMatchesWithArgs = {
  userId: Scalars['ID'];
};


export type QueryProfileArgs = {
  userId: InputMaybe<Scalars['String']>;
};


export type QueryProfileComplimentArgs = {
  complimentId: Scalars['String'];
  complimenterUserId: Scalars['String'];
};


export type QueryProfileLocationsArgs = {
  page: Scalars['Float'];
};


export type QueryProfilesArgs = {
  onlyWaitListed?: InputMaybe<Scalars['Boolean']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryPromptInAllLocalesArgs = {
  promptId: Scalars['String'];
};


export type QueryPromptSuggestionsArgs = {
  chatTypes?: InputMaybe<Array<ChatType>>;
  personalized?: Scalars['Boolean'];
};


export type QueryPromptsByTypeArgs = {
  chatTypes?: InputMaybe<Array<ChatType>>;
  cursor?: InputMaybe<Scalars['String']>;
  take?: Scalars['Int'];
  type: GameType;
};


export type QueryRecentlyLikedArgs = {
  take: InputMaybe<Scalars['Float']>;
};


export type QueryRecommendedCandidatesArgs = {
  fromInviteCode: InputMaybe<Scalars['String']>;
  specificCandidate: InputMaybe<Scalars['String']>;
};


export type QueryReportedProfilesArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QuerySearchUserArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QuerySearchUsersArgs = {
  query: Scalars['String'];
};


export type QueryShuffledPromptArgs = {
  chatTypes?: InputMaybe<Array<ChatType>>;
  cursor?: InputMaybe<Scalars['String']>;
  take?: Scalars['Int'];
  type: GameType;
};


export type QueryStockProfilesArgs = {
  take: Scalars['Float'];
};


export type QuerySwipeDeckArgs = {
  excludeIds?: InputMaybe<Array<Scalars['String']>>;
  filter: InputMaybe<Filter>;
  preferStale?: Scalars['Boolean'];
  swipeCount?: InputMaybe<Scalars['Int']>;
  take?: Scalars['Int'];
  ttq?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTeammatesArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryTrueUsersArgs = {
  usernames?: Scalars['String'];
};


export type QueryUsernameInUseArgs = {
  username: Scalars['String'];
};


export type QueryUsernameSearchArgs = {
  searchString: Scalars['String'];
};


export type QueryUsernameSuggestionsArgs = {
  email: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
};


export type QueryWhosTypingArgs = {
  chatId: Scalars['String'];
};

export type RefreshAuthTokensInput = {
  refreshToken: Scalars['String'];
};

export type ReplyToGameAnswerInput = {
  chatId: Scalars['String'];
  content: Scalars['String'];
  repliedMessageId: Scalars['String'];
};

export type ReplyToGameAnswerInputV2 = {
  chatId: Scalars['String'];
  content: Scalars['String'];
  gameId: Scalars['String'];
  playerId: Scalars['String'];
};

export type ReplyToGameInput = {
  chatId: Scalars['String'];
  content: Scalars['String'];
  gameId: Scalars['String'];
};

export type ReportInput = {
  message: InputMaybe<Scalars['String']>;
  offendingElementId: Scalars['String'];
  type: ReportType;
};

export type ReportModel = {
  __typename?: 'ReportModel';
  content: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  creatorOfReportedContentId: Scalars['String'];
  id: Scalars['ID'];
  message: Maybe<Scalars['String']>;
  reportedElementId: Scalars['String'];
  reporter: Maybe<ProfileModel>;
  reportingUserId: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ReportType {
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  DATING_PROFILE = 'DATING_PROFILE',
  PROFILE = 'PROFILE'
}

export type ReportedChatModel = {
  __typename?: 'ReportedChatModel';
  chat: ChatModel;
  messages: Array<MessageModel>;
  profiles: Array<ProfileModel>;
};

export type ReportedProfileModel = {
  __typename?: 'ReportedProfileModel';
  count: Scalars['Float'];
  lastReportedAt: Scalars['DateTime'];
  profile: ProfileModel;
  reports: Array<ReportModel>;
  reportsSinceWarning: Scalars['Float'];
  userId: Scalars['String'];
};

export type RevokeSuspensionInput = {
  suspensionId: Scalars['String'];
};

export type SaveDeviceInfoArgs = {
  data: Scalars['JSON'];
};

export type SavePromptInput = {
  chatTypes: Array<ChatType>;
  constraints: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  gameType: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  tagPrefillType: TagPrefillType;
};

export type SendMessageInput = {
  chatId: Scalars['String'];
  contextId: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  messageRepliedToId: InputMaybe<Scalars['String']>;
};

export type ShareProfileInput = {
  createUnlockAttemptMessage: InputMaybe<Scalars['Boolean']>;
  matchId: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  profileId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type StartChatGameInput = {
  chatId: Scalars['String'];
  customPrompt: InputMaybe<Scalars['String']>;
  promptId: Scalars['String'];
};

export type StartPhotoTestInput = {
  imageUrls: Array<Scalars['String']>;
};

export type SubmitAnswerInput = {
  chatId: Scalars['String'];
  confessed: InputMaybe<Scalars['Boolean']>;
  flag: InputMaybe<Scalars['Boolean']>;
  gameId: Scalars['String'];
  gameType: GameType;
  imageData: InputMaybe<Scalars['String']>;
  rating: InputMaybe<Scalars['Float']>;
  shuffled: InputMaybe<Scalars['Float']>;
  taggedUserId: InputMaybe<Scalars['String']>;
  text: InputMaybe<Scalars['String']>;
  userId: InputMaybe<Scalars['String']>;
};

export type SubmitSingleAnswersInput = {
  customPrompt: InputMaybe<Scalars['String']>;
  gameType: GameType;
  promptId: Scalars['String'];
  user1Id: InputMaybe<Scalars['String']>;
  user2Id: InputMaybe<Scalars['String']>;
  userIds: InputMaybe<Array<Scalars['String']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeToAppEvents: Maybe<AppEventModel>;
  subscribeToChats: Maybe<ChatStreamEventModel>;
};


export type SubscriptionSubscribeToChatsArgs = {
  chatIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SupportedAreaModel = {
  __typename?: 'SupportedAreaModel';
  count: AreaPopulationModel;
  launched: Scalars['Boolean'];
  name: Scalars['String'];
  polygon: PolygonModel;
  target: Maybe<Scalars['Float']>;
};

export type SupportedRegionModel = {
  __typename?: 'SupportedRegionModel';
  areas: Array<SupportedAreaModel>;
  code: Scalars['String'];
  launched: Scalars['Boolean'];
  polygon: PolygonModel;
  target: Maybe<Scalars['Float']>;
};

export type SuspensionInput = {
  permanent: Scalars['Boolean'];
  reason: InputMaybe<Scalars['String']>;
  until: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SuspensionModel = {
  __typename?: 'SuspensionModel';
  createdAt: Scalars['DateTime'];
  duration: Scalars['String'];
  id: Scalars['ID'];
  permanent: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  reason: Maybe<Scalars['String']>;
  until: Maybe<Scalars['DateTime']>;
  userId: Maybe<Scalars['String']>;
};

export type SwipeDeckModel = {
  __typename?: 'SwipeDeckModel';
  appliedFilter: Maybe<Filter>;
  candidates: Array<CandidateModel>;
  hasMore: Scalars['Boolean'];
  isStale: Scalars['Boolean'];
  location: Maybe<PointModel>;
};

export type SwipeModel = {
  __typename?: 'SwipeModel';
  /** @deprecated deprecated */
  activeAfter: Maybe<Scalars['String']>;
  doubbleMatches: Array<DoubbleMatchModel>;
  id: Maybe<Scalars['String']>;
  like: Scalars['Boolean'];
  /** @deprecated deprecated */
  likedId: Maybe<Scalars['String']>;
  /** @deprecated deprecated */
  liker: Maybe<Scalars['String']>;
  /** @deprecated deprecated */
  likerId: Maybe<Scalars['String']>;
  match: Maybe<ProfileModel>;
  swipedUserId: Scalars['String'];
  swiperUserId: Scalars['String'];
  /** @deprecated deprecated */
  verdict: Maybe<Scalars['String']>;
};

export type SwipedProfileAttachmentModel = {
  __typename?: 'SwipedProfileAttachmentModel';
  liked: Maybe<Scalars['Boolean']>;
  messageId: Scalars['String'];
};

export enum TagPrefillType {
  FRIEND = 'FRIEND',
  MATCH = 'MATCH',
  NONE = 'NONE'
}

export type TeammatesDistributionModel = {
  __typename?: 'TeammatesDistributionModel';
  count: Scalars['Float'];
  teammates: Scalars['Float'];
};

export type TestNotificationModel = {
  __typename?: 'TestNotificationModel';
  notification: Scalars['JSON'];
  sentNotificationsCount: Scalars['Float'];
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type TrueUserModel = {
  __typename?: 'TrueUserModel';
  averageMessageSentPerChat: Scalars['Float'];
  chatInteractions: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  doubbleMatchesCount: Scalars['Float'];
  experiencesSuggested: Scalars['Float'];
  friendsCount: Scalars['Float'];
  hasBio: Scalars['Boolean'];
  hasCity: Scalars['Boolean'];
  hasOccupation: Scalars['Boolean'];
  hasSchool: Scalars['Boolean'];
  id: Scalars['ID'];
  interestsCount: Scalars['Float'];
  lastActive: Maybe<Scalars['DateTime']>;
  matchesCount: Scalars['Float'];
  matchingActive: Scalars['Boolean'];
  profileImagesCount: Scalars['Float'];
  sentMessages: Scalars['Float'];
  swipesCount: Scalars['Float'];
  trueUser: Scalars['Boolean'];
  username: Scalars['String'];
};

export type UserContactInformationModel = {
  __typename?: 'UserContactInformationModel';
  hasPhoto: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  phoneNumberScore: Scalars['Float'];
  referenceId: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type UserContactsModel = {
  __typename?: 'UserContactsModel';
  contacts: Array<UserContactInformationModel>;
  profileId: Scalars['ID'];
};

export type UserFeedbackInput = {
  description: InputMaybe<Scalars['String']>;
  reasons: InputMaybe<Array<Scalars['String']>>;
  type: InputMaybe<FeedbackType>;
};

export type UserLogModel = {
  __typename?: 'UserLogModel';
  action: Scalars['String'];
  createdAt: Scalars['DateTime'];
  data: Scalars['String'];
  id: Scalars['ID'];
  userId: Scalars['String'];
};

export type UserModel = {
  __typename?: 'UserModel';
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  username: Maybe<Scalars['String']>;
};

export type UsernameSearchModel = {
  __typename?: 'UsernameSearchModel';
  id: Scalars['String'];
  name: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type VersionedRouteGuardModel = {
  __typename?: 'VersionedRouteGuardModel';
  minimumVersion: Scalars['Float'];
  routeName: Scalars['String'];
};

export type WhitelistPhoneNumberInput = {
  description: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export enum AppFeature {
  BOOST = 'BOOST',
  CHAT_LINKS = 'CHAT_LINKS',
  COMPLIMENT_REACTIONS = 'COMPLIMENT_REACTIONS',
  INSTAGRAM_IMAGE_PICKER = 'INSTAGRAM_IMAGE_PICKER',
  MY_TYPE_FILTER = 'MY_TYPE_FILTER',
  PAYWALL = 'PAYWALL',
  PHOTO_TEST = 'PHOTO_TEST',
  READ_RECEIPTS = 'READ_RECEIPTS',
  SEGMENTED_CHAT_CONTROLS = 'SEGMENTED_CHAT_CONTROLS',
  SWR = 'SWR'
}

export enum CandidateStatsType {
  PROFILE_CARD = 'PROFILE_CARD',
  SWIPE_DECK = 'SWIPE_DECK',
  UNLOCK_DOUBBLE_MATCH = 'UNLOCK_DOUBBLE_MATCH'
}

export enum ChatUserStatus {
  ACCEPTED = 'ACCEPTED',
  IGNORED = 'IGNORED',
  PENDING = 'PENDING'
}

export enum ComplimentType {
  FUN_WITH_FRIENDS = 'FUN_WITH_FRIENDS',
  MORE_FRIENDS = 'MORE_FRIENDS'
}

export enum ConsumableItemTrigger {
  GIFT = 'GIFT',
  PURCHASE = 'PURCHASE',
  PURCHASE_BONUS = 'PURCHASE_BONUS',
  REWARD = 'REWARD',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export enum ConsumableItemType {
  BOOST = 'BOOST',
  GIFTABLE_JOINABLE_BOOST = 'GIFTABLE_JOINABLE_BOOST',
  JOINABLE_BOOST = 'JOINABLE_BOOST',
  MATCH_CHAT_UNLOCK = 'MATCH_CHAT_UNLOCK',
  PHOTO_TEST = 'PHOTO_TEST',
  PRIORITY_MESSAGE = 'PRIORITY_MESSAGE',
  SUPER_COMPLIMENT = 'SUPER_COMPLIMENT'
}

export enum Filter {
  ACTIVE_TODAY = 'ACTIVE_TODAY',
  FOR_YOU = 'FOR_YOU',
  LONER = 'LONER',
  MY_TYPE = 'MY_TYPE',
  NEARBY = 'NEARBY',
  NEWBIE = 'NEWBIE'
}

export enum FriendSuggestionsCategory {
  CONTACT = 'CONTACT',
  PROFILE = 'PROFILE'
}

export enum Gender {
  FEMALE = 'FEMALE',
  Female = 'Female',
  MALE = 'MALE',
  Male = 'Male',
  NON_BINARY = 'NON_BINARY',
  NonBinary = 'NonBinary'
}

export enum InboxPreference {
  DIRECT = 'DIRECT',
  REQUEST = 'REQUEST'
}

export enum InviteResponse {
  Accept = 'Accept',
  Reject = 'Reject'
}

export enum LikedMeType {
  ALL = 'ALL',
  DISLIKED = 'DISLIKED',
  NEW = 'NEW'
}

export enum Locales {
  DA = 'DA',
  EN = 'EN',
  NL = 'NL',
  NO = 'NO',
  SV = 'SV',
  da = 'da',
  en = 'en',
  nl = 'nl',
  no = 'no',
  sv = 'sv'
}

export enum MatchType {
  DOUBBLE_MATCH = 'DOUBBLE_MATCH',
  MATCH = 'MATCH'
}

export enum MessageAttachmentKind {
  AUDIO = 'AUDIO',
  COMPLIMENT = 'COMPLIMENT',
  CONTEXT = 'CONTEXT',
  FILE = 'FILE',
  GAME_ANSWER_REPLY = 'GAME_ANSWER_REPLY',
  GAME_REPLY = 'GAME_REPLY',
  GIF = 'GIF',
  IMAGE = 'IMAGE',
  PROFILE = 'PROFILE',
  PROFILE_SWIPE = 'PROFILE_SWIPE',
  REACTION = 'REACTION',
  REPLY = 'REPLY',
  VIDEO = 'VIDEO'
}

export enum PollUserRelation {
  FRIEND = 'FRIEND',
  FRIEND_SUGGESTION = 'FRIEND_SUGGESTION',
  INVITEABLE_CONTACT = 'INVITEABLE_CONTACT'
}

export enum PremiumEntitlement {
  FILTERS = 'FILTERS',
  INCOGNITO = 'INCOGNITO',
  LIKED_ME = 'LIKED_ME',
  MATCH_CHAT = 'MATCH_CHAT',
  PRIORITY_LIKES = 'PRIORITY_LIKES',
  READ_RECEIPTS = 'READ_RECEIPTS',
  UNDO = 'UNDO',
  UNLIMITED_LIKES = 'UNLIMITED_LIKES',
  WEEKLY_FREE_BOOST = 'WEEKLY_FREE_BOOST',
  WEEKLY_FREE_PHOTO_TEST = 'WEEKLY_FREE_PHOTO_TEST'
}

export enum Region {
  EUROPE_WEST = 'EUROPE_WEST',
  US_EAST = 'US_EAST',
  US_WEST = 'US_WEST'
}

export enum RestrictedProfileField {
  BIRTH_DATE = 'BIRTH_DATE',
  GENDER = 'GENDER',
  NAME = 'NAME'
}

export enum SpacetimeFilter {
  ACTIVE_TODAY = 'ACTIVE_TODAY',
  NEARBY = 'NEARBY'
}

export type AegisReportsListQueryVariables = Exact<{
  page: InputMaybe<Scalars['Int']>;
  take: InputMaybe<Scalars['Int']>;
  minSocre: InputMaybe<Scalars['Int']>;
  maxScore: InputMaybe<Scalars['Int']>;
  used: InputMaybe<Scalars['Boolean']>;
  blocked: InputMaybe<Scalars['Boolean']>;
  ip: InputMaybe<Scalars['String']>;
  phoneNumber: InputMaybe<Scalars['String']>;
}>;


export type AegisReportsListQuery = { __typename?: 'Query', aegisReports: Array<{ __typename?: 'AegisReportModel', id: string, score: number, phoneNumber: string, phoneNumberCountry: string | null, context: string, ipAddress: string, ipCountry: string | null, requestedAt: any, userAgent: string | null, deviceName: string | null, deviceId: string | null, usedAt: any | null, aegisVersion: string, blocked: boolean, headers: string, userId: string | null, stats: Array<Array<string>>, executionTime: number | null, observations: Array<{ __typename?: 'AegisObservationModel', category: string, score: number, observations: Array<Array<string>> }> }> };

export type WhitelistPhoneNumberMutationVariables = Exact<{
  input: WhitelistPhoneNumberInput;
}>;


export type WhitelistPhoneNumberMutation = { __typename?: 'Mutation', whitelistPhoneNumber: boolean };

export type AuditLogsQueryVariables = Exact<{
  skip: InputMaybe<Scalars['Int']>;
  take: InputMaybe<Scalars['Int']>;
}>;


export type AuditLogsQuery = { __typename?: 'Query', auditlogs: { __typename?: 'PaginatedAuditlogModelResponse', total: number | null, hasMore: boolean | null, items: Array<{ __typename?: 'AuditlogModel', id: string, operationType: string, callerName: string, resourceType: string, createdAt: any }> } };

export type CreatePlaceMutationVariables = Exact<{
  createPlaceInput: CreatePlaceInput;
}>;


export type CreatePlaceMutation = { __typename?: 'Mutation', createPlace: { __typename?: 'PlaceModel', id: string, name: string, category: string, phoneNumber: string | null, image: { __typename?: 'ImageModel', imageUrl: string | null, thumbnailUrl: string | null } | null, address: { __typename?: 'AutoCompleteAddressModel', streetName: string | null, streetNumber: string | null, city: string, postalCode: string | null, longitude: number, latitude: number, countryCodeISO3: string, neighborhood: string | null, formatted: string | null }, statistics: { __typename?: 'PlaceStatisticModel', recommendedCount: number | null } | null } };

export type DeletePlaceMutationVariables = Exact<{
  placeId: Scalars['String'];
}>;


export type DeletePlaceMutation = { __typename?: 'Mutation', deletePlace: { __typename?: 'PlaceModel', id: string } };

export type EditPlaceMutationVariables = Exact<{
  input: EditPlaceInput;
}>;


export type EditPlaceMutation = { __typename?: 'Mutation', editPlace: { __typename?: 'PlaceModel', id: string, name: string, category: string, phoneNumber: string | null, image: { __typename?: 'ImageModel', imageUrl: string | null, thumbnailUrl: string | null } | null, address: { __typename?: 'AutoCompleteAddressModel', streetName: string | null, streetNumber: string | null, city: string, postalCode: string | null, countryCodeISO3: string, latitude: number, longitude: number, neighborhood: string | null, formatted: string | null }, statistics: { __typename?: 'PlaceStatisticModel', recommendedCount: number | null } | null } };

export type GeocodeAddressQueryVariables = Exact<{
  query: Scalars['String'];
  take: InputMaybe<Scalars['Int']>;
}>;


export type GeocodeAddressQuery = { __typename?: 'Query', adminGeocode: Array<{ __typename?: 'AutoCompleteAddressModel', streetName: string | null, streetNumber: string | null, city: string, postalCode: string | null, countryCodeISO3: string, latitude: number, longitude: number, neighborhood: string | null, formatted: string | null }> };

export type GetPlacesQueryVariables = Exact<{
  skip: InputMaybe<Scalars['Int']>;
  take: InputMaybe<Scalars['Int']>;
}>;


export type GetPlacesQuery = { __typename?: 'Query', placesWithoutDistance: { __typename?: 'PaginatedPlaceModelResponse', items: Array<{ __typename?: 'PlaceModel', id: string, name: string, category: string, phoneNumber: string | null, image: { __typename?: 'ImageModel', imageUrl: string | null, thumbnailUrl: string | null } | null, address: { __typename?: 'AutoCompleteAddressModel', streetName: string | null, streetNumber: string | null, city: string, postalCode: string | null, countryCodeISO3: string, latitude: number, longitude: number, neighborhood: string | null, formatted: string | null }, statistics: { __typename?: 'PlaceStatisticModel', recommendedCount: number | null } | null }> } };

export type DeletePlaceSuggestionsMutationVariables = Exact<{
  suggestionIds: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DeletePlaceSuggestionsMutation = { __typename?: 'Mutation', deletePlaceSuggestions: boolean };

export type GetPlaceSuggestionsQueryVariables = Exact<{
  skip: InputMaybe<Scalars['Int']>;
  take: InputMaybe<Scalars['Int']>;
}>;


export type GetPlaceSuggestionsQuery = { __typename?: 'Query', placeSuggestions: { __typename?: 'PaginatedPlaceSuggestionModelResponse', total: number | null, hasMore: boolean | null, items: Array<{ __typename?: 'PlaceSuggestionModel', id: string, ids: Array<string>, name: string, createdAt: any, latestSuggestedAt: any, count: number, profiles: Array<{ __typename?: 'ProfileModel', id: string | null, name: string | null, username: string | null, phoneExtension: number }> }> } };

export type AllRedisConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllRedisConfigsQuery = { __typename?: 'Query', allRedisConfigs: Array<string> };

export type SaveRedisConfigMutationVariables = Exact<{
  key: Scalars['String'];
  value: Scalars['String'];
}>;


export type SaveRedisConfigMutation = { __typename?: 'Mutation', saveRedisConfig: boolean };

export type AskForElaborationOnReportMutationVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type AskForElaborationOnReportMutation = { __typename?: 'Mutation', askForElaboration: boolean };

export type AdminClearReportsOnProfileMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AdminClearReportsOnProfileMutation = { __typename?: 'Mutation', clearReportsOnUser: boolean };

export type CreateWarningOnProfileMutationVariables = Exact<{
  userId: Scalars['String'];
  reason: Scalars['String'];
}>;


export type CreateWarningOnProfileMutation = { __typename?: 'Mutation', createWarning: boolean };

export type GetReportedChatQueryVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type GetReportedChatQuery = { __typename?: 'Query', getReportedChat: { __typename?: 'ReportedChatModel', chat: { __typename?: 'ChatModel', type: string | null, typeId: string, createdAt: any }, messages: Array<{ __typename?: 'MessageModel', id: string, content: string | null, createdAt: any | null, senderId: string | null, type: string | null, attachments: Array<{ __typename?: 'MessageAttachmentModel', data: string, kind: MessageAttachmentKind }> }>, profiles: Array<{ __typename?: 'ProfileModel', id: string | null, name: string | null, avatar: { __typename?: 'ImageModel', thumbnailUrl: string | null } | null }> } };

export type ReportedProfilesQueryVariables = Exact<{
  skip: InputMaybe<Scalars['Int']>;
  take: InputMaybe<Scalars['Int']>;
}>;


export type ReportedProfilesQuery = { __typename?: 'Query', reportedProfiles: { __typename?: 'PaginatedReportedProfileModelResponse', total: number | null, hasMore: boolean | null, items: Array<{ __typename?: 'ReportedProfileModel', count: number, reportsSinceWarning: number, lastReportedAt: any, profile: { __typename?: 'ProfileModel', id: string | null, name: string | null, username: string | null, createdAt: any | null, lastActive: any | null, matchingActive: boolean | null, country: string | null, area: string | null }, reports: Array<{ __typename?: 'ReportModel', id: string, type: string, message: string | null, content: any, reportingUserId: string, reporter: { __typename?: 'ProfileModel', name: string | null, username: string | null } | null }> }> } };

export type SetSuspensionMutationVariables = Exact<{
  suspensionInput: SuspensionInput;
}>;


export type SetSuspensionMutation = { __typename?: 'Mutation', setSuspension: { __typename?: 'SuspensionModel', id: string, phoneNumber: string, permanent: boolean, until: any | null } };

export type RequestTrueUsersQueryVariables = Exact<{
  usernames: Scalars['String'];
}>;


export type RequestTrueUsersQuery = { __typename?: 'Query', trueUsers: Array<{ __typename?: 'TrueUserModel', id: string, username: string, trueUser: boolean, hasBio: boolean, hasCity: boolean, hasOccupation: boolean, hasSchool: boolean, averageMessageSentPerChat: number, chatInteractions: number, sentMessages: number, experiencesSuggested: number, createdAt: any, lastActive: any | null, swipesCount: number, doubbleMatchesCount: number, interestsCount: number, matchesCount: number, profileImagesCount: number, friendsCount: number, matchingActive: boolean }> };

export type GetUserCoverageQueryVariables = Exact<{
  page: Scalars['Float'];
}>;


export type GetUserCoverageQuery = { __typename?: 'Query', profileLocations: Array<{ __typename?: 'ProfileLocationModel', c: Array<number>, g: string | null, h: number }>, supportedRegions: Array<{ __typename?: 'SupportedRegionModel', code: string, launched: boolean, target: number | null, polygon: { __typename?: 'PolygonModel', coordinates: Array<Array<Array<number>>> }, areas: Array<{ __typename?: 'SupportedAreaModel', name: string, launched: boolean, target: number | null, count: { __typename?: 'AreaPopulationModel', male: number, female: number, nonBinary: number, waitlist: number }, polygon: { __typename?: 'PolygonModel', coordinates: Array<Array<Array<number>>> } }> }> };

export type ProfilesQueryVariables = Exact<{
  skip: InputMaybe<Scalars['Int']>;
  take: InputMaybe<Scalars['Int']>;
  onlyWaitListed: InputMaybe<Scalars['Boolean']>;
}>;


export type ProfilesQuery = { __typename?: 'Query', profiles: { __typename?: 'PaginatedProfileModelResponse', total: number | null, hasMore: boolean | null, items: Array<{ __typename?: 'ProfileModel', id: string | null, userId: string | null, name: string | null, username: string | null, gender: string | null, birthDate: any | null, email: string | null, phoneNumber: string, bio: string | null, occupation: string | null, school: string | null, city: string | null, matchingActive: boolean | null, createdAt: any | null, lastActive: any | null, area: string | null, appVersion: string, devicePlatform: string, images: Array<{ __typename?: 'ImageModel', thumbnailUrl: string | null }>, suspension: { __typename?: 'SuspensionModel', id: string, phoneNumber: string, permanent: boolean, until: any | null } | null }> } };

export type AdminDeleteProfileImageMutationVariables = Exact<{
  userId: Scalars['String'];
  imageUrl: Scalars['String'];
}>;


export type AdminDeleteProfileImageMutation = { __typename?: 'Mutation', adminRemoveProfileImage: boolean };

export type AdminDeleteAccountMutationVariables = Exact<{
  deleteInput: DeleteProfileInput;
}>;


export type AdminDeleteAccountMutation = { __typename?: 'Mutation', adminDeleteAccount: boolean };

export type EnablePaywallMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type EnablePaywallMutation = { __typename?: 'Mutation', enablePaywall: boolean };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', adminGetUser: { __typename?: 'ProfileModel', id: string | null, name: string | null, username: string | null, gender: string | null, birthDate: any | null, email: string | null, phoneNumber: string, bio: string | null, occupation: string | null, school: string | null, city: string | null, matchingActive: boolean | null, createdAt: any | null, lastActive: any | null, updatedAt: any | null, deletedAt: any | null, country: string | null, area: string | null, hasPaywall: boolean, onWaitlist: boolean, withinSupportedRegion: boolean, appVersion: string, operatingSystem: string, deviceType: string, ipAddress: string, images: Array<{ __typename?: 'ImageModel', imageUrl: string | null, thumbnailUrl: string | null, width: number | null, height: number | null }>, suspension: { __typename?: 'SuspensionModel', id: string, phoneNumber: string, permanent: boolean, until: any | null } | null, reports: Array<{ __typename?: 'ReportModel', id: string, message: string | null, content: any, createdAt: any, type: string, reporter: { __typename?: 'ProfileModel', id: string | null, name: string | null } | null }> | null, suspensions: Array<{ __typename?: 'SuspensionModel', id: string, phoneNumber: string, permanent: boolean, until: any | null, createdAt: any, userId: string | null, reason: string | null, duration: string }>, teamMates: Array<{ __typename?: 'ProfileModel', id: string | null, name: string | null, username: string | null, reports: Array<{ __typename?: 'ReportModel', id: string, message: string | null, content: any, createdAt: any, type: string, reporter: { __typename?: 'ProfileModel', id: string | null, name: string | null } | null }> | null }>, warnings: Array<{ __typename?: 'ProfileWarningModel', reason: string | null, createdAt: any, callerId: string | null, userSeen: boolean }> | null, logs: Array<{ __typename?: 'UserLogModel', action: string, data: string, createdAt: any }> } };

export type RemoveUpdateLimitMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type RemoveUpdateLimitMutation = { __typename?: 'Mutation', removeUpdateLimit: boolean };

export type SearchUsersQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: { __typename?: 'PaginatedProfileModelResponse', items: Array<{ __typename?: 'ProfileModel', id: string | null, name: string | null, country: string | null, phoneNumber: string, email: string | null, username: string | null, avatar: { __typename?: 'ImageModel', thumbnailUrl: string | null } | null }> } };

export type SetDeepLinkRemotelyMutationVariables = Exact<{
  userId: Scalars['String'];
  link: Scalars['String'];
}>;


export type SetDeepLinkRemotelyMutation = { __typename?: 'Mutation', setDeepLinkRemotely: number };

export type UpdateWaitListStatusMutationVariables = Exact<{
  userId: Scalars['String'];
  onWaitList: Scalars['Boolean'];
}>;


export type UpdateWaitListStatusMutation = { __typename?: 'Mutation', updateWaitListStatus: boolean };

export type PaginatedPromptsQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  chatType: InputMaybe<Scalars['String']>;
  gameType: InputMaybe<Scalars['String']>;
  search: InputMaybe<Scalars['String']>;
}>;


export type PaginatedPromptsQuery = { __typename?: 'Query', paginatedPrompts: { __typename?: 'PaginatedPromptModelResponse', hasMore: boolean | null, total: number | null, items: Array<{ __typename?: 'PromptModel', id: string, locale: Locales | null, content: string, gameType: GameType, prefillType: TagPrefillType, chatTypes: Array<ChatType> | null, constraints: { __typename?: 'PromptConstraintModel', limit: number | null, startTime: any | null, endTime: any | null, specificLibrary: string | null, searchTag: string | null } | null }> } };

export type PromptInAllLocalesQueryVariables = Exact<{
  promptId: Scalars['String'];
}>;


export type PromptInAllLocalesQuery = { __typename?: 'Query', promptInAllLocales: Array<{ __typename?: 'PromptModel', id: string, locale: Locales | null, content: string, gameType: GameType, prefillType: TagPrefillType, chatTypes: Array<ChatType> | null, constraints: { __typename?: 'PromptConstraintModel', limit: number | null, startTime: any | null, endTime: any | null, specificLibrary: string | null, searchTag: string | null } | null }> };

export type SavePromptMutationVariables = Exact<{
  input: SavePromptInput;
}>;


export type SavePromptMutation = { __typename?: 'Mutation', savePrompt: { __typename?: 'PromptModel', id: string, locale: Locales | null, content: string, gameType: GameType, prefillType: TagPrefillType, chatTypes: Array<ChatType> | null, constraints: { __typename?: 'PromptConstraintModel', limit: number | null, startTime: any | null, endTime: any | null, specificLibrary: string | null, searchTag: string | null } | null } };

export type GetApplicationAudQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetApplicationAudQuery = { __typename?: 'Query', getCloudflareAUD: Array<string> };


export const AegisReportsListDocument = gql`
    query AegisReportsList($page: Int, $take: Int, $minSocre: Int, $maxScore: Int, $used: Boolean, $blocked: Boolean, $ip: String, $phoneNumber: String) {
  aegisReports(
    page: $page
    take: $take
    minScore: $minSocre
    maxScore: $maxScore
    used: $used
    blocked: $blocked
    ip: $ip
    phoneNumber: $phoneNumber
  ) {
    id
    score
    phoneNumber
    phoneNumberCountry
    context
    ipAddress
    ipCountry
    requestedAt
    userAgent
    deviceName
    deviceId
    usedAt
    aegisVersion
    blocked
    headers
    userId
    observations {
      category
      score
      observations
    }
    stats
    executionTime
  }
}
    `;

/**
 * __useAegisReportsListQuery__
 *
 * To run a query within a React component, call `useAegisReportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAegisReportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAegisReportsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      minSocre: // value for 'minSocre'
 *      maxScore: // value for 'maxScore'
 *      used: // value for 'used'
 *      blocked: // value for 'blocked'
 *      ip: // value for 'ip'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useAegisReportsListQuery(baseOptions?: Apollo.QueryHookOptions<AegisReportsListQuery, AegisReportsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AegisReportsListQuery, AegisReportsListQueryVariables>(AegisReportsListDocument, options);
      }
export function useAegisReportsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AegisReportsListQuery, AegisReportsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AegisReportsListQuery, AegisReportsListQueryVariables>(AegisReportsListDocument, options);
        }
export type AegisReportsListQueryHookResult = ReturnType<typeof useAegisReportsListQuery>;
export type AegisReportsListLazyQueryHookResult = ReturnType<typeof useAegisReportsListLazyQuery>;
export type AegisReportsListQueryResult = Apollo.QueryResult<AegisReportsListQuery, AegisReportsListQueryVariables>;
export function refetchAegisReportsListQuery(variables?: AegisReportsListQueryVariables) {
      return { query: AegisReportsListDocument, variables: variables }
    }
export const WhitelistPhoneNumberDocument = gql`
    mutation WhitelistPhoneNumber($input: WhitelistPhoneNumberInput!) {
  whitelistPhoneNumber(input: $input)
}
    `;
export type WhitelistPhoneNumberMutationFn = Apollo.MutationFunction<WhitelistPhoneNumberMutation, WhitelistPhoneNumberMutationVariables>;

/**
 * __useWhitelistPhoneNumberMutation__
 *
 * To run a mutation, you first call `useWhitelistPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWhitelistPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [whitelistPhoneNumberMutation, { data, loading, error }] = useWhitelistPhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWhitelistPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<WhitelistPhoneNumberMutation, WhitelistPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WhitelistPhoneNumberMutation, WhitelistPhoneNumberMutationVariables>(WhitelistPhoneNumberDocument, options);
      }
export type WhitelistPhoneNumberMutationHookResult = ReturnType<typeof useWhitelistPhoneNumberMutation>;
export type WhitelistPhoneNumberMutationResult = Apollo.MutationResult<WhitelistPhoneNumberMutation>;
export type WhitelistPhoneNumberMutationOptions = Apollo.BaseMutationOptions<WhitelistPhoneNumberMutation, WhitelistPhoneNumberMutationVariables>;
export const AuditLogsDocument = gql`
    query AuditLogs($skip: Int, $take: Int) {
  auditlogs(skip: $skip, take: $take) {
    total
    hasMore
    items {
      id
      operationType
      callerName
      resourceType
      createdAt
    }
  }
}
    `;

/**
 * __useAuditLogsQuery__
 *
 * To run a query within a React component, call `useAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useAuditLogsQuery(baseOptions?: Apollo.QueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, options);
      }
export function useAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, options);
        }
export type AuditLogsQueryHookResult = ReturnType<typeof useAuditLogsQuery>;
export type AuditLogsLazyQueryHookResult = ReturnType<typeof useAuditLogsLazyQuery>;
export type AuditLogsQueryResult = Apollo.QueryResult<AuditLogsQuery, AuditLogsQueryVariables>;
export function refetchAuditLogsQuery(variables?: AuditLogsQueryVariables) {
      return { query: AuditLogsDocument, variables: variables }
    }
export const CreatePlaceDocument = gql`
    mutation CreatePlace($createPlaceInput: CreatePlaceInput!) {
  createPlace(createPlaceInput: $createPlaceInput) {
    id
    name
    category
    image {
      imageUrl
      thumbnailUrl
    }
    phoneNumber
    address {
      streetName
      streetNumber
      city
      postalCode
      longitude
      latitude
      countryCodeISO3
      neighborhood
      formatted
    }
    statistics {
      recommendedCount
    }
  }
}
    `;
export type CreatePlaceMutationFn = Apollo.MutationFunction<CreatePlaceMutation, CreatePlaceMutationVariables>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      createPlaceInput: // value for 'createPlaceInput'
 *   },
 * });
 */
export function useCreatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceMutation, CreatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(CreatePlaceDocument, options);
      }
export type CreatePlaceMutationHookResult = ReturnType<typeof useCreatePlaceMutation>;
export type CreatePlaceMutationResult = Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<CreatePlaceMutation, CreatePlaceMutationVariables>;
export const DeletePlaceDocument = gql`
    mutation DeletePlace($placeId: String!) {
  deletePlace(placeId: $placeId) {
    id
  }
}
    `;
export type DeletePlaceMutationFn = Apollo.MutationFunction<DeletePlaceMutation, DeletePlaceMutationVariables>;

/**
 * __useDeletePlaceMutation__
 *
 * To run a mutation, you first call `useDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceMutation, { data, loading, error }] = useDeletePlaceMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceMutation, DeletePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceMutation, DeletePlaceMutationVariables>(DeletePlaceDocument, options);
      }
export type DeletePlaceMutationHookResult = ReturnType<typeof useDeletePlaceMutation>;
export type DeletePlaceMutationResult = Apollo.MutationResult<DeletePlaceMutation>;
export type DeletePlaceMutationOptions = Apollo.BaseMutationOptions<DeletePlaceMutation, DeletePlaceMutationVariables>;
export const EditPlaceDocument = gql`
    mutation EditPlace($input: EditPlaceInput!) {
  editPlace(input: $input) {
    id
    name
    category
    image {
      imageUrl
      thumbnailUrl
    }
    phoneNumber
    address {
      streetName
      streetNumber
      city
      postalCode
      countryCodeISO3
      latitude
      longitude
      neighborhood
      formatted
    }
    statistics {
      recommendedCount
    }
  }
}
    `;
export type EditPlaceMutationFn = Apollo.MutationFunction<EditPlaceMutation, EditPlaceMutationVariables>;

/**
 * __useEditPlaceMutation__
 *
 * To run a mutation, you first call `useEditPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlaceMutation, { data, loading, error }] = useEditPlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlaceMutation(baseOptions?: Apollo.MutationHookOptions<EditPlaceMutation, EditPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlaceMutation, EditPlaceMutationVariables>(EditPlaceDocument, options);
      }
export type EditPlaceMutationHookResult = ReturnType<typeof useEditPlaceMutation>;
export type EditPlaceMutationResult = Apollo.MutationResult<EditPlaceMutation>;
export type EditPlaceMutationOptions = Apollo.BaseMutationOptions<EditPlaceMutation, EditPlaceMutationVariables>;
export const GeocodeAddressDocument = gql`
    query GeocodeAddress($query: String!, $take: Int) {
  adminGeocode(query: $query, take: $take) {
    streetName
    streetNumber
    city
    postalCode
    countryCodeISO3
    latitude
    longitude
    neighborhood
    formatted
  }
}
    `;

/**
 * __useGeocodeAddressQuery__
 *
 * To run a query within a React component, call `useGeocodeAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeocodeAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeocodeAddressQuery({
 *   variables: {
 *      query: // value for 'query'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGeocodeAddressQuery(baseOptions: Apollo.QueryHookOptions<GeocodeAddressQuery, GeocodeAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeocodeAddressQuery, GeocodeAddressQueryVariables>(GeocodeAddressDocument, options);
      }
export function useGeocodeAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeocodeAddressQuery, GeocodeAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeocodeAddressQuery, GeocodeAddressQueryVariables>(GeocodeAddressDocument, options);
        }
export type GeocodeAddressQueryHookResult = ReturnType<typeof useGeocodeAddressQuery>;
export type GeocodeAddressLazyQueryHookResult = ReturnType<typeof useGeocodeAddressLazyQuery>;
export type GeocodeAddressQueryResult = Apollo.QueryResult<GeocodeAddressQuery, GeocodeAddressQueryVariables>;
export function refetchGeocodeAddressQuery(variables: GeocodeAddressQueryVariables) {
      return { query: GeocodeAddressDocument, variables: variables }
    }
export const GetPlacesDocument = gql`
    query GetPlaces($skip: Int, $take: Int) {
  placesWithoutDistance(skip: $skip, take: $take) {
    items {
      id
      name
      category
      image {
        imageUrl
        thumbnailUrl
      }
      phoneNumber
      address {
        streetName
        streetNumber
        city
        postalCode
        countryCodeISO3
        latitude
        longitude
        neighborhood
        formatted
      }
      statistics {
        recommendedCount
      }
    }
  }
}
    `;

/**
 * __useGetPlacesQuery__
 *
 * To run a query within a React component, call `useGetPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPlacesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
      }
export function useGetPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
        }
export type GetPlacesQueryHookResult = ReturnType<typeof useGetPlacesQuery>;
export type GetPlacesLazyQueryHookResult = ReturnType<typeof useGetPlacesLazyQuery>;
export type GetPlacesQueryResult = Apollo.QueryResult<GetPlacesQuery, GetPlacesQueryVariables>;
export function refetchGetPlacesQuery(variables?: GetPlacesQueryVariables) {
      return { query: GetPlacesDocument, variables: variables }
    }
export const DeletePlaceSuggestionsDocument = gql`
    mutation DeletePlaceSuggestions($suggestionIds: [String!]) {
  deletePlaceSuggestions(suggestionIds: $suggestionIds)
}
    `;
export type DeletePlaceSuggestionsMutationFn = Apollo.MutationFunction<DeletePlaceSuggestionsMutation, DeletePlaceSuggestionsMutationVariables>;

/**
 * __useDeletePlaceSuggestionsMutation__
 *
 * To run a mutation, you first call `useDeletePlaceSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceSuggestionsMutation, { data, loading, error }] = useDeletePlaceSuggestionsMutation({
 *   variables: {
 *      suggestionIds: // value for 'suggestionIds'
 *   },
 * });
 */
export function useDeletePlaceSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceSuggestionsMutation, DeletePlaceSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceSuggestionsMutation, DeletePlaceSuggestionsMutationVariables>(DeletePlaceSuggestionsDocument, options);
      }
export type DeletePlaceSuggestionsMutationHookResult = ReturnType<typeof useDeletePlaceSuggestionsMutation>;
export type DeletePlaceSuggestionsMutationResult = Apollo.MutationResult<DeletePlaceSuggestionsMutation>;
export type DeletePlaceSuggestionsMutationOptions = Apollo.BaseMutationOptions<DeletePlaceSuggestionsMutation, DeletePlaceSuggestionsMutationVariables>;
export const GetPlaceSuggestionsDocument = gql`
    query GetPlaceSuggestions($skip: Int, $take: Int) {
  placeSuggestions(skip: $skip, take: $take) {
    total
    hasMore
    items {
      id
      ids
      name
      createdAt
      latestSuggestedAt
      count
      profiles {
        id
        name
        username
        phoneExtension
      }
    }
  }
}
    `;

/**
 * __useGetPlaceSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetPlaceSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceSuggestionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPlaceSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlaceSuggestionsQuery, GetPlaceSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaceSuggestionsQuery, GetPlaceSuggestionsQueryVariables>(GetPlaceSuggestionsDocument, options);
      }
export function useGetPlaceSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceSuggestionsQuery, GetPlaceSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaceSuggestionsQuery, GetPlaceSuggestionsQueryVariables>(GetPlaceSuggestionsDocument, options);
        }
export type GetPlaceSuggestionsQueryHookResult = ReturnType<typeof useGetPlaceSuggestionsQuery>;
export type GetPlaceSuggestionsLazyQueryHookResult = ReturnType<typeof useGetPlaceSuggestionsLazyQuery>;
export type GetPlaceSuggestionsQueryResult = Apollo.QueryResult<GetPlaceSuggestionsQuery, GetPlaceSuggestionsQueryVariables>;
export function refetchGetPlaceSuggestionsQuery(variables?: GetPlaceSuggestionsQueryVariables) {
      return { query: GetPlaceSuggestionsDocument, variables: variables }
    }
export const AllRedisConfigsDocument = gql`
    query AllRedisConfigs {
  allRedisConfigs
}
    `;

/**
 * __useAllRedisConfigsQuery__
 *
 * To run a query within a React component, call `useAllRedisConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRedisConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRedisConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRedisConfigsQuery(baseOptions?: Apollo.QueryHookOptions<AllRedisConfigsQuery, AllRedisConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllRedisConfigsQuery, AllRedisConfigsQueryVariables>(AllRedisConfigsDocument, options);
      }
export function useAllRedisConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRedisConfigsQuery, AllRedisConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllRedisConfigsQuery, AllRedisConfigsQueryVariables>(AllRedisConfigsDocument, options);
        }
export type AllRedisConfigsQueryHookResult = ReturnType<typeof useAllRedisConfigsQuery>;
export type AllRedisConfigsLazyQueryHookResult = ReturnType<typeof useAllRedisConfigsLazyQuery>;
export type AllRedisConfigsQueryResult = Apollo.QueryResult<AllRedisConfigsQuery, AllRedisConfigsQueryVariables>;
export function refetchAllRedisConfigsQuery(variables?: AllRedisConfigsQueryVariables) {
      return { query: AllRedisConfigsDocument, variables: variables }
    }
export const SaveRedisConfigDocument = gql`
    mutation SaveRedisConfig($key: String!, $value: String!) {
  saveRedisConfig(key: $key, value: $value)
}
    `;
export type SaveRedisConfigMutationFn = Apollo.MutationFunction<SaveRedisConfigMutation, SaveRedisConfigMutationVariables>;

/**
 * __useSaveRedisConfigMutation__
 *
 * To run a mutation, you first call `useSaveRedisConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRedisConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRedisConfigMutation, { data, loading, error }] = useSaveRedisConfigMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveRedisConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveRedisConfigMutation, SaveRedisConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRedisConfigMutation, SaveRedisConfigMutationVariables>(SaveRedisConfigDocument, options);
      }
export type SaveRedisConfigMutationHookResult = ReturnType<typeof useSaveRedisConfigMutation>;
export type SaveRedisConfigMutationResult = Apollo.MutationResult<SaveRedisConfigMutation>;
export type SaveRedisConfigMutationOptions = Apollo.BaseMutationOptions<SaveRedisConfigMutation, SaveRedisConfigMutationVariables>;
export const AskForElaborationOnReportDocument = gql`
    mutation AskForElaborationOnReport($reportId: String!) {
  askForElaboration(reportId: $reportId)
}
    `;
export type AskForElaborationOnReportMutationFn = Apollo.MutationFunction<AskForElaborationOnReportMutation, AskForElaborationOnReportMutationVariables>;

/**
 * __useAskForElaborationOnReportMutation__
 *
 * To run a mutation, you first call `useAskForElaborationOnReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskForElaborationOnReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askForElaborationOnReportMutation, { data, loading, error }] = useAskForElaborationOnReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useAskForElaborationOnReportMutation(baseOptions?: Apollo.MutationHookOptions<AskForElaborationOnReportMutation, AskForElaborationOnReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AskForElaborationOnReportMutation, AskForElaborationOnReportMutationVariables>(AskForElaborationOnReportDocument, options);
      }
export type AskForElaborationOnReportMutationHookResult = ReturnType<typeof useAskForElaborationOnReportMutation>;
export type AskForElaborationOnReportMutationResult = Apollo.MutationResult<AskForElaborationOnReportMutation>;
export type AskForElaborationOnReportMutationOptions = Apollo.BaseMutationOptions<AskForElaborationOnReportMutation, AskForElaborationOnReportMutationVariables>;
export const AdminClearReportsOnProfileDocument = gql`
    mutation AdminClearReportsOnProfile($userId: String!) {
  clearReportsOnUser(userId: $userId)
}
    `;
export type AdminClearReportsOnProfileMutationFn = Apollo.MutationFunction<AdminClearReportsOnProfileMutation, AdminClearReportsOnProfileMutationVariables>;

/**
 * __useAdminClearReportsOnProfileMutation__
 *
 * To run a mutation, you first call `useAdminClearReportsOnProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminClearReportsOnProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminClearReportsOnProfileMutation, { data, loading, error }] = useAdminClearReportsOnProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminClearReportsOnProfileMutation(baseOptions?: Apollo.MutationHookOptions<AdminClearReportsOnProfileMutation, AdminClearReportsOnProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminClearReportsOnProfileMutation, AdminClearReportsOnProfileMutationVariables>(AdminClearReportsOnProfileDocument, options);
      }
export type AdminClearReportsOnProfileMutationHookResult = ReturnType<typeof useAdminClearReportsOnProfileMutation>;
export type AdminClearReportsOnProfileMutationResult = Apollo.MutationResult<AdminClearReportsOnProfileMutation>;
export type AdminClearReportsOnProfileMutationOptions = Apollo.BaseMutationOptions<AdminClearReportsOnProfileMutation, AdminClearReportsOnProfileMutationVariables>;
export const CreateWarningOnProfileDocument = gql`
    mutation CreateWarningOnProfile($userId: String!, $reason: String!) {
  createWarning(userId: $userId, reason: $reason)
}
    `;
export type CreateWarningOnProfileMutationFn = Apollo.MutationFunction<CreateWarningOnProfileMutation, CreateWarningOnProfileMutationVariables>;

/**
 * __useCreateWarningOnProfileMutation__
 *
 * To run a mutation, you first call `useCreateWarningOnProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarningOnProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarningOnProfileMutation, { data, loading, error }] = useCreateWarningOnProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateWarningOnProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateWarningOnProfileMutation, CreateWarningOnProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWarningOnProfileMutation, CreateWarningOnProfileMutationVariables>(CreateWarningOnProfileDocument, options);
      }
export type CreateWarningOnProfileMutationHookResult = ReturnType<typeof useCreateWarningOnProfileMutation>;
export type CreateWarningOnProfileMutationResult = Apollo.MutationResult<CreateWarningOnProfileMutation>;
export type CreateWarningOnProfileMutationOptions = Apollo.BaseMutationOptions<CreateWarningOnProfileMutation, CreateWarningOnProfileMutationVariables>;
export const GetReportedChatDocument = gql`
    query GetReportedChat($chatId: String!) {
  getReportedChat(chatId: $chatId) {
    chat {
      type
      typeId
      createdAt
    }
    messages {
      id
      content
      createdAt
      senderId
      type
      attachments {
        data
        kind
      }
    }
    profiles {
      id
      name
      avatar {
        thumbnailUrl
      }
    }
  }
}
    `;

/**
 * __useGetReportedChatQuery__
 *
 * To run a query within a React component, call `useGetReportedChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportedChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportedChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useGetReportedChatQuery(baseOptions: Apollo.QueryHookOptions<GetReportedChatQuery, GetReportedChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportedChatQuery, GetReportedChatQueryVariables>(GetReportedChatDocument, options);
      }
export function useGetReportedChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportedChatQuery, GetReportedChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportedChatQuery, GetReportedChatQueryVariables>(GetReportedChatDocument, options);
        }
export type GetReportedChatQueryHookResult = ReturnType<typeof useGetReportedChatQuery>;
export type GetReportedChatLazyQueryHookResult = ReturnType<typeof useGetReportedChatLazyQuery>;
export type GetReportedChatQueryResult = Apollo.QueryResult<GetReportedChatQuery, GetReportedChatQueryVariables>;
export function refetchGetReportedChatQuery(variables: GetReportedChatQueryVariables) {
      return { query: GetReportedChatDocument, variables: variables }
    }
export const ReportedProfilesDocument = gql`
    query ReportedProfiles($skip: Int, $take: Int) {
  reportedProfiles(skip: $skip, take: $take) {
    total
    hasMore
    items {
      count
      reportsSinceWarning
      lastReportedAt
      profile {
        id
        name
        username
        createdAt
        lastActive
        matchingActive
        country
        area
      }
      reports {
        id
        type
        message
        content
        reportingUserId
        reporter {
          name
          username
        }
      }
    }
  }
}
    `;

/**
 * __useReportedProfilesQuery__
 *
 * To run a query within a React component, call `useReportedProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportedProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportedProfilesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportedProfilesQuery(baseOptions?: Apollo.QueryHookOptions<ReportedProfilesQuery, ReportedProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportedProfilesQuery, ReportedProfilesQueryVariables>(ReportedProfilesDocument, options);
      }
export function useReportedProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportedProfilesQuery, ReportedProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportedProfilesQuery, ReportedProfilesQueryVariables>(ReportedProfilesDocument, options);
        }
export type ReportedProfilesQueryHookResult = ReturnType<typeof useReportedProfilesQuery>;
export type ReportedProfilesLazyQueryHookResult = ReturnType<typeof useReportedProfilesLazyQuery>;
export type ReportedProfilesQueryResult = Apollo.QueryResult<ReportedProfilesQuery, ReportedProfilesQueryVariables>;
export function refetchReportedProfilesQuery(variables?: ReportedProfilesQueryVariables) {
      return { query: ReportedProfilesDocument, variables: variables }
    }
export const SetSuspensionDocument = gql`
    mutation SetSuspension($suspensionInput: SuspensionInput!) {
  setSuspension(suspensionInput: $suspensionInput) {
    id
    phoneNumber
    permanent
    until
  }
}
    `;
export type SetSuspensionMutationFn = Apollo.MutationFunction<SetSuspensionMutation, SetSuspensionMutationVariables>;

/**
 * __useSetSuspensionMutation__
 *
 * To run a mutation, you first call `useSetSuspensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSuspensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSuspensionMutation, { data, loading, error }] = useSetSuspensionMutation({
 *   variables: {
 *      suspensionInput: // value for 'suspensionInput'
 *   },
 * });
 */
export function useSetSuspensionMutation(baseOptions?: Apollo.MutationHookOptions<SetSuspensionMutation, SetSuspensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSuspensionMutation, SetSuspensionMutationVariables>(SetSuspensionDocument, options);
      }
export type SetSuspensionMutationHookResult = ReturnType<typeof useSetSuspensionMutation>;
export type SetSuspensionMutationResult = Apollo.MutationResult<SetSuspensionMutation>;
export type SetSuspensionMutationOptions = Apollo.BaseMutationOptions<SetSuspensionMutation, SetSuspensionMutationVariables>;
export const RequestTrueUsersDocument = gql`
    query RequestTrueUsers($usernames: String!) {
  trueUsers(usernames: $usernames) {
    id
    username
    trueUser
    hasBio
    hasCity
    hasOccupation
    hasSchool
    averageMessageSentPerChat
    chatInteractions
    sentMessages
    experiencesSuggested
    createdAt
    lastActive
    swipesCount
    doubbleMatchesCount
    interestsCount
    matchesCount
    profileImagesCount
    friendsCount
    matchingActive
  }
}
    `;

/**
 * __useRequestTrueUsersQuery__
 *
 * To run a query within a React component, call `useRequestTrueUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestTrueUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestTrueUsersQuery({
 *   variables: {
 *      usernames: // value for 'usernames'
 *   },
 * });
 */
export function useRequestTrueUsersQuery(baseOptions: Apollo.QueryHookOptions<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>(RequestTrueUsersDocument, options);
      }
export function useRequestTrueUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>(RequestTrueUsersDocument, options);
        }
export type RequestTrueUsersQueryHookResult = ReturnType<typeof useRequestTrueUsersQuery>;
export type RequestTrueUsersLazyQueryHookResult = ReturnType<typeof useRequestTrueUsersLazyQuery>;
export type RequestTrueUsersQueryResult = Apollo.QueryResult<RequestTrueUsersQuery, RequestTrueUsersQueryVariables>;
export function refetchRequestTrueUsersQuery(variables: RequestTrueUsersQueryVariables) {
      return { query: RequestTrueUsersDocument, variables: variables }
    }
export const GetUserCoverageDocument = gql`
    query GetUserCoverage($page: Float!) {
  profileLocations(page: $page) {
    c
    g
    h
  }
  supportedRegions {
    code
    launched
    polygon {
      coordinates
    }
    target
    areas {
      name
      count {
        male
        female
        nonBinary
        waitlist
      }
      polygon {
        coordinates
      }
      launched
      target
    }
  }
}
    `;

/**
 * __useGetUserCoverageQuery__
 *
 * To run a query within a React component, call `useGetUserCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCoverageQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetUserCoverageQuery(baseOptions: Apollo.QueryHookOptions<GetUserCoverageQuery, GetUserCoverageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCoverageQuery, GetUserCoverageQueryVariables>(GetUserCoverageDocument, options);
      }
export function useGetUserCoverageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCoverageQuery, GetUserCoverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCoverageQuery, GetUserCoverageQueryVariables>(GetUserCoverageDocument, options);
        }
export type GetUserCoverageQueryHookResult = ReturnType<typeof useGetUserCoverageQuery>;
export type GetUserCoverageLazyQueryHookResult = ReturnType<typeof useGetUserCoverageLazyQuery>;
export type GetUserCoverageQueryResult = Apollo.QueryResult<GetUserCoverageQuery, GetUserCoverageQueryVariables>;
export function refetchGetUserCoverageQuery(variables: GetUserCoverageQueryVariables) {
      return { query: GetUserCoverageDocument, variables: variables }
    }
export const ProfilesDocument = gql`
    query Profiles($skip: Int, $take: Int, $onlyWaitListed: Boolean) {
  profiles(skip: $skip, take: $take, onlyWaitListed: $onlyWaitListed) {
    total
    hasMore
    items {
      id
      userId
      name
      username
      gender
      birthDate
      email
      phoneNumber
      bio
      occupation
      school
      city
      matchingActive
      createdAt
      lastActive
      images {
        thumbnailUrl
      }
      area
      appVersion
      devicePlatform
      suspension {
        id
        phoneNumber
        permanent
        until
      }
    }
  }
}
    `;

/**
 * __useProfilesQuery__
 *
 * To run a query within a React component, call `useProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      onlyWaitListed: // value for 'onlyWaitListed'
 *   },
 * });
 */
export function useProfilesQuery(baseOptions?: Apollo.QueryHookOptions<ProfilesQuery, ProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
      }
export function useProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesQuery, ProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
        }
export type ProfilesQueryHookResult = ReturnType<typeof useProfilesQuery>;
export type ProfilesLazyQueryHookResult = ReturnType<typeof useProfilesLazyQuery>;
export type ProfilesQueryResult = Apollo.QueryResult<ProfilesQuery, ProfilesQueryVariables>;
export function refetchProfilesQuery(variables?: ProfilesQueryVariables) {
      return { query: ProfilesDocument, variables: variables }
    }
export const AdminDeleteProfileImageDocument = gql`
    mutation AdminDeleteProfileImage($userId: String!, $imageUrl: String!) {
  adminRemoveProfileImage(userId: $userId, imageUrl: $imageUrl)
}
    `;
export type AdminDeleteProfileImageMutationFn = Apollo.MutationFunction<AdminDeleteProfileImageMutation, AdminDeleteProfileImageMutationVariables>;

/**
 * __useAdminDeleteProfileImageMutation__
 *
 * To run a mutation, you first call `useAdminDeleteProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteProfileImageMutation, { data, loading, error }] = useAdminDeleteProfileImageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useAdminDeleteProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteProfileImageMutation, AdminDeleteProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteProfileImageMutation, AdminDeleteProfileImageMutationVariables>(AdminDeleteProfileImageDocument, options);
      }
export type AdminDeleteProfileImageMutationHookResult = ReturnType<typeof useAdminDeleteProfileImageMutation>;
export type AdminDeleteProfileImageMutationResult = Apollo.MutationResult<AdminDeleteProfileImageMutation>;
export type AdminDeleteProfileImageMutationOptions = Apollo.BaseMutationOptions<AdminDeleteProfileImageMutation, AdminDeleteProfileImageMutationVariables>;
export const AdminDeleteAccountDocument = gql`
    mutation AdminDeleteAccount($deleteInput: DeleteProfileInput!) {
  adminDeleteAccount(deleteInput: $deleteInput)
}
    `;
export type AdminDeleteAccountMutationFn = Apollo.MutationFunction<AdminDeleteAccountMutation, AdminDeleteAccountMutationVariables>;

/**
 * __useAdminDeleteAccountMutation__
 *
 * To run a mutation, you first call `useAdminDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteAccountMutation, { data, loading, error }] = useAdminDeleteAccountMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useAdminDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteAccountMutation, AdminDeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteAccountMutation, AdminDeleteAccountMutationVariables>(AdminDeleteAccountDocument, options);
      }
export type AdminDeleteAccountMutationHookResult = ReturnType<typeof useAdminDeleteAccountMutation>;
export type AdminDeleteAccountMutationResult = Apollo.MutationResult<AdminDeleteAccountMutation>;
export type AdminDeleteAccountMutationOptions = Apollo.BaseMutationOptions<AdminDeleteAccountMutation, AdminDeleteAccountMutationVariables>;
export const EnablePaywallDocument = gql`
    mutation EnablePaywall($userId: String!) {
  enablePaywall(userId: $userId)
}
    `;
export type EnablePaywallMutationFn = Apollo.MutationFunction<EnablePaywallMutation, EnablePaywallMutationVariables>;

/**
 * __useEnablePaywallMutation__
 *
 * To run a mutation, you first call `useEnablePaywallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePaywallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePaywallMutation, { data, loading, error }] = useEnablePaywallMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEnablePaywallMutation(baseOptions?: Apollo.MutationHookOptions<EnablePaywallMutation, EnablePaywallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnablePaywallMutation, EnablePaywallMutationVariables>(EnablePaywallDocument, options);
      }
export type EnablePaywallMutationHookResult = ReturnType<typeof useEnablePaywallMutation>;
export type EnablePaywallMutationResult = Apollo.MutationResult<EnablePaywallMutation>;
export type EnablePaywallMutationOptions = Apollo.BaseMutationOptions<EnablePaywallMutation, EnablePaywallMutationVariables>;
export const GetUserDocument = gql`
    query GetUser($userId: String!) {
  adminGetUser(userId: $userId) {
    id
    name
    username
    gender
    birthDate
    email
    phoneNumber
    bio
    occupation
    school
    city
    matchingActive
    createdAt
    lastActive
    updatedAt
    deletedAt
    country
    area
    hasPaywall
    onWaitlist
    withinSupportedRegion
    images {
      imageUrl
      thumbnailUrl
      width
      height
    }
    suspension {
      id
      phoneNumber
      permanent
      until
    }
    reports {
      id
      message
      content
      createdAt
      type
      reporter {
        id
        name
      }
    }
    suspensions {
      id
      phoneNumber
      permanent
      until
      createdAt
      userId
      reason
      duration
    }
    teamMates {
      id
      name
      username
      reports {
        id
        message
        content
        createdAt
        type
        reporter {
          id
          name
        }
      }
    }
    warnings {
      reason
      createdAt
      callerId
      userSeen
    }
    logs {
      action
      data
      createdAt
    }
    appVersion
    operatingSystem
    deviceType
    ipAddress
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const RemoveUpdateLimitDocument = gql`
    mutation RemoveUpdateLimit($userId: String!) {
  removeUpdateLimit(userId: $userId)
}
    `;
export type RemoveUpdateLimitMutationFn = Apollo.MutationFunction<RemoveUpdateLimitMutation, RemoveUpdateLimitMutationVariables>;

/**
 * __useRemoveUpdateLimitMutation__
 *
 * To run a mutation, you first call `useRemoveUpdateLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUpdateLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUpdateLimitMutation, { data, loading, error }] = useRemoveUpdateLimitMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUpdateLimitMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUpdateLimitMutation, RemoveUpdateLimitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUpdateLimitMutation, RemoveUpdateLimitMutationVariables>(RemoveUpdateLimitDocument, options);
      }
export type RemoveUpdateLimitMutationHookResult = ReturnType<typeof useRemoveUpdateLimitMutation>;
export type RemoveUpdateLimitMutationResult = Apollo.MutationResult<RemoveUpdateLimitMutation>;
export type RemoveUpdateLimitMutationOptions = Apollo.BaseMutationOptions<RemoveUpdateLimitMutation, RemoveUpdateLimitMutationVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($query: String!) {
  searchUsers(query: $query) {
    items {
      id
      name
      country
      phoneNumber
      email
      username
      avatar {
        thumbnailUrl
      }
    }
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export function refetchSearchUsersQuery(variables: SearchUsersQueryVariables) {
      return { query: SearchUsersDocument, variables: variables }
    }
export const SetDeepLinkRemotelyDocument = gql`
    mutation SetDeepLinkRemotely($userId: String!, $link: String!) {
  setDeepLinkRemotely(userId: $userId, link: $link)
}
    `;
export type SetDeepLinkRemotelyMutationFn = Apollo.MutationFunction<SetDeepLinkRemotelyMutation, SetDeepLinkRemotelyMutationVariables>;

/**
 * __useSetDeepLinkRemotelyMutation__
 *
 * To run a mutation, you first call `useSetDeepLinkRemotelyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeepLinkRemotelyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeepLinkRemotelyMutation, { data, loading, error }] = useSetDeepLinkRemotelyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useSetDeepLinkRemotelyMutation(baseOptions?: Apollo.MutationHookOptions<SetDeepLinkRemotelyMutation, SetDeepLinkRemotelyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDeepLinkRemotelyMutation, SetDeepLinkRemotelyMutationVariables>(SetDeepLinkRemotelyDocument, options);
      }
export type SetDeepLinkRemotelyMutationHookResult = ReturnType<typeof useSetDeepLinkRemotelyMutation>;
export type SetDeepLinkRemotelyMutationResult = Apollo.MutationResult<SetDeepLinkRemotelyMutation>;
export type SetDeepLinkRemotelyMutationOptions = Apollo.BaseMutationOptions<SetDeepLinkRemotelyMutation, SetDeepLinkRemotelyMutationVariables>;
export const UpdateWaitListStatusDocument = gql`
    mutation UpdateWaitListStatus($userId: String!, $onWaitList: Boolean!) {
  updateWaitListStatus(userId: $userId, onWaitList: $onWaitList)
}
    `;
export type UpdateWaitListStatusMutationFn = Apollo.MutationFunction<UpdateWaitListStatusMutation, UpdateWaitListStatusMutationVariables>;

/**
 * __useUpdateWaitListStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWaitListStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWaitListStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWaitListStatusMutation, { data, loading, error }] = useUpdateWaitListStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      onWaitList: // value for 'onWaitList'
 *   },
 * });
 */
export function useUpdateWaitListStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWaitListStatusMutation, UpdateWaitListStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWaitListStatusMutation, UpdateWaitListStatusMutationVariables>(UpdateWaitListStatusDocument, options);
      }
export type UpdateWaitListStatusMutationHookResult = ReturnType<typeof useUpdateWaitListStatusMutation>;
export type UpdateWaitListStatusMutationResult = Apollo.MutationResult<UpdateWaitListStatusMutation>;
export type UpdateWaitListStatusMutationOptions = Apollo.BaseMutationOptions<UpdateWaitListStatusMutation, UpdateWaitListStatusMutationVariables>;
export const PaginatedPromptsDocument = gql`
    query PaginatedPrompts($skip: Int!, $take: Int!, $chatType: String, $gameType: String, $search: String) {
  paginatedPrompts(
    skip: $skip
    take: $take
    chatType: $chatType
    gameType: $gameType
    search: $search
  ) {
    hasMore
    total
    items {
      id
      locale
      content
      gameType
      constraints {
        limit
        startTime
        endTime
        specificLibrary
        searchTag
      }
      prefillType
      chatTypes
    }
  }
}
    `;

/**
 * __usePaginatedPromptsQuery__
 *
 * To run a query within a React component, call `usePaginatedPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPromptsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      chatType: // value for 'chatType'
 *      gameType: // value for 'gameType'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaginatedPromptsQuery(baseOptions: Apollo.QueryHookOptions<PaginatedPromptsQuery, PaginatedPromptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedPromptsQuery, PaginatedPromptsQueryVariables>(PaginatedPromptsDocument, options);
      }
export function usePaginatedPromptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedPromptsQuery, PaginatedPromptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedPromptsQuery, PaginatedPromptsQueryVariables>(PaginatedPromptsDocument, options);
        }
export type PaginatedPromptsQueryHookResult = ReturnType<typeof usePaginatedPromptsQuery>;
export type PaginatedPromptsLazyQueryHookResult = ReturnType<typeof usePaginatedPromptsLazyQuery>;
export type PaginatedPromptsQueryResult = Apollo.QueryResult<PaginatedPromptsQuery, PaginatedPromptsQueryVariables>;
export function refetchPaginatedPromptsQuery(variables: PaginatedPromptsQueryVariables) {
      return { query: PaginatedPromptsDocument, variables: variables }
    }
export const PromptInAllLocalesDocument = gql`
    query PromptInAllLocales($promptId: String!) {
  promptInAllLocales(promptId: $promptId) {
    id
    locale
    content
    gameType
    constraints {
      limit
      startTime
      endTime
      specificLibrary
      searchTag
    }
    prefillType
    chatTypes
  }
}
    `;

/**
 * __usePromptInAllLocalesQuery__
 *
 * To run a query within a React component, call `usePromptInAllLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptInAllLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptInAllLocalesQuery({
 *   variables: {
 *      promptId: // value for 'promptId'
 *   },
 * });
 */
export function usePromptInAllLocalesQuery(baseOptions: Apollo.QueryHookOptions<PromptInAllLocalesQuery, PromptInAllLocalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromptInAllLocalesQuery, PromptInAllLocalesQueryVariables>(PromptInAllLocalesDocument, options);
      }
export function usePromptInAllLocalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromptInAllLocalesQuery, PromptInAllLocalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromptInAllLocalesQuery, PromptInAllLocalesQueryVariables>(PromptInAllLocalesDocument, options);
        }
export type PromptInAllLocalesQueryHookResult = ReturnType<typeof usePromptInAllLocalesQuery>;
export type PromptInAllLocalesLazyQueryHookResult = ReturnType<typeof usePromptInAllLocalesLazyQuery>;
export type PromptInAllLocalesQueryResult = Apollo.QueryResult<PromptInAllLocalesQuery, PromptInAllLocalesQueryVariables>;
export function refetchPromptInAllLocalesQuery(variables: PromptInAllLocalesQueryVariables) {
      return { query: PromptInAllLocalesDocument, variables: variables }
    }
export const SavePromptDocument = gql`
    mutation savePrompt($input: SavePromptInput!) {
  savePrompt(input: $input) {
    id
    locale
    content
    gameType
    constraints {
      limit
      startTime
      endTime
      specificLibrary
      searchTag
    }
    prefillType
    chatTypes
  }
}
    `;
export type SavePromptMutationFn = Apollo.MutationFunction<SavePromptMutation, SavePromptMutationVariables>;

/**
 * __useSavePromptMutation__
 *
 * To run a mutation, you first call `useSavePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePromptMutation, { data, loading, error }] = useSavePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePromptMutation(baseOptions?: Apollo.MutationHookOptions<SavePromptMutation, SavePromptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePromptMutation, SavePromptMutationVariables>(SavePromptDocument, options);
      }
export type SavePromptMutationHookResult = ReturnType<typeof useSavePromptMutation>;
export type SavePromptMutationResult = Apollo.MutationResult<SavePromptMutation>;
export type SavePromptMutationOptions = Apollo.BaseMutationOptions<SavePromptMutation, SavePromptMutationVariables>;
export const GetApplicationAudDocument = gql`
    query GetApplicationAud($token: String!) {
  getCloudflareAUD(token: $token)
}
    `;

/**
 * __useGetApplicationAudQuery__
 *
 * To run a query within a React component, call `useGetApplicationAudQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationAudQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationAudQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetApplicationAudQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationAudQuery, GetApplicationAudQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationAudQuery, GetApplicationAudQueryVariables>(GetApplicationAudDocument, options);
      }
export function useGetApplicationAudLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationAudQuery, GetApplicationAudQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationAudQuery, GetApplicationAudQueryVariables>(GetApplicationAudDocument, options);
        }
export type GetApplicationAudQueryHookResult = ReturnType<typeof useGetApplicationAudQuery>;
export type GetApplicationAudLazyQueryHookResult = ReturnType<typeof useGetApplicationAudLazyQuery>;
export type GetApplicationAudQueryResult = Apollo.QueryResult<GetApplicationAudQuery, GetApplicationAudQueryVariables>;
export function refetchGetApplicationAudQuery(variables: GetApplicationAudQueryVariables) {
      return { query: GetApplicationAudDocument, variables: variables }
    }