import { Chip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, ReactElement } from 'react';
import { ProfileWarningModel } from '../../../../shared/utilities/__generated__/graphql';

const WarningsTable: FC<{ warnings: Partial<ProfileWarningModel>[] }> = ({
   warnings,
}): ReactElement => {
   const columns: GridColDef[] = [
      { field: 'reason', headerName: 'Reason', flex: 1 },
      {
         field: 'callerId',
         headerName: 'Creator',
         type: 'string',
         width: 200,
         // valueGetter: ({ value }) => value && new Date(value),
         renderCell: ({ value }) => {
            return <Chip label={value} variant='outlined' />;
         },
      },
      {
         field: 'userSeen',
         headerName: 'Acknowledged',
         width: 300,
         renderCell: ({ value }) => {
            return value ? (
               <Chip label='Acknowledged by user' color='success' />
            ) : (
               <Chip label='Pending acknowledgement by user' color='info' />
            );
         },
      },
      {
         field: 'createdAt',
         headerName: 'Created at',
         type: 'dateTime',
         width: 200,
         valueGetter: ({ value }) => value && new Date(value),
      },
   ];

   return (
      <DataGrid
         rows={warnings}
         getRowId={(row) => row.reason + row.createdAt}
         pagination
         columns={columns}
         disableColumnSelector
         disableSelectionOnClick
         disableColumnMenu
         hideFooter
         hideFooterPagination
         autoHeight
         // initialState={initialState}
      />
   );
};

export default WarningsTable;
