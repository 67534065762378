import { Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { UserLayerType } from '../../screens/UserCoverageMap/UserCoverageMap';

function MapControlsPanel(props: {
   currentType: UserLayerType;
   onChangeType: (city: UserLayerType) => void;
}) {
   const handleClick = () => {
      props.onChangeType(props.currentType == 'gender' ? 'activity' : 'gender');
   };

   return (
      <Card
         sx={() => ({
            position: 'absolute',
            top: 25,
            left: 25,
            p: 1,
            display: {
               xs: 'none',
               lg: 'block',
            },
         })}
         className='control-panel'
      >
         <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Visualisation</InputLabel>
            <Select value={props.currentType} label='Visualisation' onChange={handleClick}>
               <MenuItem value={'gender'}>Gender</MenuItem>
               <MenuItem value={'activity'}>Activity</MenuItem>
            </Select>
         </FormControl>
      </Card>
   );
}

export default React.memo(MapControlsPanel);
