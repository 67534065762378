/**
 * Query filters to filter model
 */

import { uniq } from 'lodash';

export const queryFiltersToModel = () => {
   const params = new URLSearchParams(window.location.search);

   // params to spread object:
   // https://stackoverflow.com/questions/35395277/how-to-convert-urlsearchparams-to-object

   return {
      // @ts-ignore
      items: [...params]
         .map(([filter, value], index) => {
            const [prefix, ...filterSplitted] = filter.split('-');

            // remove 'non'-filter related queries as well as the 'operator' filter
            if (prefix !== 'filter' || filterSplitted[0] === 'operator') {
               return undefined;
            }

            const operatorValue = filterSplitted.splice(-1, 1).join('');

            return {
               columnField: filterSplitted.join(''),
               operatorValue,
               value: operatorValue === 'isAnyOf' && value ? value.split(',') : value || null,
               id: index + 1,
            };
         })
         .filter(Boolean),
      linkOperator: params.get('filter-operator') || 'and',
   };
};

export const columnsVisibilityModelToQueryArray = (
   columnVisibility: any = {},
   columns: { name: string; hide: boolean }[] = [],
) => {
   const columnsWithCheck = [
      {
         name: '__check__',
         hide: columnVisibility.__check__ ?? false,
      },
      ...columns.filter(({ name, hide }) => columnVisibility[name] || hide),
   ];

   const columnsToShow = uniq(
      columnsWithCheck.filter(({ name }) => columnVisibility[name] ?? true).map(({ name }) => name),
   );

   // if shown columns, is equal to all the posibile columns length, no need to append this to the query
   // since the default is to show all, if noone specific is set to be shown
   if (columnsToShow.length - 1 === columns.length) {
      return [];
   }

   return uniq(
      columnsWithCheck.filter(({ name }) => columnVisibility[name] || true).map(({ name }) => name),
   );
};
