import { Avatar, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, ReactElement, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TransparentTooltip } from '../../../../shared/components/TransperantTooltip';
import {
   ChatModel,
   MessageModel,
   PlaceModel,
   ProfileModel,
   useGetReportedChatQuery,
} from '../../../../shared/utilities/__generated__/graphql';

const columns: GridColDef[] = [
   {
      field: 'sender',
      headerName: 'Sender',
      width: 200,
      renderCell: ({ value }) => {
         const profile = value as ProfileModel;

         if (!value) {
            return 'N/A';
         }

         return (
            <>
               <TransparentTooltip
                  title={
                     <Avatar src={value.avatar.thumbnailUrl} sx={{ width: 300, height: 300 }} />
                  }
               >
                  <>
                     <Avatar
                        src={value.avatar.thumbnailUrl}
                        sx={{ width: 32, height: 32, mr: 0.5 }}
                     />
                     <Link to={`/users/${profile.id}`}>{profile.name}</Link>
                  </>
               </TransparentTooltip>
            </>
         );
      },
   },
   {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'dateTime',
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
   },
   {
      field: 'content',
      headerName: 'Content',
      flex: 1,
      renderCell: ({ value, row: message }) => {
         if (message.type == 'PLACE') {
            const place = JSON.parse(message.content!) as PlaceModel;

            return (
               <>
                  <Avatar
                     src={place.image?.thumbnailUrl ?? ''}
                     sx={{ width: 32, height: 32, mr: 0.5 }}
                  />
                  {place.name}
               </>
            );
         }

         if (message.type == 'GIF') {
            return (
               <TransparentTooltip
                  title={<img src={`https://gifs.doubble.app/admin/${value}/200.gif`} />}
               >
                  <img src={`https://gifs.doubble.app/admin/${value}/200.gif`} />
               </TransparentTooltip>
            );
         }

         return value;
      },
   },
];

const ViewReportedChat: FC<void> = (): ReactElement => {
   const { id } = useParams();
   const { loading, data } = useGetReportedChatQuery({
      variables: {
         chatId: id as string,
      },
      fetchPolicy: 'cache-and-network',
   });
   const [page, setPage] = useState(0);
   const [pageSize, setPageSize] = useState(100);

   if (loading) {
      return <div>Loading...</div>;
   }

   if (!data) {
      return <div>Chat not found</div>;
   }

   document.title = 'Reported chat - Doubble Admin';

   const chat = data.getReportedChat?.chat as ChatModel;
   const profiles = data.getReportedChat?.profiles as ProfileModel[];
   const messages = [...(data.getReportedChat?.messages ?? [])]
      .sort((a, b) => {
         return a.createdAt < b.createdAt ? -1 : 1;
      })
      .map((message) => ({
         ...message,
         sender: profiles.find((profile) => profile.id == message.senderId),
      })) as (MessageModel & { sender: ProfileModel })[];

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <Typography variant='h4' mb={2}>
            Reported {chat.type}
         </Typography>

         <DataGrid
            rows={messages}
            columns={columns}
            // checkboxSelection
            autoHeight
            page={page}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
         />
      </div>
   );
};

export default ViewReportedChat;
