// PLEASE SYNC WITH BACKEND
export const ADMIN_APPLICATION_AUDS = [
   // Old Admin - pages - https://one.dash.cloudflare.com/40ba6e2bb9ce3050671c2458c7135546/access/apps/edit/61e027e0-eeb6-423b-8f31-e8279bdb6bbd
   'd5d39c761b38881e22a0a8e27e90385743fce1f2eb82f7f4b9cd55343c011e0d',

   // ADMIN LOCAL - https://one.dash.cloudflare.com/40ba6e2bb9ce3050671c2458c7135546/access/apps/edit/cb80cb10-c728-4673-8432-e0986f33e268
   'e986f10a421e3953659b08245b5465bb7881aed5060daa25275a529f0cbedcf9',
   // ADMIN V2: (https://one.dash.cloudflare.com/40ba6e2bb9ce3050671c2458c7135546/access/apps/edit/309aed78-e339-404a-96de-f666fb268c23)
   '8c32de79cd055ee189ecd1478f3708c8c2b0ec14a598221fb074f12fa7543e86',
];

export const AMBASSADOR_APPLICATION_AUDS = [
   // Ambassadors - cloudflare pages - https://one.dash.cloudflare.com/40ba6e2bb9ce3050671c2458c7135546/access/apps/edit/50fc7c6d-fdd5-4b3b-a88a-bc1a7ebe1b06
   'a6922a2865e558f096df10b8bd8a7d216e2bd0a5eb71a6c069a94413ce89d1fd',

   // Ambassadors -local - https://one.dash.cloudflare.com/40ba6e2bb9ce3050671c2458c7135546/access/apps/edit/ec8b90c8-5fa5-467f-80fa-377c06f10cad
   '448ccbf841917986bc98f74d1d5d82409699fd0c2dfa61332f55b6af705a8f88',

   // AMBASSADORS: https://one.dash.cloudflare.com/40ba6e2bb9ce3050671c2458c7135546/access/apps/edit/deb3fd39-d38a-481f-be16-752323d1b3e3
   '5a6747fbce2b58037bbd086c8bae6d56ebafcd81ed442909a46a08800952287a',

   // Allow all admins to access the ambassador app
   ...ADMIN_APPLICATION_AUDS,
];

export const INVESTOR_APPLICATION_AUDS = [
   // https://one.dash.cloudflare.com/40ba6e2bb9ce3050671c2458c7135546/access/apps/edit/b8c4535c-fb8b-4be4-80a2-95a887d13f01
   '6eb36b24f8890c1e77bd2df10f4952cc4ce7ef6891ccc26c031b925b8892c7fb',

   ...ADMIN_APPLICATION_AUDS,
];
