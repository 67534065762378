import { Tooltip, TooltipProps, styled } from '@mui/material';

const _TransparentTooltip = ({ className, ...props }: TooltipProps) => (
   <Tooltip {...props} classes={{ tooltip: className }} />
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TransparentTooltip = styled(_TransparentTooltip)<TooltipProps>(({ theme: _ }) => ({
   backgroundColor: 'transparent',
   color: 'rgba(0, 0, 0, 0.87)',
   border: 'none',
}));
