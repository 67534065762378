import CheckIcon from '@mui/icons-material/Check';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Button } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useEffect, useRef } from 'react';
import {
   ReportModel,
   useAskForElaborationOnReportMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const AskForElaborationButton: FC<{ id: ReportModel['id']; onCleared?: () => void }> = ({
   id,
   onCleared,
}) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();
   const [askForElaborationMutation, { error, loading, data }] =
      useAskForElaborationOnReportMutation({
         variables: {
            reportId: id,
         },
      });

   const handleDelete = async () => {
      await confirm({
         title: 'Are you sure?',
         description: 'This will send a message to the user asking for elaboration via Intercom',
         confirmationText: 'Ask for elaboration',
         cancellationText: 'Cancel',
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Asking user for elaboration via Intercom`, {
            variant: 'info',
            autoHideDuration: null,
         });

         const deleteAccountMutationResult = await askForElaborationMutation();
         closeSnackbar(notification.current);

         if (deleteAccountMutationResult.data) {
            onCleared && onCleared();

            enqueueSnackbar(`Done!`, {
               variant: 'success',
            });
         }
      });
   };

   useEffect(() => {
      if (error && !loading) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Could not ask for elaboration`, {
            variant: 'error',
         });
      }
   }, [error, loading]);

   return (
      <>
         <Button
            startIcon={data ? <CheckIcon /> : <LiveHelpIcon />}
            variant='outlined'
            disabled={loading || !!data}
            onClick={
               !data
                  ? () => handleDelete()
                  : () => {
                       /* do nothing */
                    }
            }
         >
            {data ? 'Asked for elaboration' : 'Ask for elaboration'}
         </Button>
      </>
   );
};

export default AskForElaborationButton;
