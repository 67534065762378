import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { routes } from '../../utilities/routes';

const RoutesComponent = () => {
   const location = useLocation();

   // current route

   useEffect(() => {
      const currentRoute = routes.find((route) => route.path === window.location.pathname);
      document.title = (currentRoute?.title ? currentRoute.title + ' - ' : '') + 'Doubble Admin';
   }, [location.pathname]);

   return (
      <Routes>
         {routes.map((route) => (
            <Route key={route.key} path={route.path} element={<route.component />} />
         ))}
      </Routes>
   );
};

export default RoutesComponent;
