import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import useFetchAndUnzip from '../../../../shared/utilities/helpers';

interface UserDebugLogsProps {
   url: string;
}

const UserDebugLogs: FunctionComponent<UserDebugLogsProps> = ({ url }) => {
   const { content, error, loading } = useFetchAndUnzip(url);
   const [onlyErrors, setOnlyErrors] = useState(false);
   const [customSearch, setCustomSearch] = useState('');

   if (loading) {
      return <div>Loading...</div>;
   }

   if (error) {
      return <div>Error: {error}</div>;
   }

   // Function to filter lines based on "onlyErrors" and "customSearch"
   const getFilteredContent = () => {
      if (!content) return '';

      let filteredContent = content.split('---');

      // Apply "onlyErrors" filter
      if (onlyErrors) {
         filteredContent = filteredContent.filter(
            (block) =>
               block.toLowerCase().includes('error') ||
               (block.toLowerCase().includes('#') && !block.toLowerCase().includes('Normal')),
         );
      }

      // Apply "customSearch" filter
      if (customSearch.trim()) {
         const searchLower = customSearch.toLowerCase();
         filteredContent = filteredContent.filter((block) =>
            block.toLowerCase().includes(searchLower),
         );
      }

      return filteredContent.join('---');
   };

   return (
      <div>
         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <ToggleButtonGroup>
               <ToggleButton
                  selected={!onlyErrors}
                  color='primary'
                  value='all'
                  onClick={() => {
                     setOnlyErrors(false);
                  }}
               >
                  All
               </ToggleButton>
               <ToggleButton
                  color='error'
                  selected={onlyErrors}
                  value='errors'
                  onClick={() => {
                     setOnlyErrors(true);
                  }}
               >
                  Errors
               </ToggleButton>
            </ToggleButtonGroup>
            <TextField
               variant='outlined'
               size='small'
               placeholder='Search...'
               value={customSearch}
               onChange={(e) => setCustomSearch(e.target.value)}
               style={{ marginLeft: '1rem' }}
            />
         </div>
         <h3>File Content:</h3>
         <pre>{getFilteredContent()}</pre>
      </div>
   );
};

export default UserDebugLogs;
