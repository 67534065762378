import { Chip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useAuditLogsQuery } from '../../shared/utilities/__generated__/graphql';
import { stringToColor } from '../../shared/utilities/helpers';

export const AuditLogs: React.FC = () => {
   const columns: GridColDef[] = [
      {
         field: 'callerName',
         headerName: 'Caller name',
         width: 300,
         renderCell: (val) => {
            return <Typography>{val.value}</Typography>;
         },
      },
      {
         field: 'operationType',
         headerName: 'Operation Type',
         width: 150,
         renderCell: (val) => {
            return (
               <Chip
                  sx={{ backgroundColor: stringToColor(val.value) }}
                  label={val.value}
                  color='info'
               />
            );
         },
      },
      { field: 'resourceType', headerName: 'Resource Type', width: 150 },
      {
         field: 'createdAt',
         headerName: 'Created At',
         width: 200,
         renderCell: (date) => {
            return format(Date.parse(date.value), 'dd-MM-yyyy HH:mm:ss');
         },
      },
   ];

   const [page, setPage] = useState(0);
   const [pageSize, setPageSize] = useState(100);
   const { loading, data } = useAuditLogsQuery({
      variables: {
         skip: page * pageSize,
         take: pageSize,
      },
   });

   const [rowCount, setRowCount] = useState(data?.auditlogs.total || 0);

   useEffect(() => {
      setRowCount((prevRowCountState) =>
         data?.auditlogs.total !== undefined ? data?.auditlogs?.total || 0 : prevRowCountState,
      );
   }, [data?.auditlogs.total, setRowCount]);

   return (
      <div style={{ height: '90vh', width: '100%' }}>
         <DataGrid
            rows={data?.auditlogs.items || []}
            columns={columns}
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            paginationMode='server'
            onPageChange={(newPage) => {
               setPage(newPage);
            }}
            onPageSizeChange={(pageSize) => {
               setPageSize(pageSize);
            }}
            loading={loading}
         />
      </div>
   );
};

export default AuditLogs;
