import queryString from 'query-string';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { paramsToQueryString, removeEmptyEntries } from '../utilities/helpers';

export default function useRouter() {
   const params = useParams();
   const location = useLocation();
   const navigate = useNavigate();
   // const match = useRouteMatch(); // https://reactrouter.com/en/main/upgrading/v5#use-usenavigate-instead-of-usehistory

   // Utility for updating URL params
   const updateQueryParams = useCallback(
      (updates = {}) => {
         navigate(
            {
               pathname: location.pathname,
               search: paramsToQueryString(
                  removeEmptyEntries({
                     ...queryString.parse(location.search),
                     ...updates,
                  }),
               ),
            },
            { replace: true },
         );
      },
      [history, location.search, location.pathname],
   );

   // Return our custom router object
   // Memoize so that a new object is only returned if something changes
   const returnValues = useMemo(
      () => ({
         // For convenience add push(), replace(), pathname at top level
         // push: history.push,
         // replace: history.replace,
         pathname: location.pathname,

         // Merge params and parsed query string into single "query" object
         // so that they can be used interchangeably.
         // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
         query: {
            ...queryString.parse(location.search), // Convert string to object
            ...params,
         },
         // Include match, location, history objects so we have
         // access to extra React Router functionality if needed.
         // match,
         location,
         history,
         navigate,
      }),
      [
         params,
         // match,
         location,
         history,
      ],
   );

   return {
      ...returnValues,
      updateQueryParams,
   };
}
