import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   useDeletePlaceSuggestionsMutation,
   useGetPlacesQuery,
} from '../../../../shared/utilities/__generated__/graphql';

const PlacesOverviewSceen: FC<void> = (): ReactElement => {
   const [page, setPage] = useState(0);
   const [pageSize, setPageSize] = useState(100);
   const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);

   const [rows, setRows] = useState<any[]>([]);
   const notification = useRef<SnackbarKey | undefined>(undefined);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();

   const handleSelectionChange = (selected: GridSelectionModel) => {
      setSelectedRows(selected);
   };

   const { loading, data } = useGetPlacesQuery({
      variables: {
         skip: pageSize * page,
         // take: pageSize,
         take: 1000,
      },
   });

   useEffect(() => {
      setRows(data?.placesWithoutDistance.items || []);
   }, [data?.placesWithoutDistance.items]);

   // Some API clients return undefined while loading
   // Following lines are here to prevent `rowCountState` from being undefined during the loading
   const [rowCountState, setRowCountState] = useState(
      data?.placesWithoutDistance.items.length || 0,
   );
   useEffect(() => {
      setRowCountState((prevRowCountState) =>
         data?.placesWithoutDistance.items.length !== undefined
            ? data?.placesWithoutDistance?.items.length || 0
            : prevRowCountState,
      );
   }, [data?.placesWithoutDistance.items.length, setRowCountState]);

   const navigate = useNavigate();

   const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', width: 350 },
      {
         field: 'country',
         headerName: 'Country',
         width: 350,
         valueGetter: ({ row }) => {
            return row?.address?.countryCodeISO3;
         },
      },
      {
         field: 'city',
         headerName: 'City',
         width: 350,
         valueGetter: ({ row }) => {
            return row?.address?.city;
         },
      },
      {
         field: 'address',
         headerName: 'Address',
         width: 350,
         valueGetter: ({ row }) => {
            const address = row?.address;
            return address?.formatted ?? `${address?.streetName} ${address?.streetNumber}`;
         },
      },
      {
         field: 'recommendedCount',
         headerName: 'Recommendations',
         type: 'number',
         width: 165,
         valueGetter: ({ row }) => {
            return row?.statistics?.recommendedCount;
         },
      },
      // TODO: Add suspension
   ];

   const [deleteSuggestionsMutation, { loading: deleting }] = useDeletePlaceSuggestionsMutation({
      variables: {
         suggestionIds: selectedRows.map((id) => id.toString()),
      },
   });

   const deleteSelectedSuggestions = async () => {
      await confirm({
         title: 'Are you sure?',
         description: 'This action cannot be undone.',
         confirmationText: 'Delete',
         cancellationText: 'Cancel',
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Deleting suggestion(s), please wait...`, {
            variant: 'info',
            autoHideDuration: null,
         });

         const deleteSuggestionMutationResult = await deleteSuggestionsMutation();
         closeSnackbar(notification.current);

         if (deleteSuggestionMutationResult.data) {
            enqueueSnackbar(`Suggestions deleted!`, {
               variant: 'success',
            });

            setRows(rows.filter((row) => !selectedRows.includes(row.id)));
            // apiRef.current.updateRows(selectedRows.map((id) => ({ id, _action: 'delete' })));
         }
      });
   };

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <DataGrid
            rows={rows}
            rowCount={rowCountState}
            loading={loading}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[5]}
            pagination
            page={page}
            pageSize={pageSize}
            sx={{ height: '90vh' }}
            // paginationMode='server'
            checkboxSelection
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            onSelectionModelChange={handleSelectionChange}
         />
         {/* <Box sx={{ m: 2 }}>
            {selectedRows.length} suggestion(s) selected
            <Button
               sx={{ ml: 2 }}
               variant='contained'
               disabled={!selectedRows.length || deleting}
               onClick={deleteSelectedSuggestions}
            >
               Delete selected
            </Button>
         </Box> */}
      </div>
   );
};

export default PlacesOverviewSceen;
