/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import qs from 'query-string';

const initialState = {
   params: qs.parse(window.location.search, {
      arrayFormat: 'comma',
   }),
};

const querySlice = createSlice({
   name: 'query',
   initialState,
   reducers: {
      addParams(state, { payload: { _query = {}, ...newParams } }) {
         state.params = {
            ...state.params,
            _query: {
               ...(state.params._query as any),
               ..._query,
            },
            ...newParams,
         };
      },

      removeParams(state, { payload: paramsToRemove }) {
         state.params = omit(
            {
               ...state.params,
               _query: omit({ ...(state.params._query as any) }, paramsToRemove),
            },
            paramsToRemove,
         );
      },
   },
});

export const { addParams: addQueryParams, removeParams: removeQueryParams } = querySlice.actions;

export default querySlice.reducer;
