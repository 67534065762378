import GppBadIcon from '@mui/icons-material/GppBad';
import { Button } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useEffect, useRef } from 'react';
import {
   ProfileModel,
   useEnablePaywallMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const EnablePaywallButton: FC<{ user: ProfileModel }> = ({ user }) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();
   const [removeUpdateLimitMutation, { error, loading }] = useEnablePaywallMutation({
      variables: {
         userId: user.id!,
      },
   });

   const handleRemoveLimit = async () => {
      await confirm({
         title: 'Enable paywall?',
         description: 'This will allow the user to buy premium',
         confirmationText: 'Enable paywall',
         cancellationText: 'Cancel',
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Please wait...`, {
            variant: 'info',
            autoHideDuration: null,
         });

         const deleteAccountMutationResult = await removeUpdateLimitMutation();
         closeSnackbar(notification.current);

         if (deleteAccountMutationResult.data) {
            enqueueSnackbar(`Paywall enabled!`, {
               variant: 'success',
            });
         }
      });
   };

   useEffect(() => {
      if (error && !loading) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Could not enable paywall`, {
            variant: 'error',
         });
      }
   }, [error, loading]);

   return (
      <>
         <Button startIcon={<GppBadIcon />} variant='outlined' onClick={() => handleRemoveLimit()}>
            Enable paywall
         </Button>
      </>
   );
};

export default EnablePaywallButton;
