import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Avatar, Chip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TransparentTooltip } from '../../../../shared/components/TransperantTooltip';
import { ImageModel, useProfilesQuery } from '../../../../shared/utilities/__generated__/graphql';
import { calculateAge, navigateOrNewTab } from '../../../../shared/utilities/helpers';
const imageSize = 40;
const columns: GridColDef[] = [
   { field: 'name', headerName: 'Name', width: 150 },
   { field: 'username', headerName: 'Username', width: 150 },
   { field: 'area', headerName: 'Area', width: 200 },
   {
      field: 'birthDate',
      headerName: 'Birth date',
      type: 'date',
      width: 100,
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: ({ value }) => {
         return value ? `${calculateAge(value)} years old` : null;
      },
   },
   {
      field: 'matchingActive',
      headerName: 'Active',
      type: 'boolean',
   },
   {
      field: 'gender',
      headerName: 'Gender',
      renderCell: ({ value: gender }) => {
         if (gender === 'Male') {
            return <Chip label='Male' color='info' />;
         }
         if (gender === 'Female') {
            return <Chip label='Female' sx={{ backgroundColor: '#e84393' }} />;
         }
         return <Chip label={gender} sx={{ backgroundColor: '#fdcb6e' }} />;
      },
   },

   {
      field: 'images',
      headerName: 'Images',
      width: (imageSize + 12) * 6,
      renderCell: ({ value: images }) => {
         return images.map(({ thumbnailUrl }: ImageModel, index: number) => (
            <TransparentTooltip
               key={index}
               title={<Avatar src={thumbnailUrl!} sx={{ width: 300, height: 300 }} />}
            >
               <Avatar src={thumbnailUrl!} sx={{ width: imageSize, height: imageSize, mr: 0.5 }} />
            </TransparentTooltip>
         ));
      },
   },
   {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'dateTime',
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
   },
   { field: 'appVersion', headerName: 'Version', width: 100 },
   {
      field: 'devicePlatform',
      headerName: 'Platform',
      width: 100, //
      renderCell: ({ value: platform }) => {
         const p = platform?.toLowerCase();

         if (p.includes('android')) {
            return <AndroidIcon />;
         }

         if (p.includes('ios')) {
            return <AppleIcon />;
         }

         return <QuestionMarkIcon />;
      },
   },
   // TODO: Add suspension
];

const Users: FC<{ onlyWaitListed: boolean }> = ({ onlyWaitListed = false }): ReactElement => {
   const [page, setPage] = useState(0);
   const [pageSize, setPageSize] = useState(100);

   const { loading, data } = useProfilesQuery({
      variables: {
         skip: pageSize * page,
         take: pageSize,
         onlyWaitListed: onlyWaitListed,
      },
   });

   // Some API clients return undefined while loading
   // Following lines are here to prevent `rowCountState` from being undefined during the loading
   const [rowCountState, setRowCountState] = useState(data?.profiles.total || 0);
   useEffect(() => {
      setRowCountState((prevRowCountState) =>
         data?.profiles.total !== undefined ? data?.profiles?.total || 0 : prevRowCountState,
      );
   }, [data?.profiles.total, setRowCountState]);

   const navigate = useNavigate();

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <DataGrid
            rows={data?.profiles.items || []}
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[5]}
            pagination
            page={page}
            pageSize={pageSize}
            sx={{ height: '90vh' }}
            paginationMode='server'
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            onRowClick={(params, event) => {
               navigateOrNewTab(`/users/${params.id}`, navigate, event);
            }}
            // initialState={initialState}
         />
      </div>
   );
};

export default Users;
