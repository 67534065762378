import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReportedProfilesQuery } from '../../../../shared/utilities/__generated__/graphql';

import { Avatar, Box, Tooltip } from '@mui/material';
import { differenceInDays, formatDistance } from 'date-fns';
import { groupBy, isEqual, merge, uniq, uniqBy } from 'lodash';
import qs from 'query-string';
import useQuerifyState from '../../../../shared/hooks/use-querify-state';
import {
    columnsVisibilityModelToQueryArray,
    queryFiltersToModel,
} from '../../../../shared/utilities/datagrid';
import { navigateOrNewTab } from '../../../../shared/utilities/helpers';

let didSkipFirstSelectionChange = false;

const datagridColumns: GridColDef[] = [
   { field: 'count', headerName: 'Reports', type: 'number', width: 75 },
   {
      field: 'reportsSinceWarning',
      headerName: 'Reports since warning',
      type: 'number',
      width: 175,
      renderCell: ({ value }) => {
         if (value > 0) {
            return <Chip label={value} variant='filled' color='error' />;
         }

         return value;
      },
   },
   {
      field: 'lastReportedAt',
      headerName: 'Last reported at',
      type: 'dateTime',
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
         value &&
         formatDistance(new Date(value), new Date(), {
            addSuffix: true,
         }),
      renderCell: ({ value }) => {
         return (
            <Tooltip title={value.toString()}>
               <Box>
                  {formatDistance(new Date(value), new Date(), {
                     addSuffix: true,
                  })}
               </Box>
            </Tooltip>
         );
      },
   },
   { field: 'name', headerName: 'Name', width: 150 },
   { field: 'username', headerName: 'Username', width: 200 },
   { field: 'country', headerName: 'Country', width: 200 },
   { field: 'area', headerName: 'City', width: 200 },
   {
      field: 'createdAt',
      headerName: 'Profile created at',
      type: 'dateTime',
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
         value &&
         formatDistance(new Date(value), new Date(), {
            addSuffix: true,
         }),
      renderCell: ({ value }) => {
         const relative = formatDistance(new Date(value), new Date(), {
            addSuffix: true,
         });

         let date = <Box>{relative}</Box>;

         // days ago using date-fns
         const daysSinceCreation = differenceInDays(new Date(), new Date(value));

         // less than 7 days
         if (daysSinceCreation <= 20) {
            date = (
               <Chip
                  label={relative}
                  variant='filled'
                  color={daysSinceCreation <= 7 ? 'error' : 'warning'}
               />
            );
         }
         const child = <Tooltip title={value?.toString()}>{date}</Tooltip>;

         return child;
      },
   },
   {
      field: 'reports',
      headerName: 'Reports',
      minWidth: 500,
      flex: 1,
      // valueGetter: ({ row: { reports } }) => {
      //    const uniqueReports = uniqBy(reports, (r) => r.reporter.name) as any[];

      //    return uniqueReports.map(({ reporter }) => reporter.name).join(', ');
      // },
      filterable: false,

      // getApplyQuickFilterFn:
      //    (filterValue) =>
      //    ({ value }) => {
      //       const uniqueReports = value.map((report: any) => report.message);

      //       console.log(uniqueReports);

      //       return uniqueReports.join(', ').toLowerCase().includes(filterValue.toLowerCase());
      //    },
      renderCell: ({ value }) => {
         const reports = Object.keys(groupBy(value, 'message')).sort((a, b) => {
            const countA = value.filter((report: any) => report.message === a).length;
            const countB = value.filter((report: any) => report.message === b).length;

            return countB - countA || a.localeCompare(b);
         });

         return (
            <Box sx={{ overflowX: 'auto' }}>
               {reports.map((message) => {
                  const count = uniqBy(
                     value,
                     (v: any) => v.reportingUserId + '-' + v.content,
                  ).filter((report: any) => report.message === message).length;

                  return (
                     <Tooltip key={message} title={message}>
                        <Chip
                           avatar={count > 1 ? <Avatar>{count}</Avatar> : undefined}
                           sx={{ mr: 1 }}
                           label={message}
                           variant='outlined'
                        />
                     </Tooltip>
                  );
               })}
            </Box>
         );
      },
   },
   {
      field: 'reporter',
      headerName: 'Reporters',
      width: 400,
      // flex:1,
      valueGetter: ({ row: { reports } }) => {
         return (uniqBy(reports, (r) => r.reporter?.username) as any[])
            .map(({ reporter }) => reporter?.username)
            .filter(Boolean)
            .join(', ');
      },
      renderCell: ({ row: { reports } }) => {
         const uniqueReports = uniqBy(reports, (r) => r.reporter?.name) as any[];

         return (
            <Box sx={{ overflowX: 'auto' }}>
               {uniqueReports
                  .filter((r) => r.reporter)
                  .map(({ reporter }) => {
                     return (
                        <Tooltip key={reporter.name} title={reporter.name}>
                           <Chip
                              // avatar={count > 1 ? <Avatar>{count}</Avatar> : undefined}
                              sx={{ mr: 1 }}
                              label={reporter.username}
                              variant='outlined'
                           />
                        </Tooltip>
                     );
                  })}
            </Box>
         );
      },
   },
   // TODO: Add suspension
];

const Reports: FC<void> = (): ReactElement => {
   // const [page, setPage] = useState(0);

   // could be passed in from props, but for now, hardcoded
   const columns = useMemo(
      () => datagridColumns.map(({ field }) => ({ name: field, hide: false })),
      [],
   );

   const initiallyShownColumns = useMemo(
      () => ['__check__', ...uniq(columns.map(({ name }) => name))],
      [columns],
   );

   const [pageSize, setPageSize] = useState(100);

   const parsedUrl = qs.parseUrl(window.location.href);

   const settings: any = {};

   const parsedUrlCommaSeperated = qs.parseUrl(window.location.href, {
      arrayFormat: 'comma',
   });

   const selectedFromUrl = parsedUrlCommaSeperated?.query?.selected || [];
   const columnsFromUrl = parsedUrlCommaSeperated?.query?.columns || [];

   const sortFromUrl = parsedUrl?.query?.sort || undefined;
   const [sortField, sortBy] = sortFromUrl ? (sortFromUrl as string).split(',') : [];

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [stateFromQuery, appendQueryParams, { reset: resetFilters }] = useQuerifyState(
      (state) => {
         /**
          * For filters
          */
         let columnFilters = {};

         if (state?.filters?.items?.length) {
            const operatorsWithoutValue = ['isEmpty', 'isNotEmpty']; // operators where a value is not required in order for filter to be valid

            const f = {};

            // eslint-disable-next-line no-restricted-syntax
            for (const { columnField, operatorValue, value } of state.filters.items) {
               (f as any)[`filter-${columnField}-${operatorValue}`] =
                  operatorsWithoutValue.includes(operatorValue) ? value || null : value;
            }

            columnFilters = {
               ...f,
               'filter-operator':
                  state.filters.linkOperator !== 'and' ? state.filters.linkOperator : undefined,
            };
         }

         /**
          * For page
          */
         const page = state?.page ?? 0;

         /**
          * For selected rows
          */
         const selected = state?.selected || [];

         /**
          * For sorted columns
          */
         const sort = state?.sort?.length
            ? `${state?.sort[0]?.field},${state?.sort[0]?.sort}`
            : undefined;

         /**
          * For shown columns
          */

         const shownColumns = state?.columns;

         /**
          * For search query
          */

         /**
          * TODO?
          * Store: For column position, Rows Per Page, Page,
          */

         return {
            q: state?.q,
            filters: columnFilters,
            selected: Array.isArray(selected) ? selected : selected.split(','),
            sort,
            page,
            columns: shownColumns,
            // below object (_query) will be passed to the query string
            _query: merge(columnFilters, {
               q: state?.q || undefined,
               selected: selected.length ? selected : undefined,
               sort,
               page,
               // we do the isEqual comparssion, because if the shown columns is same as "initiallyShownColumns"
               // there's no need to include them in the query.
               columns:
                  shownColumns?.length && !isEqual(shownColumns, initiallyShownColumns)
                     ? shownColumns
                     : undefined,
            }),
         };
      },
      {
         q: parsedUrl?.query?.q,
         filters: queryFiltersToModel().items.length
            ? queryFiltersToModel()
            : settings?.filters || queryFiltersToModel(),
         selected: Array.isArray(selectedFromUrl) ? selectedFromUrl : [selectedFromUrl],
         page: parsedUrl?.query?.page || 0,
         sort: sortFromUrl
            ? [
                 {
                    field: sortField,
                    sort: sortBy,
                 },
              ]
            : settings?.sort || [],
         columns: columnsFromUrl?.length ? columnsFromUrl : settings?.columns || [],
      },
      {
         defaultValues: settings,
      },
   );
   /**
    * Selection
    *
    * The `didSkipFirstSelectionChange` variable is added as a workaround, because for some reason if ?selected=123,456,etc is included
    * in the query, then on mount `handleSelectionChange` will fire twice, and the second time `selectedRows` will be empty array, for
    * some unknown reason. The `didSkipFirstSelectionChange` flag has been added to circumvent this issue
    */
   const handleSelectionChange = (selectedRows: any) => {
      if (didSkipFirstSelectionChange || (!stateFromQuery.selected && selectedRows.length)) {
         appendQueryParams({ ...stateFromQuery, selected: selectedRows });
      }

      if (selectedRows.length) {
         didSkipFirstSelectionChange = true;
      }
   };

   const selected = useMemo(() => stateFromQuery?.selected || [], [stateFromQuery.selected]);

   const onFilterModelChange = (newFilters: any) => {
      appendQueryParams({ ...stateFromQuery, filters: newFilters });
   };

   /**
    * Get visible columns
    */

   const columnVisibilityModel = useMemo(() => {
      if (!stateFromQuery?.columns?.length) {
         return {};
      }

      const columnVisibility = {
         __check__: stateFromQuery?.columns?.includes('__check__'),
      };

      columns
         .map(({ name }) => name)
         .forEach((column) => {
            //  @ts-ignore
            columnVisibility[column] = stateFromQuery?.columns?.includes(column);
         });

      return columnVisibility;
   }, [columns, stateFromQuery?.columns]);

   const { loading, data } = useReportedProfilesQuery({
      variables: {
         take: 2000,
         skip: 0,
         // skip: pageSize * stateFromQuery.page,
         // take: pageSize,
      },
      fetchPolicy: 'cache-and-network',
   });

   // Some API clients return undefined while loading
   // Following lines are here to prevent `rowCountState` from being undefined during the loading
   const [rowCountState, setRowCountState] = useState(data?.reportedProfiles.total || 0);
   useEffect(() => {
      setRowCountState((prevRowCountState) =>
         data?.reportedProfiles.total !== undefined
            ? data?.reportedProfiles?.total || 0
            : prevRowCountState,
      );
   }, [data?.reportedProfiles.total, setRowCountState]);

   const navigate = useNavigate();

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <DataGrid
            rows={
               data?.reportedProfiles.items.map((row) => ({
                  ...row.profile,
                  ...row,
                  profile: row.profile,
               })) || []
            }
            // onSelectionModelChange={handleSelectionChange}
            sortModel={stateFromQuery?.sort || []}
            onSortModelChange={(sort) => {
               appendQueryParams({ ...stateFromQuery, sort });
            }}
            rowCount={rowCountState}
            loading={loading}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[5]}
            pagination
            page={stateFromQuery.page}
            pageSize={pageSize}
            sx={{ height: '90vh' }}
            onRowClick={(params, event) => {
               navigateOrNewTab(`/users/${params.row.profile.id}`, navigate, event);
            }}
            onFilterModelChange={(f) => onFilterModelChange(f)}
            filterModel={
               stateFromQuery?.filters || {
                  items: [],
                  linkOperator: 'and',
               }
            }
            onPageChange={(newPage) => appendQueryParams({ ...stateFromQuery, page: newPage })}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={datagridColumns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(columnObj) => {
               // For some reason, the data grid component returns an empty object when selecting "Show all columns"
               const columnModel = !Object.keys(columnObj).length
                  ? columns.reduce((acc, { name }) => Object.assign(acc, { [name]: true }), {})
                  : columnObj;

               appendQueryParams({
                  ...stateFromQuery,
                  columns: columnsVisibilityModelToQueryArray(columnModel, columns),
               });
            }}
            checkboxSelection
            selectionModel={selected}
            onSelectionModelChange={handleSelectionChange}
         />
      </div>
   );
};

export default Reports;
