/* eslint-disable no-param-reassign */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import query from './query';
// import { rtkQueryFailed } from "./middleware/rtkQueryFailed";

const reducers = combineReducers({
   query,
});

const store = configureStore({
   reducer: reducers,

   // Adding the api middleware enables caching, invalidation, polling,
   // and other useful features of `rtk-query`.
   middleware: [
      thunk,
      // rtkQueryFailed,
   ],
   // middleware: (getDefaultMiddleware) =>
   //   getDefaultMiddleware()
   //     .concat(usersApi.middleware)
   //     .concat(authApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
