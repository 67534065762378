import { Chip, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProfileModel, ReportModel } from '../../../../shared/utilities/__generated__/graphql';
import { navigateOrNewTab } from '../../../../shared/utilities/helpers';
import AskForElaborationButton from '../AskForElaborationButton/AskForElaborationButton';

const ReportsTable: FC<{ reports: Partial<ReportModel>[] }> = ({ reports }): ReactElement => {
   const navigate = useNavigate();

   const columns: GridColDef[] = [
      {
         field: 'message',
         headerName: 'Message',
         flex: 1,
         renderCell: ({ value }) => {
            if (value) {
               return (
                  <Tooltip title={value}>
                     <div>{value}</div>
                  </Tooltip>
               );
            }

            return 'N/A';
         },
      },
      {
         field: 'content',
         headerName: 'Content',
         flex: 1,
         renderCell: ({ row }) => {
            if (row.type == 'CHAT_MESSAGE') {
               return row?.content?.content ? (
                  <Tooltip title={row?.content?.content}>
                     <div>{row?.content?.content}</div>
                  </Tooltip>
               ) : (
                  'Failed to get content'
               );
            }

            return 'N/A';
         },
      },
      {
         field: 'chatId',
         headerName: 'Chat ID',
         flex: 1,
         renderCell: ({ row }) => {
            if (row.type == 'CHAT_MESSAGE') {
               return row?.content?.chatId ? (
                  <Link to={'/reported-chats/' + row?.content?.chatId}>{row?.content?.chatId}</Link>
               ) : (
                  'Failed to get chat ID'
               );
            }

            return '';
         },
      },
      {
         field: 'reporter',
         headerName: 'Reporter',
         type: 'string',
         width: 200,
         // valueGetter: ({ value }) => value && new Date(value),
         renderCell: ({ value }: Partial<{ value: ProfileModel }>) => {
            return (
               <Chip
                  label={value?.name}
                  variant='outlined'
                  onClick={(event) => navigateOrNewTab(`/users/${value?.id}`, navigate, event)}
               />
            );
         },
      },
      {
         field: 'createdAt',
         headerName: 'Created at',
         type: 'dateTime',
         width: 200,
         valueGetter: ({ value }) => value && new Date(value),
      },
      // field with action buttons
      {
         field: 'actions',
         headerName: 'Actions',
         sortable: false,
         width: 350,
         renderCell: ({ row: { id } }) => {
            return <AskForElaborationButton id={id} />;
         },
      },
   ];

   return (
      <DataGrid
         rows={reports}
         getRowId={(row) => row.id}
         pagination
         autoHeight
         columns={columns}
         disableColumnSelector
         disableSelectionOnClick
         disableColumnMenu
         hideFooter
         hideFooterPagination
         sortModel={[
            {
               field: 'createdAt',
               sort: 'desc',
            },
         ]}
         // initialState={initialState}
      />
   );
};

export default ReportsTable;
