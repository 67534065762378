import { DataGrid, GridCellParams, GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { navigateOrNewTab } from '../../utilities/helpers';

const GenericDataTable: FC<{
   rows: GridRowsProp;
   columns?: GridColumns;
   getCellLink?: (params: GridCellParams) => string;
   useRowsAsColumns?: boolean;
}> = ({ rows, columns, getCellLink, useRowsAsColumns = true }): ReactElement => {
   if (!columns || useRowsAsColumns) {
      columns = Object.keys(rows[0] || {})
         .filter((key) => !['__typename'].includes(key))
         .map((key) => ({
            ...(columns?.find((c) => c.field == key) || {}),
            field: key,
            headerName: key,
            flex: 1,
         }));
   }

   columns = columns.map((column) => ({
      ...(column.field.endsWith('at') && {
         type: 'dateTime',
         valueGetter: ({ value }) => value && new Date(value),
      }),
      ...(column.field.toLowerCase().endsWith('id') && {
         type: 'string',
      }),
      ...(column as any),
   }));

   const navigate = useNavigate();

   return (
      <DataGrid
         rows={rows}
         onCellClick={
            getCellLink
               ? (params, event) => {
                    navigateOrNewTab(getCellLink(params), navigate, event);
                 }
               : undefined
         }
         columns={columns}
         disableColumnSelector
         disableSelectionOnClick
         disableColumnMenu
         hideFooter
         hideFooterPagination
         autoHeight
      />
   );
};

export default GenericDataTable;
