import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useEffect, useRef } from 'react';
import SaveButton from '../../../../shared/components/SaveButton';
import {
   ProfileModel,
   useUpdateWaitListStatusMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const RemoveFromWaitListButton: FC<{ user: ProfileModel }> = ({ user }) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();
   const [removeFromWaitListMutation, { error, loading, data }] = useUpdateWaitListStatusMutation({
      variables: {
         userId: user.id!,
         onWaitList: false,
      },
   });

   const handleRemoveLimit = async () => {
      await confirm({
         title: 'Remove from wait list?',
         description: 'This will allow the user to use the app fully',
         confirmationText: 'Remove from wait list',
         cancellationText: 'Cancel',
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Please wait...`, {
            variant: 'info',
            autoHideDuration: null,
         });

         const deleteAccountMutationResult = await removeFromWaitListMutation();
         closeSnackbar(notification.current);

         if (deleteAccountMutationResult.data) {
            enqueueSnackbar(`User no longer on wait list`, {
               variant: 'success',
            });

            user.onWaitlist = false;
         }
      });
   };

   useEffect(() => {
      if (error && !loading) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Failed removing from wait list`, {
            variant: 'error',
         });
      }
   }, [error, loading]);

   return user.onWaitlist && !data ? (
      <>
         <SaveButton
            startIcon={<ReceiptLongIcon />}
            variant='outlined'
            onClick={() => handleRemoveLimit()}
            loading={loading}
         >
            Remove from wait list
         </SaveButton>
      </>
   ) : null;
};

export default RemoveFromWaitListButton;
