import { Button } from '@mui/material';
import { FC, useEffect, useRef } from 'react';

import { Delete } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import {
   ProfileModel,
   useAdminClearReportsOnProfileMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const ClearReportsButton: FC<{ user: ProfileModel; onCleared?: () => void }> = ({
   user,
   onCleared,
}) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();
   const [clearReportsMutation, { error, loading }] = useAdminClearReportsOnProfileMutation({
      variables: {
         userId: user.id!,
      },
   });

   const handleDelete = async () => {
      await confirm({
         title: 'Are you sure?',
         description: 'This will clear all reports on this profile',
         confirmationText: 'Remove all reports',
         cancellationText: 'Cancel',
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Clearing reports, please wait...`, {
            variant: 'info',
            autoHideDuration: null,
         });

         const deleteAccountMutationResult = await clearReportsMutation();
         closeSnackbar(notification.current);

         if (deleteAccountMutationResult.data) {
            onCleared && onCleared();

            enqueueSnackbar(`All reports cleared!`, {
               variant: 'success',
            });
         }
      });
   };

   useEffect(() => {
      if (error && !loading) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Could not delete reports`, {
            variant: 'error',
         });
      }
   }, [error, loading]);

   return (
      <>
         <Button startIcon={<Delete />} variant='outlined' onClick={() => handleDelete()}>
            Clear all reports
         </Button>
      </>
   );
};

export default ClearReportsButton;
