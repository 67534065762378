import { Box, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ProfileModel,
    useDeletePlaceSuggestionsMutation,
    useGetPlaceSuggestionsQuery,
} from '../../../../shared/utilities/__generated__/graphql';
import { navigateOrNewTab } from '../../../../shared/utilities/helpers';

const mostFrequentValue = (arr: any[]) =>
   arr.sort((a, b) => arr.filter((v) => v === a).length - arr.filter((v) => v === b).length).pop();

const PlaceSuggestions: FC<void> = (): ReactElement => {
   const [page, setPage] = useState(0);
   const [pageSize, setPageSize] = useState(100);
   const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);

   const [rows, setRows] = useState<any[]>([]);
   const notification = useRef<SnackbarKey | undefined>(undefined);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();

   const handleSelectionChange = (selected: GridSelectionModel) => {
      const selectedRows = selected
         .map((row) => rows.find((r) => r.id === row))
         .map((r) => r.ids)
         .flat();
      setSelectedRows(selectedRows);
   };

   const { loading, data } = useGetPlaceSuggestionsQuery({
      variables: {
         skip: pageSize * page,
         // take: pageSize,
         take: 1000,
      },
      fetchPolicy: 'cache-and-network',
   });

   useEffect(() => {
      setRows(data?.placeSuggestions.items || []);
   }, [data?.placeSuggestions.items]);

   // Some API clients return undefined while loading
   // Following lines are here to prevent `rowCountState` from being undefined during the loading
   const [rowCountState, setRowCountState] = useState(data?.placeSuggestions.total || 0);
   useEffect(() => {
      setRowCountState((prevRowCountState) =>
         data?.placeSuggestions.total !== undefined
            ? data?.placeSuggestions?.total || 0
            : prevRowCountState,
      );
   }, [data?.placeSuggestions.total, setRowCountState]);

   const navigate = useNavigate();

   const columns: GridColDef[] = [
      { field: 'name', headerName: 'Name', width: 350 },
      {
         field: 'count',
         headerName: 'Count',
         type: 'number',
         width: 65,
      },
      {
         field: 'createdAt',
         headerName: 'First suggested at',
         type: 'dateTime',
         width: 200,
         valueGetter: ({ value }) => value && new Date(value),
      },
      {
         field: 'phoneExtension',
         headerName: 'Country',
         type: 'number',
         width: 100,
         valueGetter: ({ row }) => {
            return mostFrequentValue(row.profiles.map((p: ProfileModel) => p.phoneExtension));
         },
      },
      {
         field: 'latestSuggestedAt',
         headerName: 'Latest suggested at',
         type: 'dateTime',
         width: 200,
         valueGetter: ({ value }) => value && new Date(value),
      },
      {
         field: 'profiles',
         headerName: 'Suggested by',
         flex: 1,
         // valueGetter: ({ value }) => value && new Date(value),
         renderCell: ({ value }: Partial<{ value: ProfileModel[] }>) => {
            return (
               <Box sx={{ overflow: 'scroll' }}>
                  {value?.map((p) => (
                     <Chip
                        key={p?.id}
                        sx={{ mr: 1 }}
                        label={p?.name}
                        variant='outlined'
                        onClick={(event) => navigateOrNewTab(`/users/${p?.id}`, navigate, event)}
                     />
                  ))}
               </Box>
            );
         },
      },
      // TODO: Add suspension
   ];

   const [deleteSuggestionsMutation, { loading: deleting }] = useDeletePlaceSuggestionsMutation({
      variables: {
         suggestionIds: selectedRows.map((id) => id.toString()),
      },
   });

   const deleteSelectedSuggestions = async () => {
      await confirm({
         title: 'Are you sure?',
         description: 'This action cannot be undone.',
         confirmationText: 'Delete',
         cancellationText: 'Cancel',
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Deleting suggestion(s), please wait...`, {
            variant: 'info',
            autoHideDuration: null,
         });

         const deleteSuggestionMutationResult = await deleteSuggestionsMutation();
         closeSnackbar(notification.current);

         if (deleteSuggestionMutationResult.data) {
            enqueueSnackbar(`Suggestions deleted!`, {
               variant: 'success',
            });

            setRows(rows.filter((row) => !selectedRows.includes(row.id)));
            // apiRef.current.updateRows(selectedRows.map((id) => ({ id, _action: 'delete' })));
         }
      });
   };

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <DataGrid
            rows={rows}
            rowCount={rowCountState}
            loading={loading}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[5]}
            pagination
            page={page}
            pageSize={pageSize}
            sx={{ height: '90vh' }}
            // paginationMode='server'
            checkboxSelection
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            onSelectionModelChange={handleSelectionChange}
         />
         <Box sx={{ m: 2 }}>
            {selectedRows.length} suggestion(s) selected
            <Button
               sx={{ ml: 2 }}
               variant='contained'
               disabled={!selectedRows.length || deleting}
               onClick={deleteSelectedSuggestions}
            >
               Delete selected
            </Button>
         </Box>
      </div>
   );
};

export default PlaceSuggestions;
