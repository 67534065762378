import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, ReactElement } from 'react';
import { SuspensionModel } from '../../../../shared/utilities/__generated__/graphql';

const SuspensionsTable: FC<{ suspensions: Partial<SuspensionModel>[] }> = ({
   suspensions,
}): ReactElement => {
   const columns: GridColDef[] = [
      { field: 'reason', headerName: 'Reason', flex: 1 },
      { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
      { field: 'userId', headerName: 'UserId' },
      {
         field: 'permanent',
         headerName: 'Permanent',
         type: 'boolean',
      },
      {
         field: 'duration',
         headerName: 'Duration',
         width: 200,
      },
      {
         field: 'until',
         headerName: 'Until',
         type: 'dateTime',
         width: 200,
         valueGetter: ({ value }) => value && new Date(value),
      },
      {
         field: 'createdAt',
         headerName: 'Created at',
         type: 'dateTime',
         width: 200,
         valueGetter: ({ value }) => value && new Date(value),
      },
   ];

   console.log(suspensions);

   return suspensions.length > 0 ? (
      <>
         <Typography mb={2} variant='h4'>
            Suspensions
         </Typography>
         <DataGrid
            rows={suspensions}
            getRowId={(row) => row.id}
            pagination
            columns={columns}
            disableColumnSelector
            disableSelectionOnClick
            disableColumnMenu
            hideFooter
            hideFooterPagination
            autoHeight
            // initialState={initialState}
         />
      </>
   ) : (
      <div />
   );
};

export default SuspensionsTable;
