import { FormControl, FormGroup, InputLabel, MenuItem, Select } from '@mui/material';
import { FC } from 'react';

const SuspensionReasonsSelect: FC<{ onChange: (reason: string) => void }> = ({ onChange }) => {
   return (
      <>
         <FormGroup sx={{ mt: 1 }}>
            <FormControl fullWidth sx={{ mb: 1 }}>
               <InputLabel id='predefined-reason-select'>Predefined reasons</InputLabel>
               <Select
                  labelId='predefined-reason-select'
                  id='predefined-reason-select'
                  label='Predefined reasons'
                  onChange={(e) => onChange(e.target.value as string)}
                  value={'custom'}
               >
                  <MenuItem value={'custom'}>Custom</MenuItem>
                  <MenuItem value={'inappropriate messages'}>inappropriate messages</MenuItem>
                  <MenuItem value={'inappropriate images'}>inappropriate images</MenuItem>
                  <MenuItem value={'underage'}>underage</MenuItem>
                  <MenuItem value={'fake'}>fake</MenuItem>
                  <MenuItem value={'inappropriate bio'}>inappropriate bio</MenuItem>
                  <MenuItem value={'inappropriate username'}>inappropriate username</MenuItem>
               </Select>
            </FormControl>
         </FormGroup>
      </>
   );
};

export default SuspensionReasonsSelect;
