import { Box, Button, CardContent, TextField } from '@mui/material';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useRequestTrueUsersQuery } from '../../../../shared/utilities/__generated__/graphql';
import SuspendButton from '../../components/UserList/components/UserTable';

const TrueUser: FC<void> = (): ReactElement => {
   const usernamesInput = useRef<any>(null);
   const [usernames, setUsernames] = useState<string[]>([]);
   const [rawInput, setRawInput] = useState<string>('');

   const { data, loading, error } = useRequestTrueUsersQuery({
      variables: {
         usernames: usernames.join(','),
      },
      skip: !usernames.length,
      fetchPolicy: 'no-cache',
   });

   const onFormSubmit = (e: any) => {
      e.preventDefault();
      const rawUsernames = usernamesInput.current.value;

      const usernamesArray = rawUsernames
         .split(/[\s,]+/)
         .map((username: string) => username.trim());

      setRawInput(usernamesArray.join(','));

      setUsernames(usernamesArray);
   };

   // submit form on control + enter
   useEffect(() => {
      const listener = (event: KeyboardEvent) => {
         if (event.key === 'Enter' && event.metaKey) {
            onFormSubmit(event);
         }
      };
      if (usernamesInput.current) {
         usernamesInput.current.addEventListener('keydown', listener);
      }
      return () => {
         if (usernamesInput.current) {
            usernamesInput.current.removeEventListener('keydown', listener);
         }
      };
   }, [usernamesInput, onFormSubmit]);

   return (
      <Box height='100vh' display='flex' flexDirection='column'>
         <Box
            sx={{
               flexGrow: 1,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: 'column',
            }}
         >
            {data && !loading ? (
               <Box sx={{ width: '90%' }}>
                  <>
                     <SuspendButton usernames={usernames} data={data} />
                     <Button onClick={() => setUsernames([])} sx={{ mt: 2 }} variant='contained'>
                        New search
                     </Button>
                  </>
               </Box>
            ) : (
               <>
                  {!loading && (
                     <form onSubmit={onFormSubmit}>
                        <Box sx={{ maxWidth: 900 }}>
                           <CardContent>
                              <TextField
                                 inputRef={usernamesInput}
                                 id='outlined-textarea'
                                 label='Usernames'
                                 placeholder='Copy paste usernames here seperated by comma, space or newline'
                                 maxRows={20}
                                 multiline
                                 fullWidth
                                 defaultValue={rawInput}
                              />
                              <Button fullWidth type='submit' sx={{ mt: 2 }} variant='contained'>
                                 Search
                              </Button>
                           </CardContent>
                        </Box>
                     </form>
                  )}
                  {loading ? (
                     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src='/clover_loader.webp' alt='Please wait' width={100} />
                        <Box mt={2}>Analyzing {usernames.length} user(s)...</Box>
                     </Box>
                  ) : null}
                  {error ? 'Something went wrong' : null}
               </>
            )}
         </Box>
      </Box>
   );
};

export default TrueUser;
