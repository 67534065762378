import { Box, Button, Grid, TextField } from '@mui/material';
import { FC, ReactElement, useEffect, useState } from 'react';
import {
   useAllRedisConfigsQuery,
   useSaveRedisConfigMutation,
} from '../../../shared/utilities/__generated__/graphql';

interface RedisConfig {
   key: string;
   value: string;
}

const RedisConfig: FC = (): ReactElement => {
   const { loading, data } = useAllRedisConfigsQuery();
   const [saveConfig] = useSaveRedisConfigMutation();
   const [saving, setSaving] = useState<boolean>(false);

   const [dataWithEdits, setDataWithEdits] = useState<RedisConfig[]>([]);

   useEffect(() => {
      if (data?.allRedisConfigs) {
         setDataWithEdits(
            data.allRedisConfigs
               .map((configRaw) => {
                  const [key, ...valueParts] = configRaw.split(',');
                  return [key, valueParts.join(',')];
               })
               .map(([key, value]) => ({
                  key,
                  value,
               })),
         );
      }
   }, [data]);

   if (loading) {
      return <div>Loading...</div>;
   }

   return (
      <div style={{ width: '100%' }}>
         <Box sx={{ width: 100, height: 50 }} />
         Remember, these config variables need very specific structures to properly function...
         <Box sx={{ width: 100, height: 50 }} />
         <Grid container spacing={2}>
            {dataWithEdits.map((config, index) => (
               <Grid container item key={index} alignItems='center' spacing={2}>
                  <Grid item xs={4}>
                     <TextField
                        fullWidth
                        label='Key'
                        variant='outlined'
                        value={config.key}
                        InputProps={{
                           readOnly: true,
                        }}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <TextField
                        fullWidth
                        label='Value'
                        variant='outlined'
                        multiline
                        value={config.value}
                        onChange={(e) => {
                           const updatedData = [...dataWithEdits];
                           updatedData[index].value = e.target.value;
                           setDataWithEdits(updatedData);
                        }}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <Button
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                           setSaving(true);
                           await saveConfig({
                              variables: {
                                 key: config.key,
                                 value: config.value,
                              },
                           });
                           setSaving(false);
                        }}
                        disabled={saving}
                     >
                        Save
                     </Button>
                  </Grid>
               </Grid>
            ))}
         </Grid>
      </div>
   );
};

export default RedisConfig;
