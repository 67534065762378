import { Button, FormGroup, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import FormDialog from '../../../../shared/components/FormDialog';

import { WarningOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
   ProfileModel,
   useCreateWarningOnProfileMutation,
} from '../../../../shared/utilities/__generated__/graphql';
import SuspensionReasonsSelect from '../SuspensionReasonsSelect';

const WarningButton: FC<{ user: ProfileModel; onChange: () => void }> = ({ user, onChange }) => {
   const { enqueueSnackbar } = useSnackbar();
   const [isOpen, setIsOpen] = useState(false);

   const [reason, setReason] = useState('');

   const [createWarningMutation, { error, loading, data }] = useCreateWarningOnProfileMutation({
      variables: {
         userId: user.id!,
         reason,
      },
   });

   useEffect(() => {
      if (data && !loading) {
         enqueueSnackbar(`Created warning`, { variant: 'success' });

         onChange();
      }
   }, [data]);

   const handleSubmit = async () => {
      await createWarningMutation();
      setIsOpen(false);
   };

   return (
      <>
         <Button startIcon={<WarningOutlined />} variant='outlined' onClick={() => setIsOpen(true)}>
            Give warning
         </Button>
         <FormDialog
            title='Give warning'
            open={isOpen}
            onSubmit={handleSubmit}
            onClose={() => setIsOpen(false)}
            loading={loading}
            saveButtonProps={{
               color: 'warning',
               icon: WarningOutlined,
               children: 'Give warning',
               loading: loading,
            }}
         >
            <FormGroup sx={{ mt: 1 }}>
               <SuspensionReasonsSelect onChange={(e) => setReason(e)} />

               <TextField
                  multiline
                  rows={3}
                  label='Reason'
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
               />

               {error && <div>Something went wrong: {error.message}</div>}
            </FormGroup>
         </FormDialog>
      </>
   );
};

export default WarningButton;
