import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import SaveButton from '../SaveButton';
import { SaveButtonProps } from '../SaveButton/SaveButton';

interface Props extends Partial<DialogProps> {
   title: string;
   body?: string;
   children: any;
   onClose: () => void;
   onSubmit?: () => void;
   loading?: boolean;
   saveButtonProps?: Partial<SaveButtonProps>;
   hasActions?: boolean;
}

const FormDialog: FC<Props> = ({
   children,
   title,
   body,
   onClose,
   onSubmit,
   open,
   loading,
   saveButtonProps = {},
   hasActions = true,
}) => {
   return (
      <Dialog open={open ?? true} onClose={onClose}>
         <form
            onSubmit={(e) => {
               e.preventDefault();
               onSubmit?.();
            }}
         >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ width: '450px' }}>
               <DialogContentText>{body}</DialogContentText>
               {children}
            </DialogContent>

            {hasActions && (
               <DialogActions>
                  <Button onClick={onClose} variant='text'>
                     Cancel
                  </Button>

                  <SaveButton
                     loading={loading}
                     variant='outlined'
                     type='submit'
                     {...saveButtonProps}
                  >
                     {saveButtonProps.children ?? 'Save'}
                  </SaveButton>
               </DialogActions>
            )}
         </form>
      </Dialog>
   );
};

export default FormDialog;
