import { Button, FormGroup, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import FormDialog from '../../../../shared/components/FormDialog';

import { Check } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useWhitelistPhoneNumberMutation } from '../../../../shared/utilities/__generated__/graphql';

const WhitelistPhoneNumberButton: FC<{ phoneNumber: string; onChange?: () => void }> = ({
   phoneNumber,
   onChange,
}) => {
   const { enqueueSnackbar } = useSnackbar();
   const [isOpen, setIsOpen] = useState(false);
   const [description, setDescription] = useState<string>('');

   const [whitelistPhoneNumberMutation, { error, loading, data }] = useWhitelistPhoneNumberMutation(
      {
         variables: {
            input: {
               phoneNumber: phoneNumber,
               description: description,
            },
         },
      },
   );

   useEffect(() => {
      if (data) {
         enqueueSnackbar(`Phone number whitelisted!`, { variant: 'success' });

         onChange && onChange();
      }
   }, [data]);

   const handleSubmit = async () => {
      await whitelistPhoneNumberMutation();
      setIsOpen(false);
   };

   return (
      <>
         <Button
            startIcon={<Check />}
            variant='outlined'
            onClick={() => setIsOpen(true)}
            disabled={!!data}
         >
            {data ? 'Whitelisted' : 'Whitelist phone number'}
         </Button>
         <FormDialog
            title='Whitelist phone number'
            open={isOpen}
            onSubmit={handleSubmit}
            onClose={() => setIsOpen(false)}
            loading={loading}
            saveButtonProps={{
               icon: Check,
               children: 'Whitelist',
               loading: loading,
            }}
         >
            <FormGroup sx={{ mt: 1 }}>
               <TextField
                  multiline
                  rows={3}
                  label='Reason'
                  onChange={(e) => setDescription(e.target.value)}
               />

               {error && <div>Something went wrong: {error.message}</div>}
            </FormGroup>
         </FormDialog>
      </>
   );
};

export default WhitelistPhoneNumberButton;
