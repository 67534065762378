import type {} from '@mui/x-date-pickers/themeAugmentation';

import { createTheme } from '@mui/material/styles';

export default createTheme({
   palette: {
      primary: {
         light: '#46247a',
         main: '#2B134F',
         dark: '#240d45',
         contrastText: '#FFF',
      },
      secondary: {
         main: '#7654AA',
         light: '#7654AA',
         dark: '#2b134f',
         contrastText: '#FFF',
      },
   },
});
