import qs from 'query-string';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useRouter from '../../hooks/use-router';

const QuerifyStateProvider: FC<{ children?: any }> = ({ children }): ReactElement => {
   const { params } = useSelector((app) => (app as any).query);
   const { navigate } = useRouter();
   const [didInit, setDidInit] = useState(false);

   useEffect(() => {
      if (didInit) {
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         const { _query, ...actualParams } = params;

         // replace the query
         navigate(
            {
               search: qs.stringify(actualParams, { arrayFormat: 'comma' }),
            },
            { replace: true },
         );
      } else {
         // populate the state with the initial query
         // const currentQuery = qs.parse(window.location.search, {
         //   arrayFormat: "comma",
         // });
         // dispatch(addQueryParams(currentQuery));
      }
      setDidInit(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [params]);

   return children;
};

export default QuerifyStateProvider;
