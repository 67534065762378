import { Box, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';

const Home: FC<void> = (): ReactElement => {
   return (
      <Box height='100vh' display='flex' flexDirection='column'>
         <Box
            sx={{
               flexGrow: 1,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: 'column',
            }}
         >
            <Typography variant='h4'>
               Only for approved internal employees of Doubble ApS
            </Typography>
         </Box>
      </Box>
   );
};

export default Home;
