import { format, formatDistance } from 'date-fns';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';

export const getCookieValue = (name: string) =>
   document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const getCFTokenFromCookie = () => getCookieValue('CF_Authorization');

export const formatDateReadable = (date?: string | Date) => {
   if (!date) {
      return 'N/A';
   }

   return `${format(new Date(date), 'PPPP')} (${formatDistance(new Date(date), new Date(), {
      addSuffix: true,
   })})`;
};

export const navigateOrNewTab = (url: string, navigate: NavigateFunction, event: any) => {
   if (event.ctrlKey || event.metaKey) {
      window.open(url);
   } else {
      navigate(url);
   }
};

export const calculateAge = (birthdate: string): number => {
   const birthdateObj: Date = new Date(birthdate);
   const now: Date = new Date();
   let age: number = now.getFullYear() - birthdateObj.getFullYear();
   const monthDiff: number = now.getMonth() - birthdateObj.getMonth();

   if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthdateObj.getDate())) {
      age--;
   }

   return age;
};

export const paramsToQueryString = (object: any) => {
   const parameters = [];
   // eslint-disable-next-line no-restricted-syntax
   for (const property in object) {
      // eslint-disable-next-line no-prototype-builtins
      if (object.hasOwnProperty(property)) {
         parameters.push(encodeURI(`${property}=${object[property] || ''}`));
      }
   }

   return parameters.join('&');
};
export const removeEmptyEntries = (obj: any = {}) =>
   Object.keys(obj).reduce(
      (acc, key) => (isEmpty(obj[key]) ? acc : { ...acc, [key]: obj[key] }),
      {},
   );

export function stringToColor(str: string) {
   let hash = 0;
   for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
   }

   let color = '#';
   for (let i = 0; i < 3; i++) {
      // Calculate the value in a restricted range to ensure darker colors
      const value = (hash >> (i * 8)) & 0xff;

      // Limit the color to a range that ensures it is dark enough
      const adjustedValue = Math.floor(value * 0.6); // Reduce brightness to ensure contrast

      // Convert to hex and append to the color string
      color += ('00' + adjustedValue.toString(16)).slice(-2);
   }

   return color;
}

// https://stackoverflow.com/a/65448758/12443961

const useFetchAndUnzip = (url: string) => {
   const [content, setContent] = useState<string | null>(null);
   const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState<boolean>(true);

   // eslint-disable-next-line @typescript-eslint/no-var-requires
   const untar = require('js-untar');

   useEffect(() => {
      const fetchAndUnzip = async () => {
         try {
            // Start loading
            setLoading(true);
            setError(null);

            // Fetch the .tar.gz file
            const response = await fetch(url, {
               headers: {
                  'Accept-Encoding': 'identity', // Prevent automatic decompression
               },
            });
            if (!response.ok) {
               throw new Error(`Failed to fetch the file: ${response.statusText}`);
            }

            const arrayBuffer = await response.arrayBuffer();

            // Extract the .tar archive using js-untar
            const files = await untar(arrayBuffer);

            if (files.length === 0) {
               throw new Error('The tar archive is empty');
            }

            // Assuming we want to read the first file's content
            const firstFile = files[0];

            // Check if the file is a text file (you can add more checks based on file type)
            if (firstFile.blob) {
               // Read the first file's content as text
               const text = await firstFile.blob.text();
               setContent(text);
            } else {
               throw new Error('No valid text file found in the archive');
            }
         } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
         } finally {
            setLoading(false);
         }
      };

      fetchAndUnzip();
   }, [url]);

   return { content, error, loading };
};

export default useFetchAndUnzip;
