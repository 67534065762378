import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../../features/Routing/utilities/routes';
import SearchUsersInput from '../../../features/Users/components/SearchUsersInput';
import { useGetApplicationAudQuery } from '../../utilities/__generated__/graphql';
import { getCFTokenFromCookie } from '../../utilities/helpers';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
   width: drawerWidth,
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: 'hidden',
   width: `calc(${theme.spacing(7)} + 1px)`,
   [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
   },

   // always closed on mobile
   [theme.breakpoints.down('sm')]: {
      width: `0px`,
      left: '-1px',
   },
});

const DrawerHeader = styled('div')(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'flex-end',
   padding: theme.spacing(0, 1),
   // necessary for content to be below app bar
   ...theme.mixins.toolbar,
}));

const DrawerHeaderBuffer = styled('div')(({ theme }) => ({
   ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
   open?: boolean;
}

const AppBar = styled(MuiAppBar, {
   shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
   zIndex: theme.zIndex.drawer + 1,
   transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
   ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
         ...openedMixin(theme),
         '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
         ...closedMixin(theme),
         '& .MuiDrawer-paper': closedMixin(theme),
      }),
   }),
);

const Layout: React.FC<{ children?: any }> = ({ children }): React.ReactElement => {
   const theme = useTheme();
   const [open, setOpen] = React.useState(true);

   const handleDrawerOpen = () => {
      setOpen(true);
   };

   const handleDrawerClose = () => {
      setOpen(false);
   };
   const location = useLocation();

   const currentRoute = useMemo(() => {
      return routes.find((route) => route.path === window.location.pathname);
   }, [location.pathname]);

   console.log('currentRoute', currentRoute);

   const cfToken = getCFTokenFromCookie();

   const { data } = useGetApplicationAudQuery({
      variables: {
         token: cfToken,
      },
   });

   const auds = data?.getCloudflareAUD ?? [];

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />
         <AppBar position='fixed' open={open}>
            <Toolbar>
               <IconButton
                  aria-label='open drawer'
                  onClick={handleDrawerOpen}
                  edge='start'
                  sx={{
                     marginRight: 5,
                     color: 'white',
                     ...(open && { display: 'none' }),
                  }}
               >
                  <MenuIcon />
               </IconButton>
               <img height={25} src='/logo-white.svg' alt='logo' />
               <Box sx={{ ml: 2 }}>
                  <SearchUsersInput />
               </Box>
            </Toolbar>
         </AppBar>
         <Drawer variant='permanent' open={open}>
            <DrawerHeader>
               <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
               </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
               {routes
                  .filter(({ inMenu }) => inMenu)
                  .filter(
                     ({ auds: requiredAuds }) =>
                        !requiredAuds || requiredAuds.some((aud) => auds.includes(aud)),
                  )
                  .map(({ key, title, icon: Icon, path }, index) => (
                     <ListItem key={key} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                           sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                           }}
                           to={path}
                           component={Link}
                           onClick={() => setOpen(false)}
                        >
                           <ListItemIcon
                              sx={{
                                 minWidth: 0,
                                 mr: open ? 3 : 'auto',
                                 justifyContent: 'center',
                              }}
                           >
                              {index % 2 === 0 ? <Icon /> : <Icon />}
                           </ListItemIcon>
                           <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                     </ListItem>
                  ))}
            </List>
            {/* <Divider />
            <List>
               {['All mail', 'Trash', 'Spam'].map((text, index) => (
                  <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                     <ListItemButton
                        sx={{
                           minHeight: 48,
                           justifyContent: open ? 'initial' : 'center',
                           px: 2.5,
                        }}
                     >
                        <ListItemIcon
                           sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                           }}
                        >
                           {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                     </ListItemButton>
                  </ListItem>
               ))}
            </List> */}
         </Drawer>
         <Box
            component='main'
            sx={{
               flexGrow: 1,
               ...(currentRoute?.contentPadding != false && { p: 3 }),
            }}
         >
            {currentRoute?.contentPadding != false ? <DrawerHeader /> : <DrawerHeaderBuffer />}
            {children}
         </Box>
      </Box>
   );
};

export default Layout;
